/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AlertDialog from "../../compoments/AlertDialog";
import Base from "../Base";
import Button from "@material-ui/core/Button";
import api from "./../../services/axios";
import PlanilhaModelo from "./modeloimportacaocliente.csv";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	input: {
		display: "none",
	},
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	appBarSpacer: theme.mixins.toolbar,
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	disabled: {
		padding: 30,
		backgroundColor: "red",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: "100%",
	},
}));

export default function Import({ match, history }) {
	const roleName = "Clientes";
	const [message, setMessage] = React.useState(false);
	const [alertTitle, setAlertTitle] = React.useState(false);
	const [alertSubTitle, setAlertSubTitle] = React.useState(false);
	const [file, setFile] = React.useState();
	const [nameFile, setNameFile] = React.useState("");
	const classes = useStyles();

	useEffect(() => {
		setNameFile(file?.name || "");
	}, [file]);

	const save = async () => {
		let data = new FormData();
		data.append("arquivo", file);
		try {
			const result = await api.post("/v6/lead/import", data, {
				headers: {
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			});

			setFile(null);
			setAlertTitle(result.data.msg);
			setAlertSubTitle(result.data.success);
			setMessage(result.data.error);
		} catch (error) {
			setFile(null);
			setAlertTitle("Falha ao enviar o arquivo. Tente novamente");
			setMessage("");
		}
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Link color="inherit" to={`/clientes`}>
									{roleName}
								</Link>
								<Typography color="textPrimary">
									Importação em lote
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid item xs={12}></Grid>
					</Grid>
					<Grid item xs={12}>
						<input
							type="file"
							accept="csv"
							style={{ display: "none" }}
							id="contained-button-file"
							onChange={(e) => setFile(e.target.files[0])}
						/>
						<label htmlFor="contained-button-file">
							<Button
								variant="contained"
								color="string"
								component="span"
							>
								Selecionar arquivo
							</Button>
							<Typography color="textPrimary">
								{nameFile}
							</Typography>
						</label>
					</Grid>
					<Grid item xs={10}>
						<Button>
							<Link
								to={PlanilhaModelo}
								target="_blank"
								download="planilha.csv"
								style={{
									marginRight: 30,
									marginTop: 10,
									textDecoration: "none",
									color: "#7D1F1A",
								}}
							>
								Download planilha de exemplo
							</Link>
						</Button>
					</Grid>

					<Grid item xs={10}>
						<Button
							onClick={() => {
								history.push(`/clientes`);
							}}
							type="button"
							variant="contained"
							color="default"
							style={{
								marginRight: 30,
								marginTop: 30,
							}}
						>
							Voltar
						</Button>
						<Button
							disabled={!file}
							type="button"
							variant="contained"
							color="primary"
							onClick={save}
							style={{
								marginTop: 30,
							}}
						>
							Enviar
						</Button>
					</Grid>
					<AlertDialog
						message={message}
						onClose={() => setMessage(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
						subtitle={alertSubTitle}
					/>
				</Container>
			</main>
		</div>
	);
}
