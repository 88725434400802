/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MTableToolbar } from "material-table";
import React, { useEffect, useRef } from "react";
import AlertDialog from "../../compoments/AlertDialog";
import MaterialTableComponent from "../../compoments/MaterialTableComponent";
import Base from "../Base";
import api from "./../../services/axios";
import { dateFormat } from "./../../services/date/date";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import CustomDialog from "../../compoments/CustomDialog";
import jwtDecode from "./../../services/jwt";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
}));

export default function Index({ history, match }) {
	const roleName = "Clientes";
	const classes = useStyles();
	const [message, setMessage] = React.useState(false);
	const tableRef = useRef();
	const [actionsTable, setActionsTable] = React.useState([]);
	const [idCampaign, setIdCampaign] = React.useState(0);
	const [openDialogCreateClient, setOpenDialogCreateClient] =
		React.useState(false);

	const loadActionsTable = async () => {
		let actionsDt = [];

		actionsDt.push({
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, rowData) => {
				history.push(`/clientes/update/${rowData.id}`);
			},
		});

		actionsDt.push({
			icon: ShoppingCart,
			tooltip: "Pedidos",
			onClick: (event, rowData) => {
				history.push(`/pedidos/clientes/${rowData.id}`);
			},
		});

		actionsDt.push({
			icon: "add",
			tooltip: "Criar",
			isFreeAction: true,
			onClick: (event) => setOpenDialogCreateClient(true),
		});

		setActionsTable(actionsDt);
	};

	const [availablePageForThePlan, setAvailablePageForThePlan] =
		React.useState(true);

	useEffect(() => {
		setIdCampaign(match.params.idCampaign);
		loadActionsTable();
		const data = jwtDecode(localStorage.getItem("token"));
		if (data && parseInt(data.user.plano.id) === 7) {
			setAvailablePageForThePlan(false);
		}
	}, []);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				{!availablePageForThePlan ? (
					<div
						style={{
							flexDirection: "column",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "90vh",
						}}
					>
						<h2>Funcionalidade não disponível para o seu plano.</h2>
						<h3>
							Faça um upgrade do seu plano e tenha acesso a todos
							os recursos do Intelivino.
						</h3>
					</div>
				) : (
					<>
						<div className={classes.appBarSpacer} />
						<Container maxWidth="lg" className={classes.container}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Breadcrumbs aria-label="breadcrumb">
										<Typography color="textPrimary">
											{roleName}
										</Typography>
									</Breadcrumbs>
								</Grid>
								<Grid item xs={12}>
									<MaterialTableComponent
										tableRef={tableRef}
										components={{
											Toolbar: (props) => (
												<>
													<Grid
														container
														spacing={3}
														style={{
															paddingLeft: 20,
															paddingBottom: 20,
														}}
													>
														<Grid
															item
															xs={12}
															style={{
																alignSelf:
																	"center",
																marginTop: 20,
															}}
														>
															<MTableToolbar
																{...props}
															/>
														</Grid>
													</Grid>
												</>
											),
										}}
										columns={[
											{ title: "Nome", field: "name" },
											{ title: "E-mail", field: "mail" },
											{
												title: "Celular",
												field: "mobile_phone",
											},
											{
												title: "Data de criação",
												field: "created_at",
											},
										]}
										data={(query) =>
											new Promise((resolve, reject) => {
												const filterByCampaign =
													idCampaign
														? `&idCampaign=${idCampaign}`
														: "";
												api.get(
													`/v6/lead?perPage=${
														query.pageSize
													}&page=${
														query.page + 1
													}&search=${
														query.search
													}${filterByCampaign}`
												)
													.then(function ({ data }) {
														let dt = data.data.map(
															(i, k) => {
																return {
																	...i,
																	...{
																		created_at:
																			dateFormat(
																				new Date(
																					i.created_at
																				),
																				true
																			),
																	},
																};
															}
														);
														let obj = {
															data: dt,
															page: data.current_page
																? data.current_page -
																  1
																: 0,
															totalCount:
																data.total,
														};
														resolve(obj);
													})
													.catch(function (error) {
														resolve([]);
													});
											})
										}
										title=""
										actions={actionsTable}
										options={{
											actionsColumnIndex: -1,
										}}
									/>
								</Grid>
							</Grid>
							<AlertDialog
								message={message}
								onClose={() => setMessage(false)}
								title=""
								buttonSuccessText={"OK"}
							/>
							<CustomDialog
								open={openDialogCreateClient}
								onClose={() => setOpenDialogCreateClient(false)}
								title={"Criar cliente"}
								textLeft={"Cadastro único"}
								onClickLeft={() => {
									history.push(`/clientes/create`);
								}}
								textRight={"Importar"}
								onClickRight={() => {
									history.push(`/clientes/import`);
								}}
							/>
						</Container>
					</>
				)}
			</main>
		</div>
	);
}
