import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import jwtDecode from "./../../services/jwt";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 700,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function RolesCreate({ history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("createrolesgestor")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const rota = "roles";
  const roleName = "Roles";

  const [redirect] = React.useState(false);
  const [role, setRole] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");

  const [setPermissions] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [loadingRotulos, setLoadingRotulos] = React.useState(true);
  const [rotulosSelected, setRotulosSelected] = React.useState([]);
  const [rotulos, setRotulos] = React.useState([]);

  const loadPermissions = useCallback(
    async (loja, discountP, rotulosSelectedP = []) => {
      setRotulos([]);
      setLoadingRotulos(!loadingRotulos);
      const dataPermissions = await api.get("/v3/permissions");
      let rotulosData = dataPermissions.data.data.map((i, k) => {
        return {
          title: i.name + (i.description ? " - " + i.description : ""),
          preco: 0,
          id: i.id,
          nome: i.name + (i.description ? " - " + i.description : ""),
        };
      });
      setRotulos(rotulosData);
    },
    [loadingRotulos, setLoadingRotulos]
  );

  const loadData = useCallback(async () => {
    const tokenData = jwtDecode(localStorage.getItem("token"));
    const dataPermissions = await api.get("/v3/permissions");
    setPermissions(dataPermissions.data.data);
    loadPermissions({ id: tokenData.user.id }, 0);
    setLoading(false);
  }, [loadPermissions, setPermissions]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const save = async () => {
    const data = new FormData();
    data.append("role", role);
    data.append("description", description);

    let indicacoesIds = rotulosSelected.map((i, k) => i.id);
    data.append("permissions", indicacoesIds);
    try {
      await api.post("/v3/roles", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push("/" + rota);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" to={`/${rota}`}>
                    {roleName}
                  </Link>
                  <Typography color="textPrimary">Cadastro</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="role"
                          label="Nome"
                          name="role"
                          autoComplete="role"
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="description"
                          label="Descrição"
                          name="description"
                          autoComplete="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth={true}
                          loading={loadingRotulos}
                          key={loadingRotulos}
                          multiple
                          id="checkboxes-tags-demo"
                          options={rotulos}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                  !!selected ||
                                  !!rotulosSelected.find(
                                    (i, k) => i.id === option.id
                                  )
                                }
                              />
                              {option.title}
                            </React.Fragment>
                          )}
                          style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Permissões"
                              placeholder="Buscar..."
                              fullWidth
                              style={{ width: "1000px" }}
                            />
                          )}
                          value={rotulosSelected}
                          defaultValue={rotulosSelected}
                          onChange={(event, value, reason) => {
                            console.log("----value", value);
                            setRotulosSelected(value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            history.push(`/${rota}`);
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          style={{ marginRight: 30 }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={save}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          )}
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
