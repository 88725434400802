/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import AlertDialog from "../../compoments/AlertDialog";
import { estados } from "../../services/address/estados";
import api from "../../services/axios";
import Base from "../Base";
import { useStylesCreateFreight } from "./styles";

export default function Create({ match, history }) {
	const [idFreight, setIdFreight] = React.useState(0);
	const [message, setMessage] = React.useState(false);
	const [alertTitle, setAlertTitle] = React.useState("");
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [city, setCity] = React.useState("");
	const [cities, setCities] = React.useState([]);
	const [state, setState] = React.useState("");
	const [district, setDistrict] = React.useState("");
	const [initialZipCode, setInitialZipCode] = React.useState("");
	const [endZipCode, setEndZipCode] = React.useState("");
	const [minValueToFreeFreight, setMinValueToFreeFreight] =
		React.useState("");
	const [minValueToDelivery, setMinValueToDelivery] = React.useState("");
	const [rate, setRate] = React.useState("");
	const [deliveryLimit, setDeliveryLimit] = React.useState("");
	const [note, setNote] = React.useState("");

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		setIdFreight(match.params.id);
		const { data } = await api.get(`/v6/freight/` + match.params.id);

		setCity(data.city);
		setState(data.state);
		setDistrict(data.district);
		setInitialZipCode(data.initialZipCode);
		setEndZipCode(data.endZipCode);
		setMinValueToFreeFreight(data.minValueToFreeFreight);
		setMinValueToDelivery(data.minValueToDelivery);
		setRate(data.rate);
		setDeliveryLimit(data.deliveryLimit);
		setNote(data.note);
        loadCitiesAccordingState(data.state);
	};

	const save = async () => {
		setSaveLoading(true);
		const formData = {
			city,
			district,
			state,
			initialZipCode,
			endZipCode,
			minValueToFreeFreight,
			rate,
			deliveryLimit,
			minValueToDelivery,
			note,
		};

		try {
			await api.put(`/v6/freight/${idFreight}`, formData);
			setAlertTitle("");
			history.push(`/frete/`);
			setSaveLoading(false);
		} catch (error) {
			setAlertTitle("Erro");
			setMessage(error.response.data.message || "Erro");
			setSaveLoading(false);
		}
	};

	const classes = useStylesCreateFreight();

	const populateRangeCep = async (city, state) => {
		if (!city || !state || city.lenght <= 2) {
			return;
		}

		const { data } = await api.get(
			`/v6/freight/getRangeCep?city=${city}&state=${state}`
		);

		setInitialZipCode(data?.initial_range || "");
		setEndZipCode(data?.end_range || "");
	};

	const onChangeState = (value) => {
		setState(value);
		loadCitiesAccordingState(value);
	};

	const loadCitiesAccordingState = async (stateSearch) => {
		const { data } = await api.get(
			`/v6/freight/getCitiesByState?state=${stateSearch}`
		);
		setCities(data.data);
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Typography color="textPrimary">
									Editar
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Grid item xs={6}>
									<form className={classes.form} noValidate>
										<Grid container spacing={2}>
											<>
												<FormControl
													variant="outlined"
													className={
														classes.formControl
													}
												>
													<InputLabel id="demo-simple-select-outlined-label">
														Estado
													</InputLabel>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={state}
														onChange={(e) =>
															onChangeState(
																e.target.value
															)
														}
														label="Estado"
													>
														{estados.map(
															(data, k) => {
																return (
																	<MenuItem
																		value={
																			data.id
																		}
																		key={
																			data.id
																		}
																	>
																		{
																			data.label
																		}
																	</MenuItem>
																);
															}
														)}
													</Select>
												</FormControl>

												<FormControl
													variant="outlined"
													className={
														classes.formControlCities
													}
												>
													<InputLabel id="demo-simple-select-outlined-label">
														Selecione a cidade
													</InputLabel>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={city}
														onChange={(e) => {
															setCity(
																e.target.value
															);
															populateRangeCep(
																e.target.value,
																state
															);
														}}
														label="Cidade"
													>
														{cities.map(
															(data, k) => (
																<MenuItem
																	value={
																		data.city
																	}
																	key={
																		data.id
																	}
																>
																	{data.city}
																</MenuItem>
															)
														)}
													</Select>
												</FormControl>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="district"
														label="Bairro"
														name="district"
														autoComplete="district"
														value={district}
														onChange={(e) => {
															if (
																!e.target.value
															) {
																populateRangeCep(
																	city,
																	state
																);
															}
															setDistrict(
																e.target.value
															);
															setInitialZipCode(
																""
															);
															setEndZipCode("");
														}}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="initialZipCode"
														label="CEP inicial"
														name="initialZipCode"
														autoComplete="initialZipCode"
														value={initialZipCode}
														onChange={(e) => {
															setInitialZipCode(
																e.target.value
															);
														}}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="endZipCode"
														label="CEP final"
														name="endZipCode"
														autoComplete="endZipCode"
														value={endZipCode}
														onChange={(e) => {
															setEndZipCode(
																e.target.value
															);
														}}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="minValueToFreeFreight"
														label="Valor para frete gratuito"
														name="minValueToFreeFreight"
														autoComplete="minValueToFreeFreight"
														value={
															minValueToFreeFreight
														}
														onChange={(e) =>
															setMinValueToFreeFreight(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="minValueToDelivery"
														label="Valor mínimo para entrega"
														name="minValueToDelivery"
														autoComplete="minValueToDelivery"
														value={
															minValueToDelivery
														}
														onChange={(e) =>
															setMinValueToDelivery(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="rate"
														label="Valor do frete"
														name="rate"
														autoComplete="rate"
														value={rate}
														onChange={(e) =>
															setRate(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="deliveryLimit"
														label="Prazo de entrega"
														name="deliveryLimit"
														autoComplete="deliveryLimit"
														value={deliveryLimit}
														onChange={(e) =>
															setDeliveryLimit(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="note"
														label="Observações"
														name="note"
														autoComplete="note"
														value={note}
														onChange={(e) =>
															setNote(
																e.target.value
															)
														}
													/>
												</Grid>
											</>
											<Grid item xs={10}>
												<Button
													onClick={() => {
														history.goBack();
													}}
													type="button"
													variant="contained"
													color="default"
													style={{
														marginRight: 30,
														marginTop: 30,
													}}
												>
													Voltar
												</Button>
												<Button
													disabled={saveLoading}
													type="button"
													variant="contained"
													color="primary"
													onClick={save}
													style={{
														marginTop: 30,
													}}
												>
													{saveLoading
														? "Salvando..."
														: "Salvar"}
												</Button>
											</Grid>
										</Grid>
									</form>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<AlertDialog
						message={message}
						onClose={() => setMessage(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
					/>
				</Container>
			</main>
		</div>
	);
}
