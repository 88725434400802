import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
}));

export default function BannerUpdate({ match, history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("updatebannersgestor")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const route = "categorias";
  const pageTitle = "Categorias";

  const [redirect] = React.useState(false);
  const [id, setId] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [name, setName] = React.useState("");
  const [categoryId, setCategoryId] = React.useState("");
  const [categoryParentId, setCategoryParentId] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [final, setFinal] = React.useState("");

  const loadData = useCallback(async () => {
    setId(match.params.id);
    const data = await api.get(`/gestor/v1/categories/` + match.params.id);

    setName(data.data.name);
    setCategoryId(data.data.wp_category_id);
    setCategoryParentId(data.data.wp_parent_id);
    setTipo(data.data.tipo || "0");
    setFinal(data.data.final || "0");

    setLoading(false);
  }, [match, setId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const save = async () => {
    const data = new FormData();

    data.append("name", name);
    data.append("wp_category_id", categoryId);
    data.append("wp_parent_id", categoryParentId);
    data.append("tipo", tipo);
    data.append("final", final === 1 ? 1 : "");

    data.append("_method", "PUT");
    try {
      await api.post(`/gestor/v1/categories/${id}`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push(`/${route}`);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" to={`/${route}`}>
                    {pageTitle}
                  </Link>
                  <Typography color="textPrimary">Editar</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          label="Nome"
                          name="name"
                          autoComplete="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type={"number"}
                          variant="outlined"
                          fullWidth
                          id="categoryId"
                          label="Categoria id"
                          name="categoryId"
                          autoComplete="categoryId"
                          value={categoryId}
                          onChange={(e) => setCategoryId(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type={"number"}
                          variant="outlined"
                          fullWidth
                          id="categoryParentId"
                          label="Categoria pai"
                          name="categoryParentId"
                          autoComplete="categoryParentId"
                          value={categoryParentId}
                          onChange={(e) => setCategoryParentId(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Tipo
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={tipo}
                            onChange={(e) => setTipo(e.target.value)}
                          >
                            <MenuItem value="0">Nenhuma</MenuItem>
                            <MenuItem value="valor">Valor</MenuItem>
                            <MenuItem value="experiencia">Experiência</MenuItem>
                            <MenuItem value="prato">Prato</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Final
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={final}
                            onChange={(e) => setFinal(e.target.value)}
                          >
                            <MenuItem value="0">Não</MenuItem>
                            <MenuItem value="1">Sim</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            history.push(`/${route}`);
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          style={{ marginRight: 30 }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={save}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          )}
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
