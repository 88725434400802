import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
}));

export default function VendedoresDetail({ match, history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("updateuserbusinessgestor.disabled")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const roleName = "Vendedores";

  const [redirect] = React.useState(false);
  const [setId] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [alertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [name, setName] = React.useState("");
  const [fone, setFone] = React.useState("");
  const [whatsapp, setWhatsapp] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [cpf, setCpf] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [setStatus] = React.useState("");
  const [obs, setObs] = React.useState("");

  const loadData = useCallback(async () => {
    setId(match.params.id);
    const data = await api.get("/v3/users/" + match.params.id);
    setName(data.data.name);
    setFone(data.data.business.fone);
    setWhatsapp(data.data.business.whatsapp);
    setEmail(data.data.email);
    setCpf(data.data.business.cpf);
    setDescription(data.data.business.description);
    setStatus(data.data.business.status);
    setObs(data.data.business.obs);
    setLoading(false);
  }, [match, setId, setStatus]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" to={`/usersseller`}>
                    {roleName}
                  </Link>
                  <Typography color="textPrimary">Detalhe</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          label="Nome"
                          name="name"
                          autoComplete="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="description"
                          label="Descrição"
                          name="description"
                          autoComplete="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="fone"
                          label="Telefone"
                          name="fone"
                          autoComplete="fone"
                          value={fone}
                          onChange={(e) => setFone(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="whatsapp"
                          label="Whatsapp"
                          name="whatsapp"
                          autoComplete="whatsapp"
                          value={whatsapp}
                          onChange={(e) => setWhatsapp(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          autoComplete="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="password"
                        label="Senha"
                        name="password"
                        autoComplete="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>  */}
                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="cpf"
                          label="Cpf"
                          name="cpf"
                          autoComplete="cpf"
                          value={cpf}
                          onChange={(e) => setCpf(e.target.value)}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                      <TextField
                        disabled="true"
                        variant="outlined"
                        required
                        fullWidth
                        id="status"
                        label="Status"
                        name="status"
                        autoComplete="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      />
                    </Grid>  */}
                      <Grid item xs={12}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="obs"
                          label="Observações internas"
                          name="obs"
                          autoComplete="obs"
                          value={obs}
                          onChange={(e) => setObs(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            history.push(`/usersseller`);
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          // className={classes.submit}
                          style={{ marginRight: 30 }}
                        >
                          Voltar
                        </Button>
                        {/* <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={save}
                      >
                        Salvar
                      </Button> */}
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          )}
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
