import Collapse from "@material-ui/core/Collapse";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PersonPinIcon from "@material-ui/icons/People";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import jwtDecode from "./../services/jwt";
import { isPermission } from "./../services/permission";
import api from "../services/axios";
import AlertDialog from "../compoments/ConfirmDialog";
import { Alert } from "@material-ui/lab";
import { getBaseUrl } from "../env";

export default function MenuItems(props) {
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openStock, setOpenStock] = React.useState(false);
  const [openCommunity, setOpenCommunity] = React.useState(false);
  const [openUsuarios, setOpenUsuarios] = React.useState(false);
  const [openGrupos, setOpenGrupos] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [uri, setUri] = React.useState("");
  const [uriModule, setUriModule] = React.useState("");

  useEffect(() => {
    let urlArray = window.location.href.split("/");
    urlArray.shift();
    urlArray.shift();
    urlArray.shift();
    let uriParam = "/" + urlArray.join("/");
    const moluleRef = urlArray[0] ? urlArray[0] : urlArray;
    console.log({ moluleRef });
    setUriModule(moluleRef);
    setUri(uriParam);
    setOpenUsuarios(
      moluleRef === "usersbusiness" ||
        moluleRef === "usersseller" ||
        moluleRef === "usersfinal"
    );
    setOpenGrupos(moluleRef === "roles" || moluleRef === "permissions");
    setOpen(
      [
        "planos",
        "rotulos",
        "campanhas",
        "banners",
        "videos",
        "sorteios",
        "categorias",
        "clientes",
        "pedidos",
        "coupons",
      ].includes(moluleRef)
    );
    setOpenStock(["stock"].includes(moluleRef));
  }, []);

  useEffect(() => {
    const tokenData = jwtDecode(localStorage.getItem("token"));
    setPermissions(tokenData.user.permissions);
  }, []);

  const handleCommunitySso = async () => {
    const data = jwtDecode(localStorage.getItem("token"));
    const email = data.user.email;

    try {
      const { baseURL } = getBaseUrl();
      const response = await api.request({
        baseURL: `${baseURL}/api-node`,
        url: `/sso`,
        method: "POST",
        data: {
          token: process.env.REACT_APP_SSO_KEY,
          email,
        },
      });

      if (response.status === 200) {
        console.log({ response });
        const refreshToken = response.data.refreshToken;
        const baseUrl = process.env.REACT_APP_COMMUNITY_URL;

        const communityUrl = `${baseUrl}?token=${refreshToken}`;

        window.open(communityUrl, "_blank");
      }
    } catch (error) {
      setOpenDialog(true);
    }
  };

  const renderUsuarios = () => {
    let active = "";
    let uriActive = "";

    if (uri) {
      active = uri.split("/");
      active = active && active[1] ? active[1] : "";
      uriActive = `/${active}`;
    }
    return (
      <Collapse in={openUsuarios} timeout="auto" unmountOnExit>
        {permissions.includes("userbusinessgestor") && (
          <Link component={RouterLink} to="/usersbusiness">
            <ListItem button selected={uriActive === "/usersbusiness"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Lojistas"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("usersellergestor") && (
          <Link component={RouterLink} to="/usersseller">
            <ListItem button selected={uriActive === "/usersseller"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Vendedores"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("userfinalgestor") && (
          <Link component={RouterLink} to="/usersfinal">
            <ListItem button selected={uriActive === "/usersfinal"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Consumidores"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
      </Collapse>
    );
  };

  const renderGrupos = () => {
    let active = "";
    let uriActive = "";

    if (uri) {
      active = uri.split("/");
      active = active && active[1] ? active[1] : "";
      uriActive = `/${active}`;
    }
    return (
      <Collapse in={openGrupos} timeout="auto" unmountOnExit>
        {permissions.includes("rolesgestor") && (
          <Link component={RouterLink} to="/roles">
            <ListItem button selected={uriModule === "roles"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Grupos"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("permissionsgestor") && (
          <Link component={RouterLink} to="/permissions">
            <ListItem button selected={uriActive === "/permissions"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Permissões"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
      </Collapse>
    );
  };

  const renderCadastros = () => {
    let active = "";
    let uriActive = "";

    if (uri) {
      active = uri.split("/");
      active = active && active[1] ? active[1] : "";
      uriActive = `/${active}`;
    }
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        {permissions.includes("categoriasgestor") && (
          <Link component={RouterLink} to="/categorias">
            <ListItem button selected={uriModule === "categorias"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Categorias"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("planosgestor") && (
          <Link component={RouterLink} to="/planos">
            <ListItem button selected={uriModule === "planos"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Planos"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("rotulosgestor") && (
          <Link component={RouterLink} to="/rotulos">
            <ListItem button selected={uriActive === "/rotulos"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Rótulos"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("campanhasgestor") && (
          <Link component={RouterLink} to="/campanhas">
            <ListItem button selected={uriActive === "/campanhas"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Campanhas"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("campanhasgestor") && (
          <Link component={RouterLink} to="/coupons">
            <ListItem button selected={uriActive === "/coupons"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Cupons"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("bannersgestor") && (
          <Link component={RouterLink} to="/banners">
            <ListItem button selected={uriActive === "/banners"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Banners"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("videosgestor") && (
          <Link component={RouterLink} to="/videos">
            <ListItem button selected={uriActive === "/videos"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Vídeos"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}
        {permissions.includes("sorteiosgestor") && (
          <Link component={RouterLink} to="/sorteios">
            <ListItem button selected={uriActive === "/sorteios"}>
              <ListItemIcon>
                <AddCircleOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Sorteios"
                style={{ color: "rgba(0, 0, 0, 0.87)" }}
              />
            </ListItem>
          </Link>
        )}

        <Link component={RouterLink} to="/clientes">
          <ListItem button selected={uriActive === "/clientes"}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Clientes"
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            />
          </ListItem>
        </Link>

        <Link component={RouterLink} to="/pedidos">
          <ListItem button selected={uriActive === "/pedidos"}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Pedidos"
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            />
          </ListItem>
        </Link>
      </Collapse>
    );
  };

  const renderStock = () => {
    return (
      <Collapse in={openStock} timeout="auto" unmountOnExit>
        <Link component={RouterLink} to="/stock/moviment">
          <ListItem button selected={uriModule === "moviment"}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Movimentação"
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            />
          </ListItem>
        </Link>
        <Link component={RouterLink} to="/stock/inventory">
          <ListItem button selected={uriModule === "inventory"}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Inventário"
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            />
          </ListItem>
        </Link>
        <Link component={RouterLink} to="/stock/stocksheet">
          <ListItem button selected={uriModule === "stocksheet"}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Ficha de Estoque"
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            />
          </ListItem>
        </Link>
        <Link component={RouterLink} to="/stock/stockadjustment">
          <ListItem button selected={uriModule === "stockadjustment"}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Ajuste de Estoque"
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            />
          </ListItem>
        </Link>
      </Collapse>
    );
  };

  return (
    <div>
      <AlertDialog
        message={openDialog}
        onClose={() => setOpenDialog(false)}
        title="Erro ao conectar com o servidor"
        buttonSuccessText={"OK"}
      />
      <List component="div" disablePadding {...props}>
        {permissions.includes("perfilgestor") && (
          <Link component={RouterLink} to="/perfil" {...props}>
            <ListItem button href="/perfil" selected={uri === "/perfil"}>
              <ListItemIcon {...props}>
                <PersonPinIcon />
              </ListItemIcon>
              <ListItemText primary="Meu Perfil" />
            </ListItem>
          </Link>
        )}
        {(isPermission("userbusinessgestor") ||
          isPermission("usersellergestor") ||
          isPermission("userfinalgestor")) && (
          <>
            <ListItem
              button
              onClick={() => {
                setOpenUsuarios(!openUsuarios);
              }}
            >
              <ListItemIcon>
                <AddCircleIcon {...props} />
              </ListItemIcon>
              <ListItemText primary={"Usuários"} />
              {openUsuarios ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {renderUsuarios()}
          </>
        )}
        {(isPermission("rolesgestor") || isPermission("permissionsgestor")) && (
          <>
            <ListItem
              button
              onClick={() => {
                setOpenGrupos(!openGrupos);
              }}
            >
              <ListItemIcon>
                <AddCircleIcon {...props} />
              </ListItemIcon>
              <ListItemText primary={"Grupos"} />
              {openGrupos ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {renderGrupos()}
          </>
        )}
        {(isPermission("planosgestor") ||
          isPermission("rotulosgestor") ||
          isPermission("campanhasgestor") ||
          isPermission("bannersgestor") ||
          isPermission("videosgestor") ||
          isPermission("videosgestor") ||
          isPermission("sorteiosgestor")) && (
          <>
            <ListItem
              button
              onClick={() => {
                setOpen(!open);
              }}
            >
              <ListItemIcon>
                <AddCircleIcon {...props} />
              </ListItemIcon>
              <ListItemText primary={"Cadastros"} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {renderCadastros()}
          </>
        )}

        <>
          <ListItem
            button
            onClick={() => {
              setOpenStock(!openStock);
            }}
          >
            <ListItemIcon>
              <AddCircleIcon {...props} />
            </ListItemIcon>
            <ListItemText primary={"Estoque"} />
            {openStock ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {renderStock()}
        </>

        <>
          <a onClick={handleCommunitySso}>
            <ListItem button href="/perfil" selected={uri === "/perfil"}>
              <ListItemIcon {...props}>
                <PersonPinIcon />
              </ListItemIcon>
              <ListItemText primary="Comunidade" />
            </ListItem>
          </a>
        </>
      </List>
    </div>
  );
}
