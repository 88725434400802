import React, { useEffect } from 'react';

import { Breadcrumbs, CssBaseline, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ConfirmDialog from '../../compoments/ConfirmDialog';
import DatePicker from '../../compoments/DatePicker';
import api from '../../services/axios';
import jwtDecode from '../../services/jwt/index';
import Base from '../Base';
import { useStyles } from './styles';

function CouponsCreate({history}) {
  const classes = useStyles();
  const [redirect] = React.useState(false);
  const roleName = 'Cupons';
  
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const [alertTitle, setAlertTitle] = React.useState('');
  const [couponCode, setCouponCode] = React.useState('');
  const [discountType, setDiscountType] = React.useState('');
  const [discountValue, setDiscountValue] = React.useState('');
  const [userId, setUserId] = React.useState(null)
  const [couponInitialDate, setCouponInitialDate] = React.useState(null)
  const [couponEndDate, setCouponEndDate] = React.useState(null)
  const [saveLoading, setSaveLoading] = React.useState(false);

  useEffect(() => {
    const data = jwtDecode(localStorage.getItem('token'))
    setUserId(data.user.id)
  }, [])

  async function submitForm() {
    setSaveLoading(true)
    const formData = {
      code: couponCode,
      discount_type: discountType,
      user_id: userId,
      discount_value: discountValue,
      couponUse_type: 'UNLIMITED',
      initial_date: couponInitialDate,
      expiration_date: couponEndDate
    }
    const response = await api.post('/v6/coupon', formData)
    if(response.status === 201){
      setShowAlertDialog(true)
      setAlertTitle("Cupom cadastrado com sucesso!"); 
      setSaveLoading(false)
      return
    }
    setSaveLoading(false)
  }

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect}/>}
      <CssBaseline />
      {/* TOPO E MENU DRAWER*/}
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to={`/coupons`} >
                {roleName}
              </Link>
              <Typography color="textPrimary">Criar</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                <form className={classes.form} noValidate>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="code"
                        label="Código do Cupom"
                        name="code"
                        autoComplete="code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '1rem'}}>
                      <InputLabel>Tipo de desconto</InputLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        row
                      >
                        <FormControlLabel onChange={() => {                        
                          setDiscountType('PERCENTAGE')
                        }} value='PERCENTAGE'  control={<Radio />} label="Porcentagem" />
                        <FormControlLabel onChange={() => {
                          setDiscountType('VALUE')
                        }} value="VALUE" control={<Radio />} label="Valor" />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="discount_value"
                        label="Valor do desconto"
                        name="discount_value"
                        autoComplete="discount_value"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        required
                        value={couponInitialDate}
                        onChange={setCouponInitialDate}
                        label="Data da liberação do cupom"
                        id="couponInititalDate"
                        name="couponInitialDate"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        value={couponEndDate}
                        onChange={setCouponEndDate}
                        label="Data da expiração do cupom"
                        id="expiration_date"
                        name="expiration_date"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          history.push(`/coupons`)
                        }}
                        type="button"
                        variant="contained"
                        color="default"
                        style={{ marginRight: 30, marginTop: 30 }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        disabled={saveLoading}
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                        style={{ marginTop: 30 }}
                      >
                        {saveLoading ? 'Salvando...' : 'Salvar'}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>

            </Paper>
          </Grid>
        </Grid>
        <ConfirmDialog 
              message={showAlertDialog}
              onClose={() => setShowAlertDialog(false)}
              title={alertTitle}
              buttonSuccessText={'Ok'}
              onConfirm={() => {
                setShowAlertDialog(false)
                history.push('/coupons')
              }}
            />
        </Container>
      </main>
    </div>
  )
}

export default CouponsCreate;