import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ConfirmDialog from "./..//ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    width: "43.75rem",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.secondary.dark,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

export default function Line(props) {
  const classes = useStyles();

  const [message, setMessage] = React.useState(false);
  const [id, setId] = React.useState(false);
  const [preview, setPreview] = React.useState(false);

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={2.5}>
        {props.photos.map((tile, k) => (
          <GridListTile key={tile.id}>
            <img src={tile.uri} alt={""} />
            <GridListTileBar
              title={""}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              actionIcon={
                (props.photos.length > 1 && (
                  <IconButton
                    aria-label={`star ${tile.id}`}
                    onClick={() => {
                      setId(tile.id);
                      setPreview(tile.preview);
                      if (!tile.preview) {
                        setMessage("Deseja excluir essa imagem?");
                      } else {
                        props.remove(tile.id, tile.preview, k);
                      }
                    }}
                  >
                    <DeleteForeverIcon
                      className={classes.title}
                      color="action"
                    />
                  </IconButton>
                )) ||
                null
              }
            />
          </GridListTile>
        ))}
      </GridList>
      <ConfirmDialog
        message={message}
        onClose={() => setMessage(false)}
        title={"Atenção"}
        buttonSuccessText={"EXCLUIR"}
        onConfirm={async () => {
          await props.remove(id, preview);
          setMessage(false);
        }}
      />
    </div>
  );
}
