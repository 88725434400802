/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Cancel, Check } from "@material-ui/icons";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Badge from "../../compoments/Badge";
import Loading from "../../compoments/Loading";
import MaterialTableComponent from "../../compoments/MaterialTableComponent";
import api from "../../services/axios";
import Base from "../Base";
import { dateFormat } from "./../../services/date/date";
import jwtDecode from "./../../services/jwt";
import { useStyles } from "./styles";
import { getBaseUrl } from "../../env";
import ConfirmDialog from "./../../compoments/ConfirmDialog";
import { useCallback } from "react";
import Button from "@material-ui/core/Button";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

export default function Pedidos({ history, match }) {
  const { storeURL, baseURL } = getBaseUrl();
  const roleName = "Pedidos";
  const classes = useStyles();
  const tableRef = useRef();
  const [loading] = React.useState(false);
  const [messageDelete, setMessageDelete] = React.useState("");
  const [messageConfirm, setMessageConfirm] = React.useState("");
  const [idSelected, setIdSelected] = React.useState(null);
  const [orderIdConfirm, setOrderIdConfirm] = React.useState(null);
  const [showDialogDelete, setShowDialogDelete] = React.useState(false);
  const [showDialogConfirm, setShowDialogConfirm] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [lojaId, setLojaId] = React.useState(null);
  const [marketUuid, setMarketUuid] = React.useState(null);
  const nodeEnv = process.env.REACT_APP_NODE_ENV;

  console.log({ nodeEnv });

  useEffect(() => {
    const data = jwtDecode(localStorage.getItem("token"));
    if (data) {
      setLojaId(data.user.id);
    }
  }, []);

  const loadOrders = async (query) => {
    const { campaignId, leadId, couponId } = match.params;
    let queryParamsURL = "";
    if (campaignId) {
      queryParamsURL = `&campaignId=${campaignId}`;
    }

    if (leadId) {
      queryParamsURL = `${queryParamsURL}&customerId=${leadId}`;
    }

    if (couponId) {
      queryParamsURL = `${queryParamsURL}&couponId=${couponId}`;
    }

    const tokenData = jwtDecode(localStorage.getItem("token"));
    const marketId = tokenData.user.id;
    const { data } = await api.request({
      baseURL,
      url: `/api-node/account/externalId/${marketId}/orders?limit=${
        query.pageSize
      }&offset=${query.page + 1}${queryParamsURL}`,
    });

    const options = {
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    };
    const formatNumber = new Intl.NumberFormat("pt-BR", options);
    if (data.items.length > 0) {
      setMarketUuid(data.items[0].accountId);
    }
    let orders = data.items.map((order) => {
      const labelStatus = getLabelStatus(order.status);
      const colorStatus = getColorStatus(order.status);
      return {
        ...order,
        created_at: dateFormat(new Date(order.createdAt), true),
        total: formatNumber.format(order.total),
        lead_name: (
          <Link
            color="inherit"
            to={`/clientes/update/${
              order.customer.externalId ?? order.customer.id
            }`}
          >
            {order.customer.name}
          </Link>
        ),
        statusBadge: <Badge texto={labelStatus} cor={colorStatus} />,
      };
    });

    setItems(orders);

    return {
      data: orders,
      page: data.current_page ? data.current_page - 1 : 0,
      totalCount: data.total,
    };
  };

  function getLabelStatus(status) {
    switch (status) {
      case "PENDING":
        return "Pendente";
      case "AWAIT_FREIGHT_CALC":
        return "Aguardando cálculo de frete";
      case "CANCELED":
        return "Cancelado";
      default:
        return "Finalizado";
    }
  }

  function getColorStatus(status) {
    switch (status) {
      case "PENDING":
        return "warning";
      case "CANCELED":
      case "AWAIT_FREIGHT_CALC":
        return "critical";
      default:
        return "primary";
    }
  }

  function getActionTableView(rowData) {
    return {
      icon: VisibilityIcon,
      tooltip: "View",
      onClick: () => {
        history.push(`/pedidos/${rowData.id}`);
      },
    };
  }

  function getActionTableLinkOrder(rowData) {
    const data = jwtDecode(localStorage.getItem("token"));
    let urlLoja = storeURL + "" + data.user.name_business_slug;
    return {
      icon: "link",
      tooltip: "Link",
      onClick: () => {
        window.open(
          `${urlLoja}/order/${rowData.accountExternalId}/${rowData.id}/checkout`,
          "_blank"
        );
      },
      hidden:
        rowData.status !== "PENDING" && rowData.status !== "AWAIT_FREIGHT_CALC",
    };
  }

  function getActionDeleteOrder(rowData) {
    if (rowData.status !== "CANCELED") {
      return {
        icon: Cancel,
        tooltip: "Cancelar pedido",
        onClick: () => {
          setShowDialogDelete(true);
          setIdSelected(rowData.id);
          setMessageDelete("Tem certeza que deseja cancelar este pedido?");
        },
      };
    }
  }

  function getActionConfirmOrder(rowData) {
    if (rowData.status === "PENDING") {
      return {
        icon: Check,
        tooltip: "Finalizar pedido",
        onClick: () => {
          setShowDialogConfirm(true);
          setOrderIdConfirm(rowData.id);
          setMessageConfirm("Tem certeza que deseja finalizar este pedido?");
        },
      };
    }
  }
  
  function getActionOpenChatWhatsApp(rowData) {
    if (!rowData.customer?.mobilePhone) {
      return;
    }

    return {
      icon: WhatsAppIcon,
      tooltip: "Conversar no WhatsApp",
      onClick: () => {
        let phoneNumber = rowData.customer.mobilePhone.replace(/\D/g, "");
        if (!phoneNumber.startsWith("55")) {
          phoneNumber = "55" + phoneNumber;
        }
        const url = `https://wa.me/${phoneNumber.replace("+", "")}`;
        window.open(url, "_blank");
      },
    };
  }

  const onHandleCancelOrder = useCallback(async () => {
    const response = await api.request({
      baseURL,
      url: `/api-node/account/externalId/${lojaId}/order/externalId/${idSelected}/cancel`,
      method: "PATCH",
    });
    if (response.status === 204) {
      loadOrders({
        pageSize: 5,
        page: 0,
      });
    }
  }, [idSelected]);

  const onHandleConfirmOrder = useCallback(async () => {
    const response = await api.request({
      baseURL,
      url: `/api-node/account/${marketUuid}/orders/${orderIdConfirm}/status`,
      data: {
        status: "FINISHED",
      },
      method: "PATCH",
    });
    if (response.status === 200) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [orderIdConfirm]);

  const renderContent = useCallback(() => {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Base />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="textPrimary">{roleName}</Typography>
                </Breadcrumbs>
                <Grid item xs={10}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      loadOrders({
                        pageSize: 5,
                        page: 0,
                      });
                    }}
                    style={{
                      marginTop: 30,
                    }}
                  >
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
              {loading ? (
                <Loading />
              ) : (
                <Grid item xs={12}>
                  <MaterialTableComponent
                    tableRef={tableRef}
                    options={{
                      search: false,
                      sorting: false,
                      actionsColumnIndex: -1,
                    }}
                    columns={[
                      {
                        title: "Data do pedido",
                        field: "created_at",
                        align: "center",
                      },
                      {
                        title: "Cliente",
                        field: "lead_name",
                        align: "center",
                      },
                      {
                        title: "Número do pedido",
                        field: "code",
                        align: "center",
                      },
                      {
                        title: "Status",
                        field: "statusBadge",
                        align: "center",
                      },
                      {
                        title: "Total R$",
                        field: "total",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                    ]}
                    data={(query) => loadOrders(query)}
                    actions={[
                      (rowData) =>
                        getActionTableView(rowData),
                      (rowData) =>
                        getActionTableLinkOrder(
                          rowData
                        ),
                      (rowData) =>
                        getActionDeleteOrder(rowData),
                      (rowData) =>
                        getActionConfirmOrder(rowData),
                      (rowData) =>
                        getActionOpenChatWhatsApp(rowData),
                    ]}
                    title="Pedidos"
                  />
                </Grid>
              )}
            </Grid>
            <ConfirmDialog
              open={showDialogDelete}
              message={messageDelete}
              onClose={() => {
                setShowDialogDelete(false);
                setMessageDelete(null);
              }}
              title={"Atenção"}
              buttonSuccessText={"Sim"}
              onConfirm={() => {
                setShowDialogDelete(false);
                setMessageDelete(null);
                onHandleCancelOrder();
              }}
            />
            <ConfirmDialog
              open={showDialogConfirm}
              message={messageConfirm}
              onClose={() => {
                setShowDialogConfirm(false);
                setMessageConfirm(null);
              }}
              title={"Atenção"}
              buttonSuccessText={"Sim"}
              onConfirm={() => {
                setShowDialogConfirm(false);
                setMessageConfirm(null);
                onHandleConfirmOrder();
              }}
            />
          </Container>
        </main>
      </div>
    );
  }, [
    items,
    messageDelete,
    showDialogDelete,
    messageConfirm,
    showDialogConfirm,
  ]);

  return renderContent();
}
