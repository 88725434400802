import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { MTableToolbar } from "material-table";
import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import MaterialTableComponent from "./../../compoments/MaterialTableComponent";
import api from "./../../services/axios";
import Base from "./../Base";

const dateFormat = (date, time) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yy = date.getFullYear().toString().substr(-2);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  let timeString = "";
  if (time) {
    timeString =
      " às " +
      hours.toString().padStart(2, "0") +
      "h" +
      minutes.toString().padStart(2, "0");
  }
  return dd + "/" + mm + "/" + yy + " " + timeString;
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Usuarios({ history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("usersellergestor")) {
      history.push("/401");
    }

    let actionsDt = [];

    if (isPermission("usersellergestor")) {
      actionsDt.push({
        icon: "visibility",
        tooltip: "Detalhes",
        onClick: (event, rowData) => {
          history.push(`/usersseller/detail/${rowData.id}`);
        },
      });
    }

    setActionsTable(actionsDt);
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const roleName = "Vendedores";

  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [redirect] = React.useState(false);
  const [alertTitle] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [actionsTable, setActionsTable] = React.useState([]);

  const loadData = async () => {
    const data = await api.get("/v3/users?role=seller");

    const dt = data.data.data.map((i, k) => {
      return {
        id: i.id,
        name: i.name,
        parent: i.parent,
        email: i.email,
        created_at: dateFormat(new Date(i.created_at), true),
      };
    });
    setData(dt);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      {/* TOPO E MENU DRAWER*/}
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">{roleName}</Typography>
              </Breadcrumbs>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <MaterialTableComponent
                components={{
                  Toolbar: (props) => (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{ paddingLeft: 20, paddingBottom: 20 }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ alignSelf: "center", marginTop: 20 }}
                        >
                          <MTableToolbar {...props} />
                        </Grid>
                      </Grid>
                    </>
                  ),
                }}
                columns={[
                  { title: "Nome", field: "name" },
                  { title: "Empresa", field: "parent.name" },
                  { title: "Email", field: "email" },
                  { title: "Data de Cadastro", field: "created_at" },
                ]}
                data={data}
                title=""
                actions={actionsTable}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
