/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import ConfirmDialog from "./../../compoments/ConfirmDialog";
import MaterialTableComponent from "./../../compoments/MaterialTableComponent";
import api from "./../../services/axios";
import Base from "./../Base";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Planos({ history }) {
  const loadPermission = async () => {
    if (!isPermission("planosgestor")) {
      history.push("/401");
    }

    let actionsDt = [];

    if (isPermission("updateplanosgestor")) {
      actionsDt.push({
        icon: "edit",
        tooltip: "Editar",
        onClick: (event, rowData) => {
          history.push(`/planos/update/${rowData.id}`);
        },
      });
    }

    if (isPermission("deleteplanosgestor")) {
      actionsDt.push({
        icon: "delete",
        tooltip: "Deletar",
        onClick: (event, rowData) => {
          setIdSelected(rowData.id);
          setMessageDelete("Deseja excluir?");
        },
        disabled: false,
      });
    }

    if (isPermission("createplanosgestor")) {
      actionsDt.push({
        icon: "add",
        tooltip: "Criar " + roleName,
        isFreeAction: true,
        onClick: (event) => history.push(`/planos/create`),
      });
    }

    setActionsTable(actionsDt);
  };

  useEffect(() => {
    loadPermission();
  }, []);

  const roleName = "Planos";

  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [redirect] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [messageDelete, setMessageDelete] = React.useState(false);
  const [idSelected, setIdSelected] = React.useState(false);
  const [actionsTable, setActionsTable] = React.useState([]);

  const loadData = async () => {
    const data = await api.get("/v3/planos");
    setData(data.data.data);
  };

  const deleteAction = async (id) => {
    const data = await api.delete("/v3/planos/" + id);
    if (!data.data.status) {
      setAlertTitle("Erro");
      setMessage("Não foi possível deletar");
    } else {
      setAlertTitle("");
      setMessage("Deletado com sucesso");
      loadData();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      {/* TOPO E MENU DRAWER*/}
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">{roleName}</Typography>
              </Breadcrumbs>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <MaterialTableComponent
                columns={[
                  { title: "Nome", field: "nome" },
                  { title: "Valor", field: "valor_plano" },
                  { title: "Qtd. Rótulos", field: "max_rotulos" },
                  { title: "Qtd. Usuários", field: "max_users" },
                  {
                    title: "Qtd. Indicações Normais",
                    field: "normal_indications",
                  },
                  {
                    title: "Qtd. Indicações Destaque",
                    field: "highlight_indications",
                  },
                ]}
                data={data}
                title=""
                actions={actionsTable}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
          <ConfirmDialog
            message={messageDelete}
            onClose={() => setMessageDelete(false)}
            title={"Atenção"}
            buttonSuccessText={"EXCLUIR"}
            onConfirm={async () => {
              await deleteAction(idSelected);
              setMessageDelete(false);
            }}
          />
        </Container>
      </main>
    </div>
  );
}
