import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

export default function AlertDialog({
	message = "",
	title = "",
	onClose,
	buttonSuccessText = "OK",
	subtitle = "",
}) {
	return (
		<div>
			<Dialog
				open={!!message}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				{subtitle ? (
					<DialogTitle disableTypography>
						<Typography variant="h8">{subtitle}</Typography>
					</DialogTitle>
				) : (
					""
				)}
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary" autoFocus>
						{buttonSuccessText}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
