export function formatarMoeda(value) {
	if (!value) return 0;
	// remove caracteres não numéricos
	const somenteNumeros = value.replace(/\D/g, "");

	// divide por 100 para ter duas casas decimais
	const resultado = parseInt(somenteNumeros, 10) / 100;

	// retorna o resultado formatado com duas casas decimais
	return resultado.toFixed(2).replace(".", ",");
}

export function formatNumberToBRL(value, withDollarSign = true) {
	const options = {
		currency: "BRL",
		minimumFractionDigits: 2,
		maximumFractionDigits: 3,
	};
	const formatNumber = new Intl.NumberFormat("pt-BR", options);

	const result = formatNumber.format(value);
	return withDollarSign ? `R$ ${result}` : result;
}
