import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import jwt_decode from "jwt-decode";
import api from "./../../services/axios";
import { Redirect } from "react-router";
import AlertDialog from "./../../compoments/AlertDialog";
import { getBaseUrl } from "../../env";
const { imagesBucketURL } = getBaseUrl();

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${imagesBucketURL}/images/assets/banner-gestor.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login({ history }) {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [redirect] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");

  const loginAction = async () => {
    try {
      const user = {
        email,
        password: pass,
      };

      const data = await api.post("/gestor/v1/auth", user, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!data.data.token) {
        setAlertTitle("Erro");
        setMessage("Login ou senha inválido(s)");
      } else {
        const tokenDecoded = jwt_decode(data.data.token);
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user", JSON.stringify(tokenDecoded.user));
        setAlertTitle("");
        history.push("/perfil");
      }
    } catch (error) {
      console.log({ error });
      setAlertTitle("Erro");
      setMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : "Erro"
      );
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Entrar
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPass(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Lembrar-me"
            />
            <Button
              onClick={loginAction}
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/recuperar-senha" variant="body2">
                  Perdeu sua senha?
                </Link>
              </Grid>
              {/* <Grid item>
                <Link href="/cadastro" variant="body2">
                  {"Não tem uma conta? Registre-se"}
                </Link>
              </Grid> */}
            </Grid>
          </form>
        </div>
      </Grid>
      <AlertDialog
        message={message}
        onClose={() => setMessage(false)}
        title={alertTitle}
        buttonSuccessText={"OK"}
      />
    </Grid>
  );
}
