/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AlertDialog from "../../../compoments/AlertDialog";
import Loading from "../../../compoments/Loading";
import api from "../../../services/axios";
import Base from "../../Base";
import {searchCep} from "../../../services/address/viacep";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	input: {
		display: "none",
	},
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	appBarSpacer: theme.mixins.toolbar,
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	disabled: {
		padding: 30,
		backgroundColor: "red",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: "100%",
	},
}));

export default function Create({ match, history }) {
	const loadData = async () => {
		const { data } = await api.get(
			`v6/lead/address/` + match.params.addressId
		);
		setAddressName(data.address_name);
		setAddressStreet(data.address_street);
		setAddressNumber(data.address_number);
		setAddressDistrict(data.address_district);
		setAddressCity(data.address_city);
		setAddressState(data.address_state);
		setAddressZipCode(data.address_zip_code);
		setAddressComplement(data.address_complement);
		setLoading(false);
	};

	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const roleName = "Clientes/Endereço";
	const idClient = match.params.id;
	const idAddress = match.params.addressId;
	const [message, setMessage] = React.useState(false);
	const [alertTitle, setAlertTitle] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [addressName, setAddressName] = React.useState("");
	const [addressStreet, setAddressStreet] = React.useState("");
	const [addressNumber, setAddressNumber] = React.useState("");
	const [addressDistrict, setAddressDistrict] = React.useState("");
	const [addressCity, setAddressCity] = React.useState("");
	const [addressState, setAddressState] = React.useState("");
	const [addressComplement, setAddressComplement] = React.useState("");
	const [addressZipCode, setAddressZipCode] = React.useState("");

	const save = async () => {
		setSaveLoading(true);
		const data = new FormData();
		data.append("name", addressName);
		data.append("street", addressStreet);
		data.append("number", addressNumber);
		data.append("district", addressDistrict);
		data.append("city", addressCity);
		data.append("state", addressState);
		data.append("complement", addressComplement);
		data.append("zip_code", addressZipCode);
		data.append("_method", "PUT");

		try {
			await api.post(`/v6/lead/address/${idAddress}`, data, {
				headers: {
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			});
			setAlertTitle("");
			history.push(`/clientes/update/${idClient}`);
			setSaveLoading(false);
		} catch (error) {
			console.log(error.response);
			setAlertTitle("Erro");
			setMessage(error.response.data.message || "Erro");
			setSaveLoading(false);
		}
	};

	const classes = useStyles();

    const populateAddress = async (cep) => {
		const validacep = /^\d{8}$/;

		if (validacep.test(cep) && cep.length === 8) {
			const data = await searchCep(cep);
			if (data.logradouro) {
				setAddressStreet(data.logradouro);
			}
			if (data.bairro) {
				setAddressDistrict(data.bairro);
			}
			if (data.localidade) {
				setAddressCity(data.localidade);
			}
			if (data.uf) {
				setAddressState(data.uf);
			}
		}
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Link
									color="inherit"
									to={`/clientes/update/${idClient}`}
								>
									{roleName}
								</Link>
								<Typography color="textPrimary">
									Atualizar
								</Typography>
							</Breadcrumbs>
						</Grid>
						{loading ? (
							<Loading />
						) : (
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<Grid item xs={6}>
										<form
											className={classes.form}
											noValidate
										>
											<Grid container spacing={2}>
												<>
													<Grid item xs={12}>
														<TextField
															variant="outlined"
															fullWidth
															id="address_name"
															label="Nome do endereço"
															name="address_name"
															autoComplete="address_name"
															value={addressName}
															onChange={(e) =>
																setAddressName(
																	e.target
																		.value
																)
															}
														/>
													</Grid>
													<Grid item xs={12}>
														<TextField
															variant="outlined"
															fullWidth
															id="address_zip_code"
															label="CEP"
															name="address_zip_code"
															autoComplete="address_zip_code"
															value={
																addressZipCode
															}
															onChange={(e) => {
																populateAddress(
																	e.target
																		.value
																);
																setAddressZipCode(
																	e.target
																		.value
																);
															}}
														/>
													</Grid>
													<Grid item xs={8}>
														<TextField
															variant="outlined"
															fullWidth
															id="address_street"
															label="Endereço"
															name="address_street"
															autoComplete="address_street"
															value={
																addressStreet
															}
															onChange={(e) =>
																setAddressStreet(
																	e.target
																		.value
																)
															}
														/>
													</Grid>
													<Grid item xs={4}>
														<TextField
															variant="outlined"
															fullWidth
															id="address_number"
															label="Número"
															name="address_number"
															autoComplete="address_number"
															value={
																addressNumber
															}
															onChange={(e) =>
																setAddressNumber(
																	e.target
																		.value
																)
															}
														/>
													</Grid>
												</>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_district"
														label="Bairro"
														name="address_district"
														autoComplete="address_district"
														value={addressDistrict}
														onChange={(e) =>
															setAddressDistrict(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_city"
														label="Cidade"
														name="address_city"
														autoComplete="address_city"
														value={addressCity}
														onChange={(e) =>
															setAddressCity(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_state"
														label="Estado"
														name="address_state"
														autoComplete="address_state"
														value={addressState}
														onChange={(e) =>
															setAddressState(
																e.target.value
															)
														}
													/>
												</Grid>												
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_complement"
														label="Complemento"
														name="address_complement"
														autoComplete="address_complement"
														value={
															addressComplement
														}
														onChange={(e) =>
															setAddressComplement(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={10}>
													<Button
														onClick={() => {
															history.push(
																`/clientes/update/${idClient}`
															);
														}}
														type="button"
														variant="contained"
														color="default"
														style={{
															marginRight: 30,
															marginTop: 30,
														}}
													>
														Cancelar
													</Button>
													<Button
														disabled={saveLoading}
														type="button"
														variant="contained"
														color="primary"
														onClick={save}
														style={{
															marginTop: 30,
														}}
													>
														{saveLoading
															? "Salvando..."
															: "Salvar"}
													</Button>
												</Grid>
											</Grid>
										</form>
									</Grid>
								</Paper>
							</Grid>
						)}
					</Grid>
					<AlertDialog
						message={message}
						onClose={() => setMessage(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
					/>
				</Container>
			</main>
		</div>
	);
}
