import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Base from "./../Base";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import api from "./../../services/axios";
import AlertDialog from "./../../compoments/AlertDialog";
import Typography from "@material-ui/core/Typography";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { isPermission } from "../../services/permission";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 700,
  },
}));

export default function PlanosCreate({ history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("updateuserbusinessgestor")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const rota = "planos";
  const roleName = "Planos";

  const [redirect] = React.useState(false);
  const [nome, setNome] = React.useState("");
  const [descricao, setDescricao] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [valorPlano, setValorPlano] = React.useState(null);
  const [maxRotulos, setMaxRotulos] = React.useState("0");
  const [maxUsers, setMaxUsers] = React.useState("0");
  const [normalIndications, setNormalIndications] = React.useState("0");
  const [highlightIndications, setHighlightIndications] = React.useState("0");

  const save = async () => {
    const data = new FormData();
    data.append("descricao", descricao);
    data.append("nome", nome);
    data.append("valor_plano", valorPlano);
    data.append("max_rotulos", maxRotulos);
    data.append("max_users", maxUsers);
    data.append("normal_indications", normalIndications);
    data.append("highlight_indications", highlightIndications);
    try {
      await api.post("/v3/planos", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push("/" + rota);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
    }
  };

  const classes = useStyles();

  const handleValorPlano = (e) => {
    setValorPlano(e.target.value);
  };
  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={`/${rota}`}>
                  {roleName}
                </Link>
                <Typography color="textPrimary">Cadastro</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <form className={classes.form} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="nome"
                        label="Nome"
                        name="nome"
                        autoComplete="nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={2}
                        variant="outlined"
                        required
                        fullWidth
                        id="descricao"
                        label="Descrição"
                        name="descricao"
                        autoComplete="descricao"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputProps={{
                          allowEmptry: true,
                          inputProps: {
                            max: 99,
                            min: 0,
                          },
                        }}
                        type="number"
                        variant="outlined"
                        required
                        fullWidth
                        id="valorPlano"
                        label="Valor do plano"
                        name="valorPlano"
                        autoComplete="valorPlano"
                        value={valorPlano}
                        onChange={handleValorPlano}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputProps={{
                          inputProps: {
                            max: 99,
                            min: 0,
                          },
                        }}
                        type={"number"}
                        variant="outlined"
                        required
                        fullWidth
                        id="maxRotulos"
                        label="Qtd. Rótulos"
                        name="maxRotulos"
                        autoComplete="maxRotulos"
                        value={maxRotulos}
                        onChange={(e) => setMaxRotulos(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputProps={{
                          inputProps: {
                            max: 99,
                            min: 0,
                          },
                        }}
                        type={"number"}
                        variant="outlined"
                        required
                        fullWidth
                        id="maxUsers"
                        label="Qtd. Usuários"
                        name="maxUsers"
                        autoComplete="maxUsers"
                        value={maxUsers}
                        onChange={(e) => setMaxUsers(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputProps={{
                          inputProps: {
                            max: 99,
                            min: 0,
                          },
                        }}
                        type={"number"}
                        variant="outlined"
                        required
                        fullWidth
                        id="normalIndications"
                        label="Qtd. Indicações Normais"
                        name="normalIndications"
                        autoComplete="normalIndications"
                        value={normalIndications}
                        onChange={(e) => setNormalIndications(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputProps={{
                          inputProps: {
                            max: 99,
                            min: 0,
                          },
                        }}
                        type={"number"}
                        variant="outlined"
                        required
                        fullWidth
                        id="highlightIndications"
                        label="Qtd. Indicações Destaque"
                        name="highlightIndications"
                        autoComplete="highlightIndications"
                        value={highlightIndications}
                        onChange={(e) =>
                          setHighlightIndications(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={() => {
                          history.push(`/${rota}`);
                        }}
                        type="button"
                        variant="contained"
                        color="default"
                        style={{ marginRight: 30 }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={save}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
