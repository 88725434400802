import React from "react";
import { useStyles } from "./styles";

const Badge = ({ texto, cor }) => {
	const classes = useStyles();

	return <div className={`${classes.badge} ${classes[cor]}`}>{texto}</div>;
};

export default Badge;
