import {
  AppBar,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import AlertDialog from "../../../compoments/AlertDialog";
import api from "../../../services/axios";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  card: {
    maxWidth: 345,
    marginTop: 10,
  },
  media: {
    height: 140,
    objectFit: "contain",
  },
  input: {
    display: "none",
  },
  textColor: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px",
    flexDirection: "row",
    borderRadius: "4px",
    alignItems: "center",
    border: "1px solid #bcc2bf",
    justifyContent: "space-between",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [values, setValues] = useState({
    facebook_url: "",
    instagram_url: "",
    cor_texto_cabecalho: "",
    banner: { uri: "" },
    quemSomos: "",
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const user_storage = localStorage.getItem("user");
    if (user_storage) {
      const { id } = JSON.parse(user_storage);

      const user_data = await api.get("/v3/users/" + id);

      const user = user_data.data;

      if (user.business) {
        setValues({
          facebook_url: user.business.facebook_url,
          instagram_url: user.business.instagram_url,
          cor_texto_cabecalho: user.business.cor_texto_cabecalho,
          informacoes_complementares_catalogo:
            user.business.informacoes_complementares_catalogo,
          informacoes_cabecalho_catalogo:
            user.business.informacoes_cabecalho_catalogo,
          banner: {
            uri: user.business.banner ? user.business.banner.url : "",
          },
          quemSomos: user.quemSomos ?? ""
        });
      }
    }
  };

  const addPhoto = (event) => {
    const photoSelected = event.target.files[0];
    const typeArr = photoSelected.type.split("/");
    const tp = ["jpg", "jpeg", "png"];
    if (
      typeArr &&
      typeArr[0] &&
      typeArr[0] === "image" &&
      typeArr[1] &&
      tp.indexOf(typeArr[1].toLowerCase()) !== -1
    ) {
      const photoNew = {
        uri: URL.createObjectURL(photoSelected),
        id: ((Date.now() % 1000) / 1000).toString(),
        preview: true,
        image: photoSelected,
      };
      setValues((oldValues) => ({ ...oldValues, banner: photoNew }));
    } else {
      alert("Imagem inválida, utilize o formato 1944 x 300");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    if (e.hex) {
      setValues((oldValue) => ({ ...oldValue, cor_texto_cabecalho: e.hex }));
      return;
    }

    const { name, value } = e.target;
    setValues((oldValue) => ({ ...oldValue, [name]: value }));
  };

  const save = async () => {
    const data = new FormData();
    data.append("_method", "PUT");
    data.append("facebook_url", values.facebook_url);
    data.append("instagram_url", values.instagram_url);
    data.append("cor_texto_cabecalho", values.cor_texto_cabecalho);
    data.append("description", values.informacoes_cabecalho_catalogo);
    data.append(
      "informacoes_complementares_catalogo",
      values.informacoes_complementares_catalogo
    );
    data.append(
      "quemSomos",
      values.quemSomos
    );
    if (values.banner && values.banner.image) {
      data.append("banner[]", values.banner.image, values.banner.image.name);
    }
    try {
      await api.post("/gestor/v1/perfil", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      setMessage({
        message: "Catálogo atualizado com sucesso.",
        title: "Sucesso",
      });

      handleClose();
    } catch (error) {
      setMessage({
        message: "Erro ao atualizar catálogo.",
        title: "Erro",
      });
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Alterar
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{ backgroundColor: "#7d1f1a" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Alterar dados do catálogo
            </Typography>
            <Button autoFocus color="inherit" onClick={save}>
              salvar
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 24 }}>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <TextField
              variant="outlined"
              fullWidth
              id="facebook_url"
              label="Facebook"
              name="facebook_url"
              value={values.facebook_url}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <TextField
              variant="outlined"
              fullWidth
              id="instagram_url"
              label="Instagram"
              name="instagram_url"
              value={values.instagram_url}
              onChange={handleChange}
            />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 10 }} wrap="wrap">
            <Grid item xs={6} style={{ minWidth: "350px" }}>
              <div className={classes.textColor}>
                <span
                  style={{
                    color: "#737a75",
                    fontSize: "17px",
                    padding: "10px",
                    border: `3px solid ${values.cor_texto_cabecalho}`,
                    borderRadius: "5px",
                    marginRight: "5px",
                  }}
                >
                  Cor atual : {values.cor_texto_cabecalho ?? "Nenhuma"}
                </span>
                <TwitterPicker
                  colors={[
                    "#4D4D4D",
                    "#999999",
                    "#FFFFFF",
                    "#F44E3B",
                    "#FE9200",
                    "#FCDC00",
                    "#A4DD00",
                    "#68CCCA",
                    "#73D8FF",
                    "#AEA1FF",
                    "#FDA1FF",
                    "#333333",
                    "#808080",
                    "#cccccc",
                    "#D33115",
                    "#E27300",
                    "#FCC400",
                    "#B0BC00",
                    "#68BC00",
                    "#16A5A5",
                    "#009CE0",
                    "#7B64FF",
                    "#FA28FF",
                    "#000000",
                    "#666666",
                    "#B3B3B3",
                    "#9F0500",
                    "#C45100",
                    "#FB9E00",
                    "#808900",
                    "#194D33",
                    "#0C797D",
                    "#0062B1",
                    "#653294",
                    "#AB149E",
                  ]}
                  color={values.cor_texto_cabecalho ?? "#FFFFFF"}
                  value={values.cor_texto_cabecalho}
                  id="cor_texto_cabecalho"
                  onChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="informacoes_cabecalho_catalogo"
                label="Informações para o cabeçalho do catálogo"
                name="informacoes_cabecalho_catalogo"
                value={values.informacoes_cabecalho_catalogo}
                onChange={handleChange}
                inputProps={{
                  maxLength: 150,
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                id="informacoes_complementares_catalogo"
                label="Informações complementares do catálogo"
                name="informacoes_complementares_catalogo"
                value={values.informacoes_complementares_catalogo}
                onChange={handleChange}
                style={{ marginTop: 15 }}
                inputProps={{
                  maxLength: 80,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="quemSomos"
                label="Quem somos"
                name="quemSomos"
                value={values.quemSomos}
                onChange={handleChange}
                style={{ marginTop: 15 }}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <div
              className={classes.root}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItens: "start",
              }}
            >
              <input
                accept="image/*"
                className={classes.input}
                id="banner"
                type="file"
                name="banner"
                onChange={addPhoto}
              />
              <label htmlFor="banner">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: "#7d1f1a" }}
                >
                  Upload Banner
                </Button>
              </label>
              <p style={{ color: "red" }}>*Tamanho: 1944 x 300</p>
            </div>
            {values.banner.uri && (
              <>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      width="300"
                      src={values.banner.uri}
                      className={classes.media}
                      onError={(e) => console.log(e)}
                      image={values.banner.uri}
                      title="Banner"
                    />
                  </CardActionArea>
                </Card>
              </>
            )}
          </Grid>
        </div>
      </Dialog>
      <AlertDialog
        message={message.message}
        onClose={() => setMessage({})}
        title={message.title}
        buttonSuccessText={"OK"}
      />
    </div>
  );
}
