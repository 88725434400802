import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
}));

export default function PermissionsUpdate({ match, history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("updatepermissionsgestor")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const rota = "permissions";
  const roleName = "Permissões";

  const [redirect] = React.useState(false);
  const [id, setId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const loadData = useCallback(async () => {
    setId(match.params.id);
    const data = await api.get("/v3/permissions/" + match.params.id);
    setName(data.data.name);
    setDescription(data.data.description);
    setLoading(false);
  }, [match]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const save = async () => {
    const data = new FormData();
    data.append("name", name);
    data.append("description", description);
    data.append("_method", "PUT");
    try {
      await api.post("/v3/permissions/" + id, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push("/" + rota);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" to={`/${rota}`}>
                    {roleName}
                  </Link>
                  <Typography color="textPrimary">Editar</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          label="Nome"
                          name="name"
                          autoComplete="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="description"
                          label="Descrição"
                          name="description"
                          autoComplete="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            history.push(`/${rota}`);
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          // className={classes.submit}
                          style={{ marginRight: 30 }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={save}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          )}
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
