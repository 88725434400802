/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

export default function Unavailable() {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40vh",
      }}
    >
      <h2>Funcionalidade não disponível para o seu plano.</h2>
      <h3>
        Faça um upgrade do seu plano e tenha acesso a todos os recursos do
        Intelivino.
      </h3>
    </div>
  );
}
