/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import DatePicker from "../../../compoments/DatePicker";
import newApi from "../../../services/axios/new-api";
import MaterialTableComponent from "./../../../compoments/MaterialTableComponent";
import Base from "./../../Base";
import { useStylesIndex } from "./styles";
import jwtDecode from "../../../services/jwt";
import Unavailable from "../unavailable";
import { featuresAvailable } from "../../../services/permission";
import Loading from "../../../compoments/Loading";

export default function Index({ history }) {
  const roleName = "Estoque";

  const classes = useStylesIndex();
  const [historiesTypeId, setHistoriesTypeId] = React.useState("");
  const [historyTypeId, setHistoryTypeId] = React.useState(null);
  const [historyTypeOperation, setHistoryTypeOperation] = React.useState(null);
  const [initialDate, setInitialDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const tableRef = useRef();
  const [historyTypes, setHistoryTypes] = React.useState([]);
  const [loja, setLoja] = React.useState("");
  const [actionsTable] = React.useState(loadActionsTable());
  const [stockAvailable, setStockAvailable] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setIsLoading(true);
    loadHistoryTypes();
    setLoja(getUser());
    loadPermissionPage();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleLoadData();
  }, [historyTypeOperation, historyTypeId, initialDate, endDate]);

  const getUser = () => {
    const data = jwtDecode(localStorage.getItem("token"));
    return data.user;
  };

  function loadActionsTable() {
    let actionsDt = [];
    actionsDt.push({
      icon: "add",
      tooltip: "Criar movimentação",
      isFreeAction: true,
      onClick: () => history.push(`/stock/moviment/create`),
    });

    actionsDt.push({
      icon: "edit",
      tooltip: "Editar",
      onClick: (event, rowData) => {
        history.push(`/stock/moviment/update/${rowData.id}`);
      },
    });

    return actionsDt;
  }

  const loadPermissionPage = async () => {
    const { stockAvailable } = await featuresAvailable(loja.id ?? getUser().id);
    setStockAvailable(stockAvailable);
  };

  const handleHistoryTypeId = (e, value, reason) => {
    const id = value.props["data-id"];
    setHistoryTypeId(id);
    setHistoriesTypeId(e.target.value);
  };

  const handleHistoryTypeOperation = (e) => {
    setHistoryTypeOperation(e.target.value ? e.target.value.trim() : null);
  };

  const loadHistoryTypes = async () => {
    const { data } = await newApi.get(`/stock/historytypes`);
    setHistoryTypes(data);
  };

  const handleLoadData = async () => {
    try {
      const idUser = loja.id ?? getUser().id;
      const { data } = await newApi.get(
        `/stock/stockhistory/${idUser}?limit=50`,
        {
          params: {
            historyType: historyTypeOperation,
            historyTypeId: historyTypeId,
            initialDate: initialDate
              ? moment(initialDate).format("YYYY-MM-DD")
              : null,
            endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
          },
        }
      );
      const options = {
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      };
      const formatNumber = new Intl.NumberFormat("pt-BR", options);
      const moviments = data.map((item) => ({
        ...item,
        date: moment(item.date).format("DD/MM/YYYY"),
        priceUnit: formatNumber.format(item.priceUnit),
        priceTotal: formatNumber.format(item.priceTotal),
      }));

      setTableData(moviments);
    } catch (error) {
      return;
    }
  };

  const filters = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "12px",
      }}
    >
      <div style={{ marginRight: "100px" }}>
        <DatePicker
          value={initialDate}
          onChange={setInitialDate}
          label="Data Inicial"
          id="initialDate"
          name="initialDate"
        />
      </div>
      <div style={{ marginRight: "100px" }}>
        <DatePicker
          value={endDate}
          onChange={setEndDate}
          label="Data Final"
          id="endDate"
          name="endDate"
        />
      </div>
      <div style={{ marginRight: "100px" }}>
        <InputLabel>Filtrar por historico:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={historiesTypeId}
          name="historiesTypeId"
          onChange={handleHistoryTypeId}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value="">Todos</MenuItem>
          {historyTypes.map((data, k) => (
            <MenuItem value={data.name} key={data.id} data-id={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ marginRight: "60px" }}>
        <InputLabel>Filtrar por natureza:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={historyTypeOperation}
          name="historyTypeOperation"
          onChange={handleHistoryTypeOperation}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value={null}>Ambos</MenuItem>
          <MenuItem value="INPUT">Entrada</MenuItem>
          <MenuItem value="OUTPUT">Sáida</MenuItem>
        </Select>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Base />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {!stockAvailable && stockAvailable !== null ? (
          Unavailable()
        ) : (
          <Container maxWidth="lg" className={classes.container}>
            {isLoading || stockAvailable === null ? (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">{roleName}</Typography>
                  </Breadcrumbs>
                </Grid>

                {filters()}

                <Grid item xs={12}>
                  <MaterialTableComponent
                    tableRef={() => tableRef.current}
                    columns={[
                      { title: "Data", field: "date" },
                      {
                        title: "Rótulo",
                        field: "itemName",
                        width: "30%",
                      },
                      { title: "Cod Ref", field: "codeRef" },
                      {
                        title: "Histórico",
                        field: "historyTypeName",
                      },
                      {
                        title: "Fornecedor/Cliente",
                        field: "personVinculatedName",
                      },
                      {
                        title: "Quant",
                        field: "quantity",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Valor Unit R$",
                        field: "priceUnit",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Valor Total R$ ",
                        field: "priceTotal",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                    ]}
                    data={tableData}
                    title="Relátorio de Movimentação"
                    actions={actionsTable}
                    options={{
                      search: false,
                      sorting: false,
                      actionsColumnIndex: -1,
                      pageSize: 10,
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Container>
        )}
      </main>
    </div>
  );
}
