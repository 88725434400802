import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MTableToolbar } from "material-table";
import React, { useCallback, useEffect, useRef } from "react";
import { Redirect } from "react-router";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import ConfirmDialog from "./../../compoments/ConfirmDialog";
import MaterialTableComponent from "./../../compoments/MaterialTableComponent";
import api from "./../../services/axios";
import jwtDecode from "./../../services/jwt";
import Base from "./../Base";

const dateFormat = (date, time) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yy = date.getFullYear().toString().substr(-2);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  let timeString = "";
  if (time) {
    timeString =
      " às " +
      hours.toString().padStart(2, "0") +
      "h" +
      minutes.toString().padStart(2, "0");
  }
  return dd + "/" + mm + "/" + yy + " " + timeString;
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Index({ history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("rotulosgestor")) {
      history.push("/401");
    }

    let actionsDt = [];

    if (isPermission("updaterotulosgestor")) {
      actionsDt.push({
        icon: "edit",
        tooltip: "Editar",
        onClick: (event, rowData) => {
          history.push(`/rotulos/update/${rowData.id}`);
        },
      });
    }

    if (isPermission("deleterotulosgestor")) {
      actionsDt.push({
        icon: "delete",
        tooltip: "Deletar",
        onClick: (event, rowData) => {
          setIdSelected(rowData.id);
          setMessageDelete("Deseja excluir?");
        },
        disabled: false,
      });
    }

    if (isPermission("createrotulosgestor")) {
      actionsDt.push({
        icon: "add",
        tooltip: "Criar " + roleName,
        isFreeAction: true,
        onClick: (event) => history.push(`/rotulos/create`),
      });
    }

    setActionsTable(actionsDt);
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  useEffect(() => {
    const tokenData = jwtDecode(localStorage.getItem("token"));
    setShowLoja(!!tokenData.user.roles.find((r, k) => r === "administrator"));
  }, []);

  const loadLojas = useCallback(async () => {
    const dataLojas = await api.get("/v3/users?role=business");
    setLojas(dataLojas.data.data);
  }, []);

  useEffect(() => {
    loadLojas();
  }, [loadLojas]);

  const roleName = "Rótulos";

  const classes = useStyles();

  const [redirect] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [messageDelete, setMessageDelete] = React.useState(false);
  const [idSelected, setIdSelected] = React.useState(false);
  const tableRef = useRef();
  const [actionsTable, setActionsTable] = React.useState([]);
  const [lojas, setLojas] = React.useState([]);
  const [loja, setLoja] = React.useState("");
  const [lojaValue, setLojaValue] = React.useState("");
  const [showLoja, setShowLoja] = React.useState(false);

  const deleteAction = async (id) => {
    const data = await api.delete("/v3/indicacoes/" + id);
    if (!data.data.status) {
      setAlertTitle("Erro");
      setMessage("Não foi possível deletar");
    } else {
      setAlertTitle("");
      setMessage("Deletado com sucesso");
      tableRef.current.onQueryChange();
    }
  };

  const search = () => {
    tableRef.current.onQueryChange();
  };

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      {/* TOPO E MENU DRAWER*/}
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">{roleName}</Typography>
              </Breadcrumbs>
            </Grid>
            {showLoja && (
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <Autocomplete
                    value={loja}
                    onChange={(event, newValue) => {
                      setLoja(newValue);
                      search();
                    }}
                    inputValue={lojaValue}
                    onInputChange={(event, newInputValue) => {
                      setLojaValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={lojas}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Filtrar por loja:" />
                    )}
                    getOptionLabel={(option) => option && option.name}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <MaterialTableComponent
                tableRef={tableRef}
                columns={[
                  { title: "Nome", field: "nome" },
                  { title: "Loja", field: "user.name" },
                  { title: "Data de Cadastro", field: "created_at" },
                ]}
                components={{
                  Toolbar: (props) => (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{ paddingLeft: 20, paddingBottom: 20 }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ alignSelf: "center", marginTop: 20 }}
                        >
                          <MTableToolbar {...props} />
                        </Grid>
                      </Grid>
                    </>
                  ),
                }}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    api
                      .get(
                        `/gestor/v1/indicacoes?perPage=${query.pageSize}&page=${
                          query.page + 1
                        }&search=${query.search}&userId=${loja && loja.id}`
                      )
                      .then(function ({ data }) {
                        let dt = data.data.map((i, k) => {
                          return {
                            ...i,
                            ...{
                              created_at: dateFormat(
                                new Date(i.created_at),
                                true
                              ),
                            },
                          };
                        });
                        let obj = {
                          data: dt,
                          page: data.page - 1,
                          totalCount: data.total,
                        };
                        resolve(obj);
                      })
                      .catch(function (error) {
                        console.log("------error", error);
                        resolve();
                      });
                  })
                }
                title=""
                actions={actionsTable}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
          <ConfirmDialog
            message={messageDelete}
            onClose={() => setMessageDelete(false)}
            title={"Atenção"}
            buttonSuccessText={"EXCLUIR"}
            onConfirm={async () => {
              await deleteAction(idSelected);
              setMessageDelete(false);
            }}
          />
        </Container>
      </main>
    </div>
  );
}
