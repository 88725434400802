import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import camera from "../../assets/images/camera.svg";

export default function AudioUpload({
  onChange,
  previewChange,
  cropFlag,
  previewImage = false,
  remove = false,
  removeFlag = false,
  onCropComplete = false,
  thumbnail = false,
}) {
  const [aspect] = React.useState(4 / 3);

  return (
    <>
      <div style={{ display: !previewChange ? "block" : "none" }}>
        <label
          id="thumbnail"
          style={{
            borderStyle: "dotted",
            borderWidth: 3,
            borderColor: "#e0e0e0",
            display: "flex",
            width: 150,
            height: 150 / aspect,
            backgroundSize: "cover",
            backgroundImage: previewChange
              ? `url(${previewChange})`
              : `url(${previewImage})`,
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            style={{
              display: "none",
            }}
            onChange={onChange}
          />
          <img
            src={camera}
            alt="Selecionar imagem"
            style={{
              display: "flex",
              flex: 1,
              padding: 45,
            }}
          />
        </label>
        {previewImage.length > 0 && (
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => {
              if (typeof remove === "function") {
                remove(true);
              }
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      {previewChange && (
        <>
          <div
            style={{
              position: "relative",
              width: 150,
              backgroundColor: "#333",
            }}
          >
            <img
              src={previewChange ? previewChange : previewImage}
              alt="Selecionar imagem"
              width={150}
              style={{
                display: "flex",
                flex: 1,
                padding: 0,
              }}
            />
          </div>
          {removeFlag && (
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => {
                if (typeof remove === "function") {
                  remove();
                }
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </>
  );
}
