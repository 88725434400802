/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import AlertDialog from "./../../compoments/AlertDialog";
import api from "./../../services/axios";
import {searchCep} from "../../services/address/viacep";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	input: {
		display: "none",
	},
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	appBarSpacer: theme.mixins.toolbar,
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	disabled: {
		padding: 30,
		backgroundColor: "red",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: "100%",
	},
}));



export default function Create({ match, history }) {
	const roleName = "Clientes";
	const [message, setMessage] = React.useState(false);
	const [alertTitle, setAlertTitle] = React.useState("");
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [name, setName] = React.useState("");
	const [mail, setMail] = React.useState("");
	const [mobilePhone, setMobilePhone] = React.useState("");
	const [cpfCnpj, setCpfCnpj] = React.useState("");
	const [note, setNote] = React.useState("");
	const [typeClient, setTypeClient] = React.useState(0);
	const [addressName, setAddressName] = React.useState("");
	const [addressStreet, setAddressStreet] = React.useState("");
	const [addressNumber, setAddressNumber] = React.useState("");
	const [addressDistrict, setAddressDistrict] = React.useState("");
	const [addressCity, setAddressCity] = React.useState("");
	const [addressState, setAddressState] = React.useState("");
	const [addressComplement, setAddressComplement] = React.useState("");
	const [addressZipCode, setAddressZipCode] = React.useState("");
	const [labelTypePerson, setLabelTypePerson] = React.useState("CPF/CNPJ");

	const [status, setStatus] = React.useState(true);
	const typePerson = [
		{
			id: 0,
			label: "",
		},
		{
			id: 1,
			label: "Física",
		},
		{
			id: 2,
			label: "Juridica",
		},
	];

	const save = async () => {
		setSaveLoading(true);
		const data = new FormData();

		data.append("name", name);
		data.append("mail", mail);
		data.append("mobile_phone", mobilePhone);
		data.append("cpf_cnpj", cpfCnpj);
		data.append("note", note);
		data.append("type", typeClient);
		data.append("address_name", addressName);
		data.append("address_street", addressStreet);
		data.append("address_number", addressNumber);
		data.append("address_district", addressDistrict);
		data.append("address_city", addressCity);
		data.append("address_state", addressState);
		data.append("address_complement", addressComplement);
		data.append("address_zip_code", addressZipCode);
		data.append("is_active", !!status ? 1 : 0);
		data.append("_method", "POST");

		try {
			await api.post("/v6/lead", data, {
				headers: {
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			});
			setAlertTitle("");
			history.push("/clientes");
			setSaveLoading(false);
		} catch (error) {
			console.log(error.response);
			setAlertTitle("Erro");
			setMessage(error.response.data.message || "Erro");
			setSaveLoading(false);
		}
	};

	const classes = useStyles();

    const populateAddress = async (cep) => {
		const validacep = /^\d{8}$/;

		if (validacep.test(cep) && cep.length === 8) {
			const data = await searchCep(cep);
			if (data.logradouro) {
				setAddressStreet(data.logradouro);
			}
			if (data.bairro) {
				setAddressDistrict(data.bairro);
			}
			if (data.localidade) {
				setAddressCity(data.localidade);
			}
			if (data.uf) {
				setAddressState(data.uf);
			}
		}
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Link color="inherit" to={`/clientes`}>
									{roleName}
								</Link>
								<Typography color="textPrimary">
									Criar
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Grid item xs={6}>
									<form className={classes.form} noValidate>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													required
													fullWidth
													id="name"
													label="Nome"
													name="name"
													autoComplete="name"
													value={name}
													onChange={(e) =>
														setName(e.target.value)
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													required
													fullWidth
													id="mail"
													label="E-mail"
													name="mail"
													autoComplete="mail"
													value={mail}
													onChange={(e) =>
														setMail(e.target.value)
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													required
													fullWidth
													id="mobile_phone"
													label="Celular"
													name="mobile_phone"
													autoComplete="mobile_phone"
													value={mobilePhone}
													onChange={(e) =>
														setMobilePhone(
															e.target.value
														)
													}
												/>
											</Grid>
											<>
												<Grid item xs={8}>
													<FormControl
														className={
															classes.formControl
														}
													>
														<InputLabel id="demo-simple-select-label">
															Tipo Pessoa
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															defaultValue={
																typePerson[0]
															}
															value={typeClient}
															onChange={(e) => {
																setTypeClient(
																	e.target
																		.value
																);

																if (
																	e.target
																		.value ===
																	1
																) {
																	setLabelTypePerson(
																		"CPF"
																	);
																} else if (
																	e.target
																		.value ===
																	2
																) {
																	setLabelTypePerson(
																		"CNPJ"
																	);
																}
															}}
														>
															{typePerson.map(
																(data, k) => (
																	<MenuItem
																		value={
																			data.id
																		}
																		key={
																			data.id
																		}
																	>
																		{
																			data.label
																		}
																	</MenuItem>
																)
															)}
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="cpf_cnpj"
														label={labelTypePerson}
														name="cpf_cnpj"
														autoComplete="cpf_cnpj"
														value={cpfCnpj}
														onChange={(e) =>
															setCpfCnpj(
																e.target.value
															)
														}
													/>
												</Grid>
											</>
											<>
												<Grid item xs={8}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_name"
														label="Nome do endereço"
														name="address_name"
														autoComplete="address_name"
														value={addressName}
														onChange={(e) =>
															setAddressName(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_zip_code"
														label="CEP"
														name="address_zip_code"
														autoComplete="address_zip_code"
														value={addressZipCode}
														onChange={(e) => {
															populateAddress(
																e.target.value
															);
															setAddressZipCode(
																e.target.value
															);
														}}
													/>
												</Grid>
												<Grid item xs={8}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_street"
														label="Endereço"
														name="address_street"
														autoComplete="address_street"
														value={addressStreet}
														onChange={(e) =>
															setAddressStreet(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={4}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_number"
														label="Número"
														name="address_number"
														autoComplete="address_number"
														value={addressNumber}
														onChange={(e) =>
															setAddressNumber(
																e.target.value
															)
														}
													/>
												</Grid>
											</>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													fullWidth
													id="address_district"
													label="Bairro"
													name="address_district"
													autoComplete="address_district"
													value={addressDistrict}
													onChange={(e) =>
														setAddressDistrict(
															e.target.value
														)
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													fullWidth
													id="address_city"
													label="Cidade"
													name="address_city"
													autoComplete="address_city"
													value={addressCity}
													onChange={(e) =>
														setAddressCity(
															e.target.value
														)
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													fullWidth
													id="address_state"
													label="Estado"
													name="address_state"
													autoComplete="address_state"
													value={addressState}
													onChange={(e) =>
														setAddressState(
															e.target.value
														)
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													fullWidth
													id="address_complement"
													label="Complemento"
													name="address_complement"
													autoComplete="address_complement"
													value={addressComplement}
													onChange={(e) =>
														setAddressComplement(
															e.target.value
														)
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													fullWidth
													id="note"
													label="Observações gerais"
													name="note"
													autoComplete="note"
													value={note}
													onChange={(e) =>
														setNote(e.target.value)
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															checked={!!status}
															onChange={(e) => {
																setStatus(
																	e.target
																		.checked ===
																		true
																		? true
																		: false
																);
															}}
															name="is_active"
															color="primary"
														/>
													}
													label="Ativo"
												/>
											</Grid>

											<Grid item xs={10}>
												<Button
													onClick={() => {
														history.push(
															`/clientes`
														);
													}}
													type="button"
													variant="contained"
													color="default"
													style={{
														marginRight: 30,
														marginTop: 30,
													}}
												>
													Cancelar
												</Button>
												<Button
													disabled={saveLoading}
													type="button"
													variant="contained"
													color="primary"
													onClick={save}
													style={{
														marginTop: 30,
													}}
												>
													{saveLoading
														? "Salvando..."
														: "Salvar"}
												</Button>
											</Grid>
										</Grid>
									</form>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<AlertDialog
						message={message}
						onClose={() => setMessage(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
					/>
				</Container>
			</main>
		</div>
	);
}
