import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
}));

export const useStylesCreateFreight = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	input: {
		display: "none",
	},
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	appBarSpacer: theme.mixins.toolbar,
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	disabled: {
		padding: 30,
		backgroundColor: "red",
	},
	formControl: {
		margin: theme.spacing(1),
		width: "12rem",
	},
    formControlCities: {
		margin: theme.spacing(1),
		width: '24.5rem',
		maxWidth: "100%",
	},
}));
