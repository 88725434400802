/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MTableToolbar } from "material-table";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Redirect } from "react-router";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import ConfirmDialog from "./../../compoments/ConfirmDialog";
import MaterialTableComponent from "./../../compoments/MaterialTableComponent";

import api from "./../../services/axios";
import jwtDecode from "./../../services/jwt";
import Base from "./../Base";
import Person from "@material-ui/icons/Person";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { getBaseUrl } from "../../env";

const dateFormat = (date, time) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yy = date.getFullYear().toString().substr(-2);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  let timeString = "";
  if (time) {
    timeString =
      " às " +
      hours.toString().padStart(2, "0") +
      "h" +
      minutes.toString().padStart(2, "0");
  }
  return dd + "/" + mm + "/" + yy + " " + timeString;
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Index({ history }) {
  const { storeURL } = getBaseUrl();
  const loadPermission = async () => {
    if (!isPermission("campanhasgestor")) {
      history.push("/401");
    }
  };

  useEffect(() => {
    loadPermission();
  }, []);

  const roleName = "Campanhas";

  const classes = useStyles();
  const [redirect] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [messageDelete, setMessageDelete] = React.useState(false);
  const [idSelected, setIdSelected] = React.useState(false);
  const [type, setType] = React.useState("");
  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.retry();
  }, [type]);

  const deleteAction = async (id) => {
    const data = await api.delete("/v3/campaigns/" + id);
    if (!data.data.status) {
      setAlertTitle("Erro");
      setMessage("Não foi possível deletar");
    } else {
      setAlertTitle("");
      setMessage("Deletado com sucesso");
      tableRef.current.onQueryChange();
    }
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  function generateURL(query) {
    let url = `/v5/campaigns?page=${
      query.page + 1
    }&comResumoTotalIndicacoes=true&search=${query.search}`;

    if (type) {
      url = url.concat(`&type=${type}`);
    }
    return url;
  }

  function getnameTypeCampaign(typeCampaign) {
    let result = "";
    switch (typeCampaign) {
      case 1:
        result = "Social Commerce";
        break;
      case 2:
        result = "Intelivino App";
        break;
      case 3:
        result = "Captura de Lead";
        break;
      case 4:
        result = "Tabela de preço";
        break;
      default:
        result = "Campanha não definida";
        break;
    }

    return result;
  }

  function getActionTableLinkCampaign(rowData) {
    const data = jwtDecode(localStorage.getItem("token"));
    let urlLoja = storeURL + "" + data.user.name_business_slug;

    return {
      icon: "link",
      tooltip: "Link",
      onClick: () => {
        window.open(`${urlLoja}?campanha=${rowData.id}`, "_blank");
      },
      hidden: parseInt(rowData.type) === 2,
    };
  }

  function getActionTableUpdate(rowData) {
    if (!isPermission("updatecampanhasgestor")) {
      return;
    }
    return {
      icon: "edit",
      tooltip: "Editar",
      onClick: () => {
        history.push(`/campanhas/update/${rowData.id}`);
      },
    };
  }

  function getActionTableDelete(rowData) {
    if (!isPermission("deletecampanhasgestor")) {
      return;
    }
    return {
      icon: "delete",
      tooltip: "Deletar",
      onClick: () => {
        setIdSelected(rowData.id);
        setMessageDelete("Deseja excluir?");
      },
      disabled: false,
    };
  }

  function getActionTableCreate() {
    if (!isPermission("createcampanhasgestor")) {
      return;
    }
    return {
      icon: "add",
      tooltip: "Criar " + roleName,
      isFreeAction: true,
      onClick: () => history.push(`/campanhas/create`),
    };
  }

  function getActionTableLeads(rowData) {
    return {
      icon: Person,
      tooltip: "Leads",
      onClick: () => {
        history.push(`/clientes/campaign/${rowData.id}`);
      },
      hidden: parseInt(rowData.type) !== 3,
    };
  }

  function getActionTableOrder(rowData) {
    return {
      icon: ShoppingCart,
      tooltip: "Pedidos",
      onClick: () => {
        history.push(`/pedidos/campaign/${rowData.id}`);
      },
      hidden: ![1, 4].includes(parseInt(rowData.type)),
    };
  }

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      {/* TOPO E MENU DRAWER*/}
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">{roleName}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Filtrar por tipo da Campanha:</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={type}
                name="type"
                onChange={handleChangeType}
                style={{ width: 200 }}
                displayEmpty
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value={1}>Social Commerce</MenuItem>
                <MenuItem value={2}>Intelivino App</MenuItem>
                <MenuItem value={3}>Captura de Lead</MenuItem>
                <MenuItem value={4}>Tabela de preço</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <MaterialTableComponent
                tableRef={tableRef}
                components={{
                  Toolbar: (props) => (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{
                          paddingLeft: 20,
                          paddingBottom: 20,
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            alignSelf: "center",
                            marginTop: 20,
                          }}
                        >
                          <MTableToolbar {...props} />
                        </Grid>
                      </Grid>
                    </>
                  ),
                }}
                columns={[
                  { title: "Nome", field: "name" },
                  {
                    title: "Campanha",
                    field: "type",
                    render: (rowData) =>
                      getnameTypeCampaign(parseInt(rowData.type)),
                  },
                  {
                    title: "Início",
                    field: "date_start",
                    render: (rowData) => {
                      if (
                        rowData.date_start &&
                        rowData.date_start !== "0000-00-00"
                      ) {
                        return moment(rowData.date_start).format("DD/MM/YY");
                      }

                      return "-";
                    },
                  },
                  {
                    title: "Fim",
                    field: "date_end",
                    render: (rowData) => {
                      if (
                        rowData.date_end &&
                        rowData.date_end !== "0000-00-00"
                      ) {
                        return moment(rowData.date_end).format("DD/MM/YY");
                      }

                      return "-";
                    },
                  },
                  {
                    title: "Rótulos",
                    field: "1",
                    render: (rowData) => rowData.totalLabels,
                  },
                  {
                    title: "Criado por",
                    field: "1",
                    render: (rowData) => rowData.user.name,
                  },
                  {
                    title: "Status",
                    field: "status",
                    render: (rowData) =>
                      !!rowData.status ? "Ativo" : "Inativo",
                  },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    api
                      .get(generateURL(query))
                      .then(function ({ data }) {
                        let dt = data.data.map((i, k) => {
                          return {
                            ...i,
                            ...{
                              created_at: dateFormat(
                                new Date(i.created_at),
                                true
                              ),
                            },
                          };
                        });
                        let obj = {
                          data: dt,
                          page: data.page - 1,
                          totalCount: data.total,
                        };
                        resolve(obj);
                      })
                      .catch(function (error) {
                        console.log("------error", error);
                        resolve();
                      });
                  })
                }
                title=""
                actions={[
                  (rowData) => getActionTableLinkCampaign(rowData),
                  getActionTableCreate(),
                  (rowData) => getActionTableUpdate(rowData),
                  (rowData) => getActionTableDelete(rowData),
                  (rowData) => getActionTableLeads(rowData),
                  (rowData) => getActionTableOrder(rowData),
                ]}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </Grid>
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
          <ConfirmDialog
            message={messageDelete}
            onClose={() => setMessageDelete(false)}
            title={"Atenção"}
            buttonSuccessText={"EXCLUIR"}
            onConfirm={async () => {
              await deleteAction(idSelected);
              setMessageDelete(false);
            }}
          />
        </Container>
      </main>
    </div>
  );
}
