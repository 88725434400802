import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import AlertDialog from "./../../compoments/AlertDialog";
import api from "./../../services/axios";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 700,
  },
}));

export default function UsuariosCreate({ history }) {
  const rota = "usersbusiness";
  const roleName = "Lojistas";

  const [redirect] = React.useState(false);
  const [name, setName] = React.useState("");
  const [fone, setFone] = React.useState("");
  const [whatsapp, setWhatsapp] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [obs, setObs] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");

  const save = async () => {
    const data = new FormData();
    data.append("name", name);
    data.append("fone", fone);
    data.append("whatsapp", whatsapp);
    data.append("email", email);
    data.append("password", password);
    data.append("cpf", cpf);
    data.append("description", description);
    data.append("status", status);
    data.append("obs", obs);
    try {
      await api.post("/v3/users/business", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push("/" + rota);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={`/${rota}`}>
                  {roleName}
                </Link>
                <Typography color="textPrimary">Cadastro</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <form className={classes.form} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        name="name"
                        autoComplete="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="description"
                        label="Descrição"
                        name="description"
                        autoComplete="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="fone"
                        label="Telefone"
                        name="fone"
                        autoComplete="fone"
                        value={fone}
                        onChange={(e) => setFone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="whatsapp"
                        label="Whatsapp"
                        name="whatsapp"
                        autoComplete="whatsapp"
                        value={whatsapp}
                        onChange={(e) => setWhatsapp(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="password"
                        label="Senha"
                        name="password"
                        autoComplete="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="cpf"
                        label="Cpf"
                        name="cpf"
                        autoComplete="cpf"
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="status"
                        label="Status"
                        name="status"
                        autoComplete="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="obs"
                        label="Observações internas"
                        name="obs"
                        autoComplete="obs"
                        value={obs}
                        onChange={(e) => setObs(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        onClick={() => {
                          history.push(`/${rota}`);
                        }}
                        type="button"
                        variant="contained"
                        color="default"
                        style={{ marginRight: 30 }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={save}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
