import React from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from './screens/Login';
import page401 from './screens/401';
import ResetPassword from './screens/ResetPassword';

import UpdateMarket from './screens/Market/update';
import PerfilForm from './screens/Perfil/PerfilForm';
import Permissions from './screens/Permissions';
import PermissionsCreate from './screens/Permissions/PermissionsCreate';
import PermissionsUpdate from './screens/Permissions/PermissionsUpdate';
import Roles from './screens/Roles';
import RolesCreate from './screens/Roles/RolesCreate';
import RolesUpdate from './screens/Roles/RolesUpdate';
import Planos from './screens/Planos';
import PlanosCreate from './screens/Planos/PlanosCreate';
import PlanosUpdate from './screens/Planos/PlanosUpdate';
import Usuarios from './screens/Usuarios';
import UsuariosCreate from './screens/Usuarios/UsuariosCreate';
import UsuariosUpdate from './screens/Usuarios/UsuariosUpdate';
import Vendedores from './screens/Vendedores';
import VendedoresCreate from './screens/Vendedores/VendedoresCreate';
import VendedoresUpdate from './screens/Vendedores/VendedoresUpdate';
import VendedoresDetail from './screens/Vendedores/VendedoresDetail';
import Consumidores from './screens/Consumidores';
import ConsumidoresCreate from './screens/Consumidores/ConsumidoresCreate';
import ConsumidoresUpdate from './screens/Consumidores/ConsumidoresUpdate';
import ConsumidoresDetail from './screens/Consumidores/ConsumidoresDetail';
import Rotulos from './screens/Rotulos';
import RotulosCreate from './screens/Rotulos/create';
import RotulosUpdate from './screens/Rotulos/update';
import Campanhas from './screens/Campanhas';
import CampanhasCreate from './screens/Campanhas/create';
import CampanhasUpdate from './screens/Campanhas/update';
import Banners from './screens/Banners';
import BannersCreate from './screens/Banners/create';
import BannersUpdate from './screens/Banners/update';
import Videos from './screens/Videos';
import VideosCreate from './screens/Videos/create';
import VideosUpdate from './screens/Videos/update';
import Sorteios from './screens/Sorteios';
import SorteiosCreate from './screens/Sorteios/create';
import SorteiosUpdate from './screens/Sorteios/update';
import SorteiosUsers from './screens/Sorteios/users';
import Categorias from './screens/Categorias';
import CategoriasCreate from './screens/Categorias/create';
import CategoriasUpdate from './screens/Categorias/update';
import Clientes from './screens/Clientes';
import ClientesUpdate from './screens/Clientes/update';
import ClientesCreate from './screens/Clientes/create';
import ClientesImport from './screens/Clientes/import';
import EnderecoCreate from './screens/Clientes/Endereco/create';
import EnderecoUpdate from './screens/Clientes/Endereco/update';
import PedidosDescricao from './screens/Pedidos/details';
import { isAuthenticated } from './auth';
import Coupons from './screens/coupons/index';
import CouponsCreate from './screens/coupons/create';
import CouponsUpdate from './screens/coupons/update';
import Pedidos from './screens/Pedidos';
import Frete from './screens/Frete';
import FreteCreate from './screens/Frete/create';
import FreteUpdate from './screens/Frete/update';
import MovimentCreate from './screens/Stock/moviment/create';
import MovimentIndex from './screens/Stock/moviment/index';
import InventoryIndex from './screens/Stock/inventory/index';
import StockAdjustmentIndex from './screens/Stock/stockadjustment/index';
import StockSheetIndex from './screens/Stock/stocksheet/index';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route 
    { ...rest } 
    render={props => (
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    )} />
)

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={PerfilForm} />
      <PrivateRoute exact path="/perfil" component={PerfilForm} />
      <PrivateRoute exact path="/updatemarket" component={UpdateMarket} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/401" component={page401} />
      <Route exact path="/recuperar-senha" component={ResetPassword} />
      <PrivateRoute exact path="/permissions" component={Permissions} />
      <PrivateRoute path="/permissions/create" component={PermissionsCreate} />
      <PrivateRoute path="/permissions/update/:id" component={PermissionsUpdate} />
      <PrivateRoute exact path="/roles" component={Roles} />
      <PrivateRoute path="/roles/create" component={RolesCreate} />
      <PrivateRoute path="/roles/update/:id" component={RolesUpdate} />
      <PrivateRoute exact path="/planos" component={Planos} />
      <PrivateRoute path="/planos/create" component={PlanosCreate} />
      <PrivateRoute path="/planos/update/:id" component={PlanosUpdate} />
      <PrivateRoute exact path="/usersbusiness" component={Usuarios} />
      <PrivateRoute path="/usersbusiness/create" component={UsuariosCreate} />
      <PrivateRoute path="/usersbusiness/update/:id" component={UsuariosUpdate} />
      <PrivateRoute exact path="/usersseller" component={Vendedores} />
      <PrivateRoute path="/usersseller/create" component={VendedoresCreate} />
      <PrivateRoute path="/usersseller/update/:id" component={VendedoresUpdate} />
      <PrivateRoute path="/usersseller/detail/:id" component={VendedoresDetail} />
      <PrivateRoute exact path="/usersfinal" component={Consumidores} />
      <PrivateRoute path="/usersfinal/create" component={ConsumidoresCreate} />
      <PrivateRoute path="/usersfinal/update/:id" component={ConsumidoresUpdate} />
      <PrivateRoute path="/usersfinal/detail/:id" component={ConsumidoresDetail} />
      <PrivateRoute exact path="/rotulos" component={Rotulos} />
      <PrivateRoute path="/rotulos/create" component={RotulosCreate} />
      <PrivateRoute path="/rotulos/update/:id" component={RotulosUpdate} />
      <PrivateRoute exact path="/campanhas" component={Campanhas} />
      <PrivateRoute path="/campanhas/create" component={CampanhasCreate} />
      <PrivateRoute path="/campanhas/update/:id" component={CampanhasUpdate} />
      <PrivateRoute exact path="/banners" component={Banners} />
      <PrivateRoute path="/banners/create" component={BannersCreate} />
      <PrivateRoute path="/banners/update/:id" component={BannersUpdate} />
      <PrivateRoute exact path="/videos" component={Videos} />
      <PrivateRoute path="/videos/create" component={VideosCreate} />
      <PrivateRoute path="/videos/update/:id" component={VideosUpdate} />
      <PrivateRoute exact path="/sorteios" component={Sorteios} />
      <PrivateRoute path="/sorteios/create" component={SorteiosCreate} />
      <PrivateRoute path="/sorteios/update/:id" component={SorteiosUpdate} />
      <PrivateRoute exact path="/sorteios/users/:id" component={SorteiosUsers} />
      <PrivateRoute exact path="/categorias" component={Categorias} />
      <PrivateRoute path="/categorias/create" component={CategoriasCreate} />
      <PrivateRoute path="/categorias/update/:id" component={CategoriasUpdate} />
      <PrivateRoute exact path="/clientes" component={Clientes} />
      <PrivateRoute exact path="/clientes/campaign/:idCampaign" component={Clientes} />
      <PrivateRoute exact path="/clientes/update/:id" component={ClientesUpdate} />
      <PrivateRoute path="/clientes/create" component={ClientesCreate} />
      <PrivateRoute path="/clientes/import" component={ClientesImport} />
      <PrivateRoute path="/clientes/update/:id/endereco/create" component={EnderecoCreate} />
      <PrivateRoute path="/clientes/update/:id/endereco/update/:addressId" component={EnderecoUpdate} />
      <PrivateRoute exact path="/coupons/" component={Coupons} />
      <PrivateRoute path="/coupons/create" component={CouponsCreate} />
      <PrivateRoute path="/coupons/update/:id" component={CouponsUpdate} />
      <PrivateRoute exact path="/pedidos/" component={Pedidos} />
      <PrivateRoute exact path="/pedidos/campaign/:campaignId" component={Pedidos} />
      <PrivateRoute exact path="/pedidos/clientes/:leadId" component={Pedidos} />
      <PrivateRoute exact path="/pedidos/cupons/:couponId" component={Pedidos} />
      <PrivateRoute exact path="/pedidos/:orderId" component={PedidosDescricao} />
      <PrivateRoute exact path="/frete/" component={Frete} />
      <PrivateRoute path="/frete/create" component={FreteCreate} />
      <PrivateRoute path="/frete/update/:id" component={FreteUpdate} />
      <PrivateRoute path="/stock/moviment/create" component={MovimentCreate} />
      <PrivateRoute path="/stock/moviment/update/:id" component={MovimentCreate} />
      <PrivateRoute exact path="/stock/moviment" component={MovimentIndex} />
      <PrivateRoute path="/stock/inventory" component={InventoryIndex} />
      <PrivateRoute path="/stock/stockadjustment" component={StockAdjustmentIndex} />
      <PrivateRoute path="/stock/stocksheet" component={StockSheetIndex} />
 
    </Switch>
  </BrowserRouter>
)

export default Routes;
