/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef } from "react";
import jwtDecode from "../../../services/jwt";
import Base from "../../Base";
import AlertDialog from "../../../compoments/AlertDialog";
import { useStylesCreate } from "./styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DatePicker from "../../../compoments/DatePicker";
import api from "../../../services/axios";
import newApi from "../../../services/axios/new-api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDebouncedCallback } from "use-debounce";
import moment from "moment";

export default function Create({ match, history }) {
	const [idHistory, setIdHistory] = React.useState(null);
	const [disableColumnsToUpdate, setDisableColumnsToUpdate] =
		React.useState(false);
	const [disablePriceUnit, setDisablePriceUnit] = React.useState(false);
	const [message, setMessage] = React.useState(false);
	const [alertTitle, setAlertTitle] = React.useState("");
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [hasMore, setHasMore] = React.useState(true);
	const [date, setDate] = React.useState(null);
	const [historic, setHistoric] = React.useState("");
	const [quantity, setQuantity] = React.useState("");
	const [priceUnit, setPriceUnit] = React.useState("");
	const [priceTotal, setPriceTotal] = React.useState(0);
	const [note, setNote] = React.useState("");
	const [historyTypeId, setHistoryTypeId] = React.useState(null);
	const [slugHistoryType, setSlugHistoryType] = React.useState(null);
	const [historyTypes, setHistoryTypes] = React.useState([]);
	const [operation, setOperation] = React.useState("");
	const [searchTerm, setSearchTerm] = React.useState("");
	const [selectedSupplier, setSelectedSupplier] = React.useState(null);
	const [supplierList, setSupplierList] = React.useState([]);
	const [supplierListInitial, setSupplierListInitial] = React.useState([]);
	const [inputValue, setInputValue] = React.useState("");
	const [searchTermCustomer, setSearchTermCustomer] = React.useState("");
	const [selectedCustomer, setSelectedCustomer] = React.useState(null);
	const [customerList, setCustomerList] = React.useState([]);
	const [customerListInitial, setCustomerListInitial] = React.useState([]);
	const [inputValueCustomer, setInputValueCustomer] = React.useState("");
	const inputRefCustomer = useRef(null);
	const [selectedItem, setSelectedItem] = React.useState(null);
	const [searchTermItem, setSearchTermItem] = React.useState("");
	const [inputValueItem, setInputValueItem] = React.useState("");
	const [items, setItems] = React.useState([]);
	const [loja, setLoja] = React.useState("");
	const [usePriceAverage, setUsePriceAverage] = React.useState(0);
	const inputRef = useRef(null);
	const inputRefItem = useRef(null);

	const [page, setPage] = React.useState(1);
	const [showSupplier, setShowSupplier] = React.useState(true);
	const historyTypesCustomer = [
		"venda",
		"devolução_de_cliente",
		"saída_para_comodato",
		"cancelamento_de_pedido_de_cliente",
	];

	useEffect(() => {
		if (match.params.id) {
			setIdHistory(match.params.id);
			loadData(match.params.id);
			setDisableColumnsToUpdate(true);
		} else {
			loadItems();
		}
		loadHistoryTypes();
		loadSupplier();
		loadCustomer();
		setLoja(getUser());
	}, []);

	useEffect(() => {
		const searchSupplier = async () => {
			const idUser = loja.id ?? getUser().id;
			try {
				const { data } = await newApi.get(
					`/supplier/${idUser}?search=${searchTerm}`
				);
				setSupplierList(data);
			} catch (error) {
				setAlertTitle("Erro");
				setMessage(error.response.data.message || "Erro");
			}
		};

		if (searchTerm) {
			searchSupplier();
		} else {
			setSupplierList(supplierListInitial);
		}
	}, [searchTerm]);

	useEffect(() => {
		const searchCustomer = async () => {
			const idUser = loja.id ?? getUser().id;
			try {
				const { data } = await newApi.get(
					`/customer/${idUser}?search=${searchTerm}&limit=100`
				);
				setCustomerList(data);
			} catch (error) {
				setAlertTitle("Erro");
				setMessage(error.response.data.message || "Erro");
			}
		};

		if (searchTerm) {
			searchCustomer();
		} else {
			setCustomerList(customerListInitial);
		}
	}, [searchTermCustomer]);

	useEffect(() => {
		loadItems();
	}, [page]);

	useEffect(() => {
		calcPriceTotal(quantity, priceUnit);
	}, [quantity, priceUnit]);

	useEffect(() => {
		setSelectedSupplier(null);
		setInputValue("");
		setSearchTerm("");
	}, [showSupplier]);

	const loadData = async (idHistory) => {
		const idUser = loja.id ?? getUser().id;
		const { data } = await newApi.get(
			`/stock/stockhistory/${idUser}/${idHistory}`
		);

		const [date] = data.date.split("T");

		setDate(moment(date).format("ll"));
		setSelectedItem({
			id: data.item.id,
			title: data.item.name,
		});
		setHistoryTypeId(data.historyType.id);
		setHistoric(data.historyType.name);
		setSlugHistoryType(data.historyType.slug);

		const nameOperation =
			data.historyType.operation === "INPUT" ? "ENTRADA" : "SAÍDA";
		setOperation(nameOperation);
		setQuantity(data.quantity);
		setPriceUnit(data.priceUnit);
		setPriceTotal(data.priceTotal);
		if (data.supplier?.id) {
			setShowSupplier(true);
			setSelectedSupplier({
				id: data.supplier.id,
				name: data.supplier.name,
			});
		}

		if (data.customer?.id) {
			setSelectedCustomer({
				id: data.customer.id,
				name: data.customer.name,
			});
		}
		setNote(data.complement);
	};

	const loadHistoryTypes = async () => {
		const { data } = await newApi.get(`/stock/historytypes`);
		setHistoryTypes(data);
	};

	const getUser = () => {
		const data = jwtDecode(localStorage.getItem("token"));
		return data.user;
	};

	const loadSupplier = async () => {
		const idUser = loja.id ?? getUser().id;
		const { data } = await newApi.get(`/supplier/${idUser}`);
		setSupplierList(data);
		setSupplierListInitial(data);
	};

	const loadCustomer = async () => {
		const idUser = loja.id ?? getUser().id;
		const { data } = await newApi.get(`/customer/${idUser}?limit=100`);
		setCustomerList(data);
		setCustomerListInitial(data);
	};

	const handleSelectSupplier = (event, supplier) => {
		setSelectedSupplier(supplier);
		setSearchTerm(supplier ? supplier.name : "");
		setSupplierList([]);
	};

	const handleInputChange = (event, value) => {
		setInputValue(value);
		setSearchTerm(value);
	};

	const handleBlur = () => {
		if (!selectedSupplier) {
			setInputValue(inputValue || searchTerm);
		}
	};

	const handleFocus = () => {
		setInputValue(inputValue || searchTerm);
	};

	const handleSelectItem = (event, item) => {
		setSelectedItem(item);
		setSearchTermItem(item ? item.title : "");
		setItems([]);
	};

	const handleBlurItem = () => {
		if (!selectedItem) {
			setInputValueItem(inputValueItem || searchTermItem);
		}
	};

	const handleFocusItem = () => {
		setInputValueItem(inputValueItem || searchTermItem);
	};

	const handleSelectCustomer = (event, customer) => {
		setSelectedCustomer(customer);
		setSearchTermCustomer(customer ? customer.name : "");
		setCustomerList([]);
	};

	const handleInputChangeCustomer = (event, value) => {
		setInputValueCustomer(value);
		setSearchTermCustomer(value);
	};

	const handleBlurCustomer = () => {
		if (!selectedCustomer) {
			setInputValueCustomer(inputValueCustomer || searchTermCustomer);
		}
	};

	const handleFocusCustomer = () => {
		setInputValueCustomer(inputValueCustomer || searchTermCustomer);
	};

	const handleHistoryTypeId = (e, value, reason) => {
		const operation = value.props["data-operation"];
		const slug = value.props["data-slug"];
		const historyTypeId = value.props["data-id"];
		const nameOperation = operation === "INPUT" ? "ENTRADA" : "SAÍDA";
		setOperation(nameOperation);
		setShowSupplier(true);
		setUsePriceAverage(0);
		setHistoryTypeId(historyTypeId);
		setSlugHistoryType(slug);
		setHistoric(e.target.value);
	};

	const calcPriceTotal = (quantity, priceUnit) => {
		if (quantity > 0 && priceUnit > 0) {
			const total = parseFloat((quantity * priceUnit).toFixed(2));
			setPriceTotal(total);
		}
	};

	const handleListScroll = (event) => {
		const { scrollTop, clientHeight, scrollHeight } = event.target;
		if (scrollHeight - scrollTop === clientHeight && hasMore) {
			setPage((prevPage) => prevPage + 1);
		}
	};

	const debounceSearchRotulosInApi = useDebouncedCallback(() => {
		loadItems();
	}, 800);

	const loadItems = async () => {
		const idUser = loja.id ?? getUser().id;
		const search = inputValueItem ?? searchTermItem ?? "";
		setHasMore(true);
		const dataRotulos = await api.get(
			`v5/indicacoes?perPage=20&userId=${idUser}&page=${page}&search=${search}&orderBy=name&incluirRotuloSemEstoque=true`
		);
		let rotulosData = dataRotulos.data.data.map((i, k) => {
			return {
				title: i.nome,
				id: i.id,
				nome: i.nome,
				estoque: i.estoque,
				custoMedio: i.priceAverage,
				status: i.status.id,
				columnToFilter: `${i.nome}, ${i.meta_pais_descricao}, ${i.safra}, ${i.vinicola}, ${i.meta_regiao_descricao}, ${i.meta_tipo_vinho_descricao}`,
			};
		});

		if (page === 1) {
			setItems(rotulosData);
		} else {
			setItems((prevList) => [...prevList, ...rotulosData]);
		}

		if (rotulosData.length === 0) {
			setHasMore(false);
		}
	};

	const save = async () => {
		const idUser = loja.id ?? getUser().id;
		const data = {
			itemId: selectedItem ? selectedItem.id : null,
			historyTypeId,
			quantity,
			complement: note,
			priceUnit: parseFloat(priceUnit),
			supplierId: selectedSupplier ? selectedSupplier.id : null,
			customerId: selectedCustomer ? selectedCustomer.id : null,
			supplierName: inputValue ?? null,
			accountId: idUser,
			date,
		};
		try {
			validateBeforeSave(data);
			try {
				if (idHistory) {
					await newApi.patch(
						`/stock/stockhistory/${idUser}/${idHistory}`,
						data,
						{
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
						}
					);
				} else {
					await newApi.post(`/stock/moviment`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
					});
				}

				setAlertTitle("");
				history.push("/stock/moviment");
			} catch (error) {
				setAlertTitle("Erro");
				setMessage(error.response.data.message || "Erro");
			}
		} catch (error) {
			setAlertTitle("Dados obrigatórios");
			setMessage(error.message);
		}

		setSaveLoading(false);
	};

	const validateBeforeSave = (payload) => {
		let msg = "";
		if (!payload.date) {
			msg = "Data não informada";
		} else if (!payload.itemId) {
			msg = "Rótulo não selecionado";
		} else if (!payload.historyTypeId) {
			msg = "Histórico não selecionado";
		} else if (!payload.quantity) {
			msg = "Quantidade não informada";
		} else if (!payload.priceUnit) {
			msg = "Valor unitário não informado";
		}

		if (msg) {
			throw new Error(msg);
		}
	};

	const classes = useStylesCreate();

	const handleUsePriceAverage = (e, value, reason) => {
		setUsePriceAverage(e.target.value);
		setDisablePriceUnit(!!e.target.value);
		setPriceUnit(selectedItem.custoMedio);
	};

	const handleShowSupplier = (e, value, reason) => {
		setShowSupplier(e.target.value);
	};

	const renderUsePriceAverage = () => {
		if (
			operation !== "ENTRADA" ||
			slugHistoryType === "compra" ||
			!selectedItem ||
			idHistory
		)
			return;

		return (
			<FormControl
				variant="outlined"
				className={classes.formControlSelect}
			>
				<InputLabel id="demo-simple-select-outlined-label">
					Utilizar custo médio
				</InputLabel>
				<Select
					labelId="demo-simple-select-outlined-label"
					id="demo-simple-select-outlined"
					value={usePriceAverage}
					onChange={handleUsePriceAverage}
					label="Utilizar custo médio"
				>
					<MenuItem value={1}>Sim</MenuItem>
					<MenuItem value={0}>Não</MenuItem>
				</Select>
			</FormControl>
		);
	};

	const renderShowSupplier = () => {
		if (
			[
				"venda",
				"devolução_de_cliente",
				"saída_para_comodato",
				"cancelamento_de_pedido_de_cliente",
				"compra",
				"devolução_de_fornecedor",
			].includes(slugHistoryType)
		)
			return;

		return (
			<FormControl
				variant="outlined"
				className={classes.formControlSelect}
			>
				<InputLabel id="demo-simple-select-outlined-label">
					Fornecedor/Cliente
				</InputLabel>
				<Select
					labelId="demo-simple-select-outlined-label"
					id="demo-simple-select-outlined"
					value={showSupplier}
					onChange={handleShowSupplier}
					label="Fornecedor/Cliente"
				>
					<MenuItem value={true}>Fornecedor</MenuItem>
					<MenuItem value={false}>Cliente</MenuItem>
				</Select>
			</FormControl>
		);
	};

	const renderSupplier = () => {
		if (historyTypesCustomer.includes(slugHistoryType) || !showSupplier)
			return;
		return (
			<Grid item xs={12}>
				<Autocomplete
					fullWidth={true}
					options={supplierList}
					getOptionLabel={(supplier) => supplier.name}
					value={selectedSupplier}
					onChange={handleSelectSupplier}
					inputValue={inputValue}
					onInputChange={handleInputChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Fornecedor"
							variant="outlined"
							inputRef={inputRef}
						/>
					)}
					renderOption={(supplier) => supplier.name}
					noOptionsText="Nenhum fornecedor encontrado"
				/>
			</Grid>
		);
	};

	const renderCustomer = () => {
		if (historyTypesCustomer.includes(slugHistoryType) || !showSupplier)
			return (
				<Grid item xs={12}>
					<Autocomplete
						fullWidth={true}
						options={customerList}
						getOptionLabel={(customer) => customer.name}
						value={selectedCustomer}
						onChange={handleSelectCustomer}
						inputValue={inputValueCustomer}
						onInputChange={handleInputChangeCustomer}
						onBlur={handleBlurCustomer}
						onFocus={handleFocusCustomer}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Cliente"
								variant="outlined"
								inputRef={inputRefCustomer}
							/>
						)}
						renderOption={(customer) => customer.name}
						noOptionsText="Nenhum cliente encontrado"
					/>
				</Grid>
			);
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Typography color="textPrimary">
									Movimentação
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Grid item xs={6}>
									<form className={classes.form} noValidate>
										<Grid container spacing={2}>
											<>
												<Grid item xs={12}>
													<DatePicker
														value={date}
														onChange={setDate}
														label="Data"
														id="date"
														name="date"
														disabled={
															disableColumnsToUpdate
														}
													/>
												</Grid>
											</>
											<Grid item xs={12}>
												<Autocomplete
													disabled={
														disableColumnsToUpdate
													}
													options={items}
													getOptionLabel={(item) =>
														item.title
													}
													value={selectedItem}
													onChange={handleSelectItem}
													inputValue={inputValueItem}
													onInputChange={(
														event,
														newInputValue
													) => {
														if (newInputValue) {
															setInputValueItem(
																newInputValue
															);
															setSearchTermItem(
																newInputValue
															);
															debounceSearchRotulosInApi(
																newInputValue
															);
															return;
														}
														setInputValueItem("");
														setSearchTermItem("");
													}}
													onBlur={handleBlurItem}
													onFocus={handleFocusItem}
													ListboxProps={{
														onScroll:
															handleListScroll,
														style: {
															maxHeight: "200px",
															overflowY: "auto",
														},
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Rótulo"
															variant="outlined"
															inputRef={
																inputRefItem
															}
														/>
													)}
													renderOption={(item) =>
														item.title
													}
												/>
											</Grid>
											<FormControl
												variant="outlined"
												className={
													classes.formControlSelect
												}
											>
												<InputLabel id="demo-simple-select-outlined-label">
													Histórico
												</InputLabel>
												<Select
													disabled={
														disableColumnsToUpdate
													}
													required
													labelId="demo-simple-select-outlined-label"
													id="demo-simple-select-outlined"
													value={historic}
													onChange={
														handleHistoryTypeId
													}
													label="Histórico"
												>
													{!historyTypes.length ? (
														<MenuItem
															value={historic}
														>
															{historic}
														</MenuItem>
													) : (
														historyTypes.map(
															(data, k) => (
																<MenuItem
																	data-id={
																		data.id
																	}
																	data-operation={
																		data.operation
																	}
																	data-slug={
																		data.slug
																	}
																	value={
																		data.name
																	}
																	key={
																		data.id
																	}
																>
																	{data.name}
																</MenuItem>
															)
														)
													)}
												</Select>
											</FormControl>
											<Grid item xs={4}>
												<TextField
													variant="outlined"
													disabled
													fullWidth
													id="quantity"
													label="Nat. Operação"
													name="operation"
													autoComplete="operation"
													value={operation}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													disabled={
														disableColumnsToUpdate
													}
													variant="outlined"
													fullWidth
													id="quantity"
													label="Quantidade"
													name="quantity"
													autoComplete="quantity"
													value={quantity}
													onChange={(e) => {
														setQuantity(
															e.target.value
														);
													}}
												/>
											</Grid>
											{renderUsePriceAverage()}
											<Grid item xs={6}>
												<TextField
													disabled={
														disableColumnsToUpdate ||
														disablePriceUnit
													}
													variant="outlined"
													fullWidth
													id="priceUnit"
													label="Preço Unitário"
													name="priceUnit"
													autoComplete="priceUnit"
													value={priceUnit}
													onChange={(e) => {
														setPriceUnit(
															e.target.value
														);
													}}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													variant="outlined"
													fullWidth
													disabled
													id="priceTotal"
													label="Preço Total"
													name="priceTotal"
													autoComplete="priceTotal"
													value={priceTotal}
												/>
											</Grid>
											{renderShowSupplier()}
											{renderSupplier()}
											{renderCustomer()}
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													fullWidth
													id="note"
													label="Complemento"
													name="note"
													autoComplete="note"
													value={note}
													onChange={(e) =>
														setNote(e.target.value)
													}
												/>
											</Grid>
											<Grid item xs={10}>
												<Button
													onClick={() => {
														history.goBack();
													}}
													type="button"
													variant="contained"
													color="default"
													style={{
														marginRight: 30,
														marginTop: 30,
													}}
												>
													Voltar
												</Button>
												<Button
													disabled={saveLoading}
													type="button"
													variant="contained"
													color="primary"
													onClick={save}
													style={{
														marginTop: 30,
													}}
												>
													{saveLoading
														? "Salvando..."
														: "Salvar"}
												</Button>
											</Grid>
										</Grid>
									</form>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<AlertDialog
						message={message}
						onClose={() => setMessage(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
					/>
				</Container>
			</main>
		</div>
	);
}
