import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import moment from 'moment';
import React, { useCallback, useEffect } from "react";
import MaterialTableComponent from "../../compoments/MaterialTableComponent";
import api from "../../services/axios";
import Base from "../Base";
import ConfirmDialog from './../../compoments/ConfirmDialog';
import { useStyles } from "./styles";

export default function Coupons({ history }) {
  const [actionsTable] = React.useState(loadActionsTable());
  const [coupons, setCoupons] = React.useState([]);
  const [messageDelete, setMessageDelete] = React.useState("");
  const [idSelected, setIdSelected] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [showDialogDelete,  setShowDialogDelete] = React.useState(false);
  const classes = useStyles();
  function loadActionsTable  ()  {
    let actionsDt = [];
    actionsDt.push({
      icon: "edit",
      tooltip: "Editar",
      onClick: (_, rowData) => {
        history.push(`/coupons/update/${rowData.id}`);
      },
    });
    actionsDt.push({
      icon: "add",
      tooltip: "Criar cupom",
      isFreeAction: true,
      onClick: () => history.push(`/coupons/create`),
    });
    actionsDt.push({
      icon: 'delete',
      tooltip: 'Deletar',
      onClick: (_, rowData) => {
        setShowDialogDelete(true);
        setIdSelected(rowData.id);
        setMessageDelete('Deseja excluir?')
      },
      disabled: false
    });
    actionsDt.push({
        icon: ShoppingCart,
        tooltip: "Pedidos",
        onClick: (event, rowData) => {
            history.push(`/pedidos/cupons/${rowData.id}`);
        },
    });
   
    return actionsDt;
  };

  useEffect(() => {
    loadCoupons()
  }, [])
  

  const loadCoupons = async () => {
    const data = await api.get("/v6/coupon");
    if(!data.data.data.data){
      setCoupons([])
    }else {
      setCoupons(data.data.data.data);
    }
  };

  const onHandleDeleteCoupon = useCallback(async () => {
		const response = await api.delete(`/v6/coupon/${idSelected}`);
    if(response.status === 200){
      loadCoupons();
    }
	}, [idSelected]);

  const renderContent = useCallback(() => {
    return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Typography color="textPrimary">
									Cupons
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid item xs={12}>
							<MaterialTableComponent
								columns={[
									{ title: "Código", field: "code" },
									{
										title: "Tipo de Desconto",
										render: (rowData) =>
											rowData.discount_type ===
											"PERCENTAGE"
												? "Porcentagem"
												: "Valor",
									},
									{
										title: "Valor do desconto",
										field: "discount_value",
									},
									{
										title: "Data Inicial",
										render: (rowData) =>
											moment(rowData.initial_date).format(
												"DD/MM/YY"
											),
									},
									{
										title: "Data de Expiração",
										render: (rowData) =>
											rowData.expiration_date &&
											moment(
												rowData.expiration_date
											).format("DD/MM/YY"),
									},
								]}
								data={coupons}
								title=""
								actions={actionsTable}
								options={{
									actionsColumnIndex: -1,
								}}
							/>
						</Grid>
					</Grid>
					<ConfirmDialog
						message={messageDelete}
						onClose={() => setShowDialogDelete(false)}
						title={"Atenção"}
						buttonSuccessText={"EXCLUIR"}
						onConfirm={() => {
							setShowDialogDelete(false);
							setMessageDelete(null);
							onHandleDeleteCoupon();
						}}
					/>
				</Container>
			</main>
		</div>
	);
  }, [actionsTable, classes.appBarSpacer, classes.container, classes.content, classes.root, coupons, messageDelete, onHandleDeleteCoupon, setShowDialogDelete])

  return renderContent();
}
