import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function CustomDialog({
	open,
	title = "",
	onClickLeft,
	onClickRight,
	textLeft = "",
	textRight = "",
	onClose,
}) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogActions>
					<Button onClick={onClickLeft} color="primary" autoFocus>
						{textLeft}
					</Button>
					<Button onClick={onClickRight} color="primary" autoFocus>
						{textRight}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
