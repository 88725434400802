import "date-fns";
import MaterialTable from "material-table";
import React from "react";

export default function MaterialTableComponent(props) {
  return (
    <MaterialTable
      localization={{
        toolbar: {
          exportCSVName: "Exportar para CSV",
          exportPDFName: "Imprimir",
          searchPlaceholder: "Busca",
          searchAriaLabel: "Busca",
          clearSearchAriaLabel: "Limpar Busca",
          searchTooltip: "Busca",
        },
        addRemoveColumns: "Add or remove columns",
        nRowsSelected: "{0} row(s) selected",
        showColumnsTitle: "Show Columns",
        showColumnsAriaLabel: "Show Columns",
        exportTitle: "Exportar",
        exportAriaLabel: "Exportar",
        body: {
          emptyDataSourceMessage: "Não há registros a serem exibidos",
          filterRow: {
            filterTooltip: "Filtro",
          },
        },
        header: {
          actions: "Ações",
        },
        pagination: {
          labelRowsSelect: "Registros",
          previousAriaLabel: "Página anterior",
          previousTooltip: "Página anterior",
          nextAriaLabel: "Próxima página",
          nextTooltip: "Próxima página",
          lastAriaLabel: "Última página",
          lastTooltip: "Última página",
          firstAriaLabel: "Primeira página",
          firstTooltip: "Primeira página",
        },
      }}
      {...props}
    />
  );
}
