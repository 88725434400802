export const paises = [
    {
		id: 0,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "selecione",
		country_name: "Selecione o País",
	},
	{
		id: 12,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "africadosul",
		country_name: "África do Sul",
	},
	{
		id: 6,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "argentina",
		country_name: "Argentina",
	},
	{
		id: 11,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "australia",
		country_name: "Austrália",
	},
	{
		id: 18,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "austria",
		country_name: "Áustria",
	},
	{
		id: 1,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "brasil",
		country_name: "Brasil",
	},
	{
		id: 22,
		timestamp: "2022-03-31 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "bulgaria",
		country_name: "Bulgária",
	},
	{
		id: 34,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "canada",
		country_name: "Canadá",
	},
	{
		id: 16,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "chile",
		country_name: "Chile",
	},
	{
		id: 28,
		timestamp: "2022-12-21 18:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "croacia",
		country_name: "Croácia",
	},
	{
		id: 14,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "eslovaquia",
		country_name: "Eslováquia",
	},
	{
		id: 30,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "eslovenia",
		country_name: "Eslovênia",
	},
	{
		id: 9,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "espanha",
		country_name: "Espanha",
	},
	{
		id: 7,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "estadosunidos",
		country_name: "Estados Unidos",
	},
	{
		id: 15,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "franca",
		country_name: "França",
	},
	{
		id: 13,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "grecia",
		country_name: "Grécia",
	},
	{
		id: 20,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "hungria",
		country_name: "Hungria",
	},
	{
		id: 8,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "italia",
		country_name: "Itália",
	},
	{
		id: 23,
		timestamp: "2022-12-21 18:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "kosovo",
		country_name: "Kosovo",
	},
	{
		id: 24,
		timestamp: "2022-12-21 18:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "libano",
		country_name: "Líbano",
	},
	{
		id: 31,
		timestamp: "2023-01-01 00:00:00",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "macedonia",
		country_name: "Macedônia",
	},
	{
		id: 26,
		timestamp: "2022-12-21 18:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "moldavia",
		country_name: "Moldávia",
	},
	{
		id: 32,
		timestamp: "2023-01-01 00:00:00",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "novazelandia",
		country_name: "Nova Zelândia",
	},
	{
		id: 21,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "marrocos",
		country_name: "Marrocos",
	},
	{
		id: 29,
		timestamp: "2022-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "romenia",
		country_name: "Romênia",
	},
	{
		id: 25,
		timestamp: "2022-12-21 18:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "servia",
		country_name: "Sérvia",
	},
	{
		id: 27,
		timestamp: "2022-12-21 18:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "siria",
		country_name: "Síria",
	},
	{
		id: 19,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "suica",
		country_name: "Suíça",
	},
	{
		id: 33,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "turquia",
		country_name: "Turquia",
	},
	{
		id: 4,
		timestamp: "2020-07-14 17:38:52",
		category_id: 1,
		category_name: "pais_da_vinicola",
		country_id: "uruguai",
		country_name: "Uruguai",
	},
];
