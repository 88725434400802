/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef } from "react";
import MaterialTableComponent from "./../../../compoments/MaterialTableComponent";
import Base from "./../../Base";
import { useStylesIndex } from "./styles";
import jwtDecode from "../../../services/jwt";
import newApi from "../../../services/axios/new-api";
import Loading from "../../../compoments/Loading";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { formatNumberToBRL } from "../../../compoments/Util";
import { featuresAvailable } from "../../../services/permission";
import Unavailable from "../unavailable";

export default function Index({ history }) {
  const roleName = "Estoque";

  const classes = useStylesIndex();
  const [wineType, setWineType] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [grape, setGrape] = React.useState("");
  const [wineTypeId, setWineTypeId] = React.useState(null);
  const [aboveMinimumStock, setAboveMinimumStock] = React.useState(null);
  const [typeOrder, setTypeOrder] = React.useState(null);
  const [countryId, setCountryId] = React.useState(null);
  const [grapeId, setGrapeId] = React.useState(null);
  const [loja, setLoja] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [historyWinetypes, setHistoryWinetypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [grapes, setGrapes] = React.useState([]);
  const [quantity, setQuantity] = React.useState("");
  const [priceTotal, setPriceTotal] = React.useState("");
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [stockAvailable, setStockAvailable] = React.useState(null);
  const itemPerPage = 25;
  const tableRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    setLoja(getUser());
    loadWinetypes();
    loadCountries();
    loadGrapes();
    handleLoadData();
    loadPermissionPage();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setPage(1);
    handleLoadData();
  }, [wineTypeId, countryId, aboveMinimumStock, grapeId, typeOrder]);

  useEffect(() => {
    handleLoadData();
  }, [page]);

  const loadPermissionPage = async () => {
    const { stockAvailable } = await featuresAvailable(loja.id ?? getUser().id);
    setStockAvailable(stockAvailable);
  };

  const loadWinetypes = async () => {
    const { data } = await newApi.get(
      `/catalog/winetypes?idAccount=loja-inteli`
    );
    setHistoryWinetypes(data);
  };

  const loadCountries = async () => {
    const { data } = await newApi.get(
      `/catalog/countries?idAccount=loja-inteli`
    );
    setCountries(data);
  };

  const loadGrapes = async () => {
    const { data } = await newApi.get(`/catalog/grapes?idAccount=loja-inteli`);
    setGrapes(data);
  };

  const getUser = () => {
    const data = jwtDecode(localStorage.getItem("token"));
    return data.user;
  };

  const handleChangeWineType = (e, value, reason) => {
    const id = value.props["data-id"];
    setWineTypeId(id);
    setWineType(e.target.value);
  };

  const handleChangeCountry = (e, value, reason) => {
    const id = value.props["data-id"];
    setCountryId(id);
    setCountry(e.target.value);
  };

  const handleChangeGrape = (e, value, reason) => {
    const id = value.props["data-id"];
    setGrapeId(id);
    setGrape(e.target.value);
  };

  const handleChangeAboveMinimumStock = (e, value, reason) => {
    setAboveMinimumStock(e.target.value);
  };

  const handleChangeTypeOrder = (e, value, reason) => {
    setTypeOrder(e.target.value);
  };

  const handleLoadData = async () => {
    try {
      const idUser = loja.id ?? getUser().id;
      const { data } = await newApi.get(
        `/stock/inventory/${idUser}?limit=${itemPerPage}&offset=${page}`,
        {
          params: {
            wineTypeId,
            countryId,
            aboveMinimumStock,
            grapeId,
            typeOrder,
          },
        }
      );

      setQuantity(data.data.totals?.quantity);
      setPriceTotal(formatNumberToBRL(data.data.totals?.total));
      setTotalCount(data.total);

      const moviments = data.data.items.map((item) => ({
        ...item,
        priceUnit: formatNumberToBRL(item.priceUnit),
        priceTotal: formatNumberToBRL(item.priceTotal),
      }));
      setTableData(moviments);
    } catch (error) {
      return;
    }
  };

  const renderFilters = () => (
    <>
      <Grid item xs={3}>
        <InputLabel>Filtrar por Tipo de Vinho:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={wineType}
          name="wineType"
          onChange={handleChangeWineType}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value="">Todos</MenuItem>
          {historyWinetypes.map((data, k) => (
            <MenuItem value={data.name} key={data.id} data-id={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <InputLabel>Filtrar por Uva:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={grape}
          name="grape"
          onChange={handleChangeGrape}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value="">Todas</MenuItem>
          {grapes.map((data, k) => (
            <MenuItem value={data.name} key={data.id} data-id={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <InputLabel>Filtrar por País:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={country}
          name="country"
          onChange={handleChangeCountry}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value="">Todos</MenuItem>
          {countries.map((data, k) => (
            <MenuItem value={data.name} key={data.id} data-id={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <InputLabel>Estoque abaíxo do mínimo:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={aboveMinimumStock}
          name="aboveMinimumStock"
          onChange={handleChangeAboveMinimumStock}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value={null}>Ambos</MenuItem>
          <MenuItem value={0}>Sim</MenuItem>
          <MenuItem value={1}>Nao</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={3}>
        <InputLabel>Ordenação:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={typeOrder}
          name="typeOrder"
          onChange={handleChangeTypeOrder}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value={null}>Padrão</MenuItem>
          <MenuItem value="NAME">Alfabética</MenuItem>
          <MenuItem value="QUANTITY_ASC">Estoque Atual Crescente</MenuItem>
          <MenuItem value="QUANTITY_DESC">Estoque Atual Decrescente</MenuItem>
        </Select>
      </Grid>
    </>
  );

  const renderInfoAboutlStock = () => (
    <Grid item xs={6}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2>Resumo</h2>
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              id="quantity"
              label="Quantidade de Rótulos"
              name="quantity"
              autoComplete="quantity"
              value={quantity}
              InputProps={{
                style: { fontWeight: "bold", color: "#000" },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              disabled
              id="priceTotal"
              label="Total"
              name="priceTotal"
              autoComplete="priceTotal"
              value={priceTotal}
              InputProps={{
                style: { fontWeight: "bold", color: "#000" },
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Base />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {!stockAvailable && stockAvailable !== null ? (
          Unavailable()
        ) : (
          <Container maxWidth="lg" className={classes.container}>
            {isLoading || stockAvailable === null ? (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">{roleName}</Typography>
                  </Breadcrumbs>
                </Grid>

                {renderFilters()}
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    {renderInfoAboutlStock()}
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <MaterialTableComponent
                    tableRef={tableRef}
                    columns={[
                      {
                        title: "Rótulo",
                        field: "itemName",
                        width: "30%",
                      },
                      { title: "Cod Ref", field: "codeRef" },
                      {
                        title: "Quant",
                        field: "quantity",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Est Mínimo",
                        field: "stockMin",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Diferença",
                        field: "difference",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Valor Unit R$",
                        field: "priceUnit",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Valor Total R$",
                        field: "priceTotal",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                    ]}
                    data={tableData}
                    title="Inventário"
                    options={{
                      search: false,
                      sorting: false,
                      actionsColumnIndex: -1,
                      pageSize: itemPerPage,
                      pageSizeOptions: [],
                    }}
                    page={page > 0 ? page - 1 : 0}
                    onChangePage={(newPage) => {
                      setPage(newPage + 1);
                    }}
                    totalCount={totalCount}
                  />
                </Grid>
              </Grid>
            )}
          </Container>
        )}
      </main>
    </div>
  );
}
