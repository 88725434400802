/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import DatePicker from "../../../compoments/DatePicker";
import api from "../../../services/axios";
import newApi from "../../../services/axios/new-api";
import jwtDecode from "../../../services/jwt";
import MaterialTableComponent from "./../../../compoments/MaterialTableComponent";
import Base from "./../../Base";
import { useStylesIndex } from "./styles";
import Paper from "@material-ui/core/Paper";
import { formatNumberToBRL } from "../../../compoments/Util";
import Unavailable from "../unavailable";
import { featuresAvailable } from "../../../services/permission";
import Loading from "../../../compoments/Loading";

export default function Index({ history }) {
  const roleName = "Estoque";

  const classes = useStylesIndex();
  const tableRef = useRef();
  const [initialDate, setInitialDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [loja, setLoja] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [itemId, setItemId] = React.useState("");
  const inputRefItem = useRef(null);
  const [page, setPage] = React.useState(1);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [searchTermItem, setSearchTermItem] = React.useState("");
  const [inputValueItem, setInputValueItem] = React.useState("");
  const [items, setItems] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [quantity, setQuantity] = React.useState("");
  const [priceUnit, setPriceUnit] = React.useState("");
  const [priceTotal, setPriceTotal] = React.useState(0);
  const [stockMin, setStockMin] = React.useState("");
  const [differenceStock, setDifferenceStock] = React.useState("");
  const [stockAvailable, setStockAvailable] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setIsLoading(true);
    setLoja(getUser());
    loadItems();
    loadPermissionPage();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (itemId) {
      handleLoadData();
    }
  }, [initialDate, endDate, itemId]);

  useEffect(() => {
    loadItems();
  }, [page]);

  useEffect(() => {
    setQuantity("");
    setPriceTotal("");
    setPriceUnit("");
    if (itemId) {
      loadInfoAboutStock();
    }
  }, [itemId]);

  const getUser = () => {
    const data = jwtDecode(localStorage.getItem("token"));
    return data.user;
  };

  const handleSelectItem = (event, item) => {
    setItemId(item?.id ? item.id : "");
    setSelectedItem(item ?? null);
    setSearchTermItem(item ? item.title : "");
  };

  const handleBlurItem = () => {
    if (!selectedItem) {
      setInputValueItem(inputValueItem || searchTermItem);
    }
  };

  const handleFocusItem = () => {
    setInputValueItem(inputValueItem || searchTermItem);
  };

  const handleListScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const loadPermissionPage = async () => {
    const { stockAvailable } = await featuresAvailable(loja.id ?? getUser().id);
    setStockAvailable(stockAvailable);
  };

  const debounceSearchRotulosInApi = useDebouncedCallback(() => {
    loadItems();
  }, 800);

  const loadItems = async () => {
    const idUser = loja.id ?? getUser().id;
    const search = inputValueItem ?? searchTermItem ?? "";
    setHasMore(true);
    const dataRotulos = await api.get(
      `v5/indicacoes?perPage=20&userId=${idUser}&page=${page}&search=${search}&incluirRotuloSemEstoque=true`
    );
    let rotulosData = dataRotulos.data.data.map((i, k) => {
      return {
        title: i.nome,
        id: i.id,
        nome: i.nome,
        estoque: i.estoque,
        status: i.status.id,
        columnToFilter: `${i.nome}, ${i.meta_pais_descricao}, ${i.safra}, ${i.vinicola}, ${i.meta_regiao_descricao}, ${i.meta_tipo_vinho_descricao}`,
      };
    });

    if (page === 1) {
      setItems(rotulosData);
    } else {
      setItems((prevList) => [...prevList, ...rotulosData]);
    }

    if (rotulosData.length === 0) {
      setHasMore(false);
    }
  };

  const loadInfoAboutStock = async () => {
    const { data } = await newApi.get(`/item/external/${itemId}`);
    setQuantity(data.stockItem.quantity);
    setPriceTotal(formatNumberToBRL(data.stockItem.total));
    setPriceUnit(formatNumberToBRL(data.stockItem.priceAverage));
    setStockMin(data.stockItem.minQuantity);
    setDifferenceStock(data.stockItem.diffenceStock);
  };

  const handleLoadData = async () => {
    try {
      const idUser = loja.id ?? getUser().id;
      const { data } = await newApi.get(
        `/stock/stockhistory/${idUser}?limit=50`,
        {
          params: {
            initialDate: initialDate
              ? moment(initialDate).format("YYYY-MM-DD")
              : "",
            endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
            itemId,
          },
        }
      );
      const options = {
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      };
      const formatNumber = new Intl.NumberFormat("pt-BR", options);
      const moviments = data.map((item) => ({
        ...item,
        date: moment(item.date).format("DD/MM/YYYY"),
        priceUnit: formatNumber.format(item.priceUnit),
        priceTotal: formatNumber.format(item.priceTotal),
      }));

      setTableData(moviments);
    } catch (error) {
      return;
    }
  };

  const filters = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "12px",
      }}
    >
      <div style={{ marginRight: "100px" }}>
        <DatePicker
          value={initialDate}
          onChange={setInitialDate}
          label="Data Inicial"
          id="initialDate"
          name="initialDate"
        />
      </div>
      <div style={{ marginRight: "100px" }}>
        <DatePicker
          value={endDate}
          onChange={setEndDate}
          label="Data Final"
          id="endDate"
          name="endDate"
        />
      </div>
      <div style={{ width: "400px" }}>
        <Autocomplete
          options={items}
          getOptionLabel={(item) => item.title}
          value={selectedItem}
          onChange={handleSelectItem}
          inputValue={inputValueItem}
          onInputChange={(event, newInputValue) => {
            if (newInputValue) {
              setInputValueItem(newInputValue);
              setSearchTermItem(newInputValue);
              debounceSearchRotulosInApi(newInputValue);
              return;
            }
            setInputValueItem("");
            setSearchTermItem("");
          }}
          onBlur={handleBlurItem}
          onFocus={handleFocusItem}
          ListboxProps={{
            onScroll: handleListScroll,
            style: {
              maxHeight: "200px",
              overflowY: "auto",
            },
          }}
          renderInput={(params) => {
            params.InputProps.endAdornment = null;
            return (
              <TextField
                {...params}
                label="Rótulo"
                variant="outlined"
                inputRef={inputRefItem}
              />
            );
          }}
          renderOption={(item) => item.title}
        />
      </div>
    </div>
  );

  const renderActualStock = () => (
    <Grid item xs={12}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2>Estoque Atual</h2>
          </Grid>

          <Grid item xs={2}>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              id="quantity"
              label="Quantidade"
              name="quantity"
              autoComplete="quantity"
              value={quantity}
              InputProps={{
                style: { fontWeight: "bold", color: "#000" },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              fullWidth
              disabled
              id="stockMin"
              label="Estoque Mínimo"
              name="stockMin"
              autoComplete="stockMin"
              value={stockMin}
              InputProps={{
                style: { fontWeight: "bold", color: "#000" },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              fullWidth
              disabled
              id="differenceStock"
              label="Diferença"
              name="differenceStock"
              autoComplete="differenceStock"
              value={differenceStock}
              InputProps={{
                style: { fontWeight: "bold", color: "#000" },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              id="priceUnit"
              label="Preço Unitário"
              name="priceUnit"
              autoComplete="priceUnit"
              value={priceUnit}
              InputProps={{
                style: { fontWeight: "bold", color: "#000" },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              fullWidth
              disabled
              id="priceTotal"
              label="Preço Total"
              name="priceTotal"
              autoComplete="priceTotal"
              value={priceTotal}
              InputProps={{
                style: { fontWeight: "bold", color: "#000" },
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Base />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {!stockAvailable && stockAvailable !== null ? (
          Unavailable()
        ) : (
          <Container maxWidth="lg" className={classes.container}>
            {isLoading || stockAvailable === null ? (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">{roleName}</Typography>
                  </Breadcrumbs>
                </Grid>

                {filters()}
                <Grid item xs={12}>
                  <Paper className={classes.paper}>{renderActualStock()}</Paper>
                </Grid>
                <Grid item xs={12}>
                  <MaterialTableComponent
                    tableRef={tableRef}
                    columns={[
                      { title: "Data", field: "date" },
                      {
                        title: "Histórico",
                        field: "historyTypeName",
                      },
                      {
                        title: "Fornecedor/Cliente",
                        field: "personVinculatedName",
                      },
                      {
                        title: "Complemento",
                        field: "complement",
                      },
                      {
                        title: "Quant",
                        field: "quantity",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Valor Unit R$",
                        field: "priceUnit",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Valor Total R$",
                        field: "priceTotal",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                    ]}
                    data={tableData}
                    title="Ficha de Estoque"
                    options={{
                      search: false,
                      sorting: false,
                      actionsColumnIndex: -1,
                      pageSize: 10,
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Container>
        )}
      </main>
    </div>
  );
}
