/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import MaterialTableComponent from "../../compoments/MaterialTableComponent";
import AlertDialog from "./../../compoments/AlertDialog";
import ConfirmDialog from "./../../compoments/ConfirmDialog";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import { dateFormat } from "./../../services/date/date";
import Base from "./../Base";
import jwtDecode from "../../services/jwt/index";
import { getBaseUrl } from "../../env";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  input: {
    display: "none",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: "100%",
  },
}));

export default function Update({ match, history }) {
  const roleName = "Clientes";
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [actionsTableAddress, setActionsTableAddress] = React.useState([]);
  const [actionsTableOrder, setActionsTableOrder] = React.useState([]);
  const [messageDelete, setMessageDelete] = React.useState(false);
  const [originRegistration, setOriginRegistration] = React.useState(0);
  const [name, setName] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [mobilePhone, setMobilePhone] = React.useState("");
  const [cpfCnpj, setCpfCnpj] = React.useState("");
  const [note, setNote] = React.useState("");
  const [createdAt, setCreatedAt] = React.useState("");
  const [status, setStatus] = React.useState(true);
  const [dataAddresses, setDataAddresses] = React.useState([]);
  const [labelTypePerson, setLabelTypePerson] = React.useState("");
  const [typeClient, setTypeClient] = React.useState(0);
  const [idSelectedDelete, setIdSelectedDelete] = React.useState(0);
  const [marketId, setMarketId] = React.useState(0);
  const tableRef = useRef();

  const typePerson = [
    {
      id: 0,
      label: "",
    },
    {
      id: 1,
      label: "Física",
    },
    {
      id: 2,
      label: "Juridica",
    },
  ];

  const [id, setId] = React.useState([]);

  useEffect(() => {
    loadActionsTableAddress();
    loadActionsTableOrder();
    loadData();
    const tokenData = jwtDecode(localStorage.getItem("token"));
    setMarketId(tokenData.user.id);
  }, []);

  const loadActionsTableAddress = async () => {
    let actionsAdress = [];

    actionsAdress.push({
      icon: "edit",
      tooltip: "Editar",
      onClick: (event, rowData) => {
        history.push(
          `/clientes/update/${match.params.id}/endereco/update/${rowData.id}`
        );
      },
    });

    actionsAdress.push({
      icon: "delete",
      tooltip: "Deletar",
      onClick: (event, rowData) => {
        setIdSelectedDelete(rowData.id);
        setMessageDelete("Deseja excluir?");
      },
      disabled: false,
    });

    actionsAdress.push({
      icon: "add",
      tooltip: "Novo endereço",
      isFreeAction: true,
      onClick: (event, rowData) => {
        history.push(`/clientes/update/${match.params.id}/endereco/create`);
      },
    });

    setActionsTableAddress(actionsAdress);
  };

  const loadActionsTableOrder = async () => {
    const actionsOrder = [];
    actionsOrder.push({
      icon: VisibilityIcon,
      tooltip: "View",
      onClick: (event, rowData) => {
        history.push(`/pedidos/${rowData.id}`);
      },
    });

    setActionsTableOrder(actionsOrder);
  };

  const deleteAddressAction = async (id) => {
    const data = await api.delete("/v6/lead/address/" + id);
    if (!data.data.status) {
      setAlertTitle("Erro");
      setMessage("Não foi possível deletar");
    }

    setAlertTitle("");
    setMessage("Deletado com sucesso");
    const newAddresses = dataAddresses.filter((address) => {
      return address.id != id;
    });
    setDataAddresses(newAddresses);
    tableRef.current.onQueryChange();
  };

  const loadData = async () => {
    setId(match.params.id);
    const data = await api.get(`v6/lead/` + match.params.id);
    setOriginRegistration(data.data.origin_registration);
    setName(data.data.name);
    setMail(data.data.mail);
    setMobilePhone(data.data.mobile_phone);
    setCpfCnpj(data.data.cpf_cnpj);
    setNote(data.data.note);
    const dateCreated = dateFormat(new Date(data.data.created_at), true);
    setCreatedAt(dateCreated);
    setTypeClient(data.data.type);
    setStatus(data.data.is_active);
    setDataAddresses(data.data.lead_addresses);
    setLoading(false);
    setLabelTypePerson(parseInt(data.data.type) === 1 ? "CPF" : "CNPJ");
  };

  const loadOrder = async (query) => {
    const { baseURL } = getBaseUrl();
    const response = await api.request({
      baseURL,
      url: `/api-node/account/externalId/${marketId}/customer/${
        match.params.id
      }/orders?offset${query.page + 1}&limit${query.pageSize}`,
    });

    let orders = response.data.items.map((order) => {
      console.log({ order });
      return {
        ...order,
        status: getLabelStatus(order.status),
        quantity: order.items.length,
        total: parseFloat(order.total),
        createdAt: dateFormat(new Date(order.createdAt), true),
      };
    });

    let obj = {
      data: orders,
      page: response.data.current_page ? response.data.current_page - 1 : 0,
      totalCount: response.data.total,
    };

    return obj;
  };

  function getLabelStatus(status) {
    switch (status) {
      case "PENDING":
        return "Pendente";
      case "AWAIT_FREIGHT_CALC":
        return "Aguardando cálculo de frete";
      case "CANCELED":
        return "Cancelado";
      default:
        return "Finalizado";
    }
  }

  const save = async () => {
    setSaveLoading(true);
    const data = new FormData();
    data.append("name", name);
    data.append("mail", mail);
    data.append("mobile_phone", mobilePhone);
    data.append("cpf_cnpj", cpfCnpj);
    data.append("note", note);
    data.append("type", typeClient);
    data.append("is_active", !!status ? 1 : 0);
    data.append("_method", "PUT");

    try {
      await api.post(`/v6/lead/${id}`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push("/clientes");
      setSaveLoading(false);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
      setSaveLoading(false);
    }
  };

  const getTypeOriginRegistration = (type) => {
    let result = "";
    switch (type) {
      case 2:
      case 3:
        result = "Pedido";
        break;
      case 4:
        result = "Campanha de Lead";
        break;
      default:
        result = "Cadastro avulso";
        break;
    }
    return result;
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Base />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={`/clientes`}>
                  {roleName}
                </Link>
                <Typography color="textPrimary">Atualizar</Typography>
              </Breadcrumbs>
            </Grid>
            {loading ? (
              <Loading />
            ) : (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid item xs={10}>
                    <form className={classes.form} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="name"
                            label="Origem Cadastro"
                            name="origin_registration"
                            autoComplete="origin_registration"
                            value={getTypeOriginRegistration(
                              originRegistration
                            )}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            label="Nome"
                            name="name"
                            autoComplete="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="mail"
                            label="E-mail"
                            name="mail"
                            autoComplete="mail"
                            value={mail}
                            onChange={(e) => setMail(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="mobile_phone"
                            label="Celular"
                            name="mobile_phone"
                            autoComplete="mobile_phone"
                            value={mobilePhone}
                            onChange={(e) => setMobilePhone(e.target.value)}
                          />
                        </Grid>
                        <>
                          <Grid item xs={8}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">
                                Tipo Pessoa
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={typePerson[0]}
                                value={typeClient}
                                onChange={(e) => {
                                  setTypeClient(e.target.value);

                                  if (e.target.value === 1) {
                                    setLabelTypePerson("CPF");
                                  } else if (e.target.value === 2) {
                                    setLabelTypePerson("CNPJ");
                                  }
                                }}
                              >
                                {typePerson.map((data, k) => (
                                  <MenuItem value={data.id} key={data.id}>
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="cpf_cnpj"
                              label={labelTypePerson}
                              name="cpf_cnpj"
                              autoComplete="cpf_cnpj"
                              value={cpfCnpj}
                              onChange={(e) => setCpfCnpj(e.target.value)}
                            />
                          </Grid>
                        </>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="note"
                            label="Observações gerais"
                            name="note"
                            autoComplete="note"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="created_at"
                            label="Data de criação"
                            name="created_at"
                            autoComplete="created_at"
                            value={createdAt}
                            onChange={(e) => setCreatedAt(e.target.value)}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MaterialTableComponent
                            tableRef={tableRef}
                            options={{
                              search: false,
                              paging: false,
                              actionsColumnIndex: -1,
                            }}
                            columns={[
                              {
                                title: "Nome do endereço",
                                field: "address_name",
                              },
                              {
                                title: "Endereço",
                                field: "address_street",
                              },
                              {
                                title: "Cidade",
                                field: "address_city",
                              },
                              {
                                title: "Bairro",
                                field: "address_district",
                              },
                              {
                                title: "Estado",
                                field: "address_state",
                              },
                              {
                                title: "CEP",
                                field: "address_zip_code",
                              },
                            ]}
                            data={(query) =>
                              new Promise((resolve, reject) => {
                                let obj = {
                                  data: dataAddresses,
                                  page: 0,
                                  totalCount: dataAddresses.length,
                                };
                                resolve(obj);
                              })
                            }
                            actions={actionsTableAddress}
                            title="Endereços"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MaterialTableComponent
                            options={{
                              search: false,
                              sorting: false,
                              actionsColumnIndex: -1,
                            }}
                            columns={[
                              {
                                title: "Data do pedido",
                                field: "createdAt",
                              },
                              {
                                title: "Pedido",
                                field: "code",
                              },
                              {
                                title: "Status",
                                field: "status",
                              },
                              {
                                title: "Itens",
                                field: "quantity",
                              },
                              {
                                title: "Total R$",
                                field: "total",
                              },
                            ]}
                            data={(query) => {
                              return loadOrder(query);
                            }}
                            actions={actionsTableOrder}
                            title="Pedidos"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!status}
                                onChange={(e) => {
                                  setStatus(
                                    e.target.checked === true ? true : false
                                  );
                                }}
                                name="is_active"
                                color="primary"
                              />
                            }
                            label="Ativo"
                          />
                        </Grid>

                        <Grid item xs={10}>
                          <Button
                            onClick={() => {
                              history.goBack();
                            }}
                            type="button"
                            variant="contained"
                            color="default"
                            style={{
                              marginRight: 30,
                              marginTop: 30,
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={saveLoading}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={save}
                            style={{
                              marginTop: 30,
                            }}
                          >
                            {saveLoading ? "Salvando..." : "Salvar"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
          <ConfirmDialog
            message={messageDelete}
            onClose={() => setMessageDelete(false)}
            title={"Atenção"}
            buttonSuccessText={"EXCLUIR"}
            onConfirm={async () => {
              await deleteAddressAction(idSelectedDelete);
              setMessageDelete(false);
            }}
          />
        </Container>
      </main>
    </div>
  );
}
