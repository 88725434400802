import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import DatePicker from "./../../compoments/DatePicker";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  input: {
    display: "none",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: "100%",
  },
}));

export default function Create({ match, history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("updatesorteiosgestor")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const roleName = "Sorteios";

  const [redirect] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [thumbnail] = React.useState(null);
  const [name, setName] = React.useState("");
  const [info, setInfo] = React.useState("");

  const [date_start, setDate_start] = React.useState(null);
  const [date_end, setDate_end] = React.useState(null);
  const [status, setStatus] = React.useState(true);

  const [id, setId] = React.useState([]);

  const loadData = useCallback(async () => {
    setId(match.params.id);
    const data = await api.get(`/gestor/v1/sorteios/` + match.params.id);
    setName(data.data.nome);
    setInfo(data.data.descricao);
    setDate_start(data.data.data_inicial + " 00:00:00");
    setDate_end(data.data.data_final + " 00:00:00");
    setStatus(data.data.ativo);

    setLoading(false);
  }, [match]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const save = async () => {
    setSaveLoading(true);
    const data = new FormData();

    if (thumbnail && thumbnail.name) {
      data.append("photos[]", thumbnail, thumbnail.name);
    }

    data.append("nome", name);
    data.append("descricao", info);
    data.append("data_inicial", moment(date_start).format("YYYY-MM-DD"));
    data.append("data_final", moment(date_end).format("YYYY-MM-DD"));
    data.append("ativo", !!status === true ? 1 : 0);
    data.append("_method", "PUT");

    try {
      await api.post(`/gestor/v1/sorteios/${id}`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push("/sorteios");
      setSaveLoading(false);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
      setSaveLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={`/sorteios`}>
                  {roleName}
                </Link>
                <Typography color="textPrimary">Atualizar</Typography>
              </Breadcrumbs>
            </Grid>
            {loading ? (
              <Loading />
            ) : (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid item xs={6}>
                    <form className={classes.form} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            label="Nome do sorteio"
                            name="name"
                            autoComplete="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="info"
                            label="Descrição"
                            name="info"
                            autoComplete="info"
                            value={info}
                            onChange={(e) => setInfo(e.target.value)}
                            multiline
                            rows={2}
                            rowsMax={4}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DatePicker
                            value={date_start}
                            onChange={setDate_start}
                            label="Data início"
                            id="date_start"
                            name="date_start"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DatePicker
                            value={date_end}
                            onChange={setDate_end}
                            label="Data fim"
                            id="date_end"
                            name="date_end"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!status}
                                onChange={(e) => {
                                  setStatus(
                                    e.target.checked === true ? true : false
                                  );
                                }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label="Ativo"
                          />
                        </Grid>

                        <Grid item xs={10}>
                          <Button
                            onClick={() => {
                              history.push(`/sorteios`);
                            }}
                            type="button"
                            variant="contained"
                            color="default"
                            style={{ marginRight: 30, marginTop: 30 }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={saveLoading}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={save}
                            style={{ marginTop: 30 }}
                          >
                            {saveLoading ? "Salvando..." : "Salvar"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
