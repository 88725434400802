/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ConfirmDialog from "./../../compoments/ConfirmDialog";
import React, { useEffect, useRef } from "react";
import Loading from "../../compoments/Loading";
import MaterialTableComponent from "../../compoments/MaterialTableComponent";
import AlertDialog from "./../../compoments/AlertDialog";
import Base from "../Base";
import api from "./../../services/axios";
import { useStyles } from "./styles";

export default function Frete({ history, match }) {
	const roleName = "Configurações de Frete";
	const classes = useStyles();
	const tableRef = useRef();
	const [loading, setLoading] = React.useState(false);
	const [actionsTableFreight, setActionsTableFreight] = React.useState([]);
	const [alertTitle, setAlertTitle] = React.useState("");
	const [message, setMessage] = React.useState(false);
	const [messageDelete, setMessageDelete] = React.useState(false);
	const [idSelectedToDelete, setIdSelectedToDelete] = React.useState(false);

	useEffect(() => {
		loadActionsTableFreight();
	}, []);

	const loadFeights = async (query) => {
		const { data } = await api.get(
			`v6/freight?perPage=${query.pageSize}&page=${query.page + 1}`
		);

		const options = {
			currency: "BRL",
			minimumFractionDigits: 2,
			maximumFractionDigits: 3,
		};
		const formatNumber = new Intl.NumberFormat("pt-BR", options);
		let freights = data.data.map((freight) => {
			return {
				...freight,
                city: `${freight.city}/${freight.state}`.toUpperCase(),
				minValueToFreeFreight: formatNumber.format(
					freight.minValueToFreeFreight
				),
				rate: formatNumber.format(freight.rate),
			};
		});

		return {
			data: freights,
			page: data.current_page ? data.current_page - 1 : 0,
			totalCount: data.total,
		};
	};

	const loadActionsTableFreight = async () => {
		setLoading(true);
		const actionsFreight = [];
		actionsFreight.push({
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, rowData) => {
				history.push(`/frete/update/${rowData.id}`);
			},
		});

		actionsFreight.push({
			icon: "add",
			tooltip: "Criar",
			isFreeAction: true,
			onClick: (event, rowData) => {
				history.push(`/frete/create`);
			},
		});

		actionsFreight.push({
			icon: "delete",
			tooltip: "Deletar",
			onClick: (event, rowData) => {
				setIdSelectedToDelete(rowData.id);
				setMessageDelete("Deseja excluir?");
			},
			disabled: false,
		});

		setActionsTableFreight(actionsFreight);
		setLoading(false);
	};

	const deleteAction = async (id) => {
		const data = await api.delete("/v6/freight/" + id);
		if (!data.status) {
			setAlertTitle("Erro");
			setMessage("Não foi possível deletar");
		} else {
			setAlertTitle("");
			setMessage("Deletado com sucesso");
			tableRef.current.onQueryChange();
		}
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Typography color="textPrimary">
									{roleName}
								</Typography>
							</Breadcrumbs>
						</Grid>
						{loading ? (
							<Loading />
						) : (
							<Grid item xs={12}>
								<MaterialTableComponent
									tableRef={tableRef}
									options={{
										search: false,
										sorting: false,
										actionsColumnIndex: -1,
									}}
									columns={[
										{
											title: "Cidade",
											field: "city",
											align: "center",
										},
										{
											title: "Bairro",
											field: "district",
											align: "center",
										},
										{
											title: "Frete gratuito",
											field: "minValueToFreeFreight",
											align: "center",
										},
                                        {
											title: "Valor mínimo para entrega",
											field: "minValueToDelivery",
											align: "center",
										},
										{
											title: "Valor do frete",
											field: "rate",
											align: "center",
										},
										{
											title: "Prazo",
											field: "deliveryLimit",
											align: "center",
										},
									]}
									data={(query) => loadFeights(query)}
									actions={actionsTableFreight}
									title="Frete"
								/>
							</Grid>
						)}
					</Grid>
					<AlertDialog
						message={message}
						onClose={() => setMessage(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
					/>
					<ConfirmDialog
						message={messageDelete}
						onClose={() => setMessageDelete(false)}
						title={"Atenção"}
						buttonSuccessText={"EXCLUIR"}
						onConfirm={async () => {
							await deleteAction(idSelectedToDelete);
							setMessageDelete(false);
						}}
					/>
				</Container>
			</main>
		</div>
	);
}
