import React, { useCallback, useEffect } from "react";

import {
	Breadcrumbs,
	CssBaseline,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import AlertDialog from "../../compoments/AlertDialog/index";
import DatePicker from "../../compoments/DatePicker";
import MaterialTableComponent from "../../compoments/MaterialTableComponent";
import Base from "../Base";
import ConfirmDialog from "./../../compoments/ConfirmDialog";
import api from "./../../services/axios";
import { dateFormat } from "./../../services/date/date";
import { useStyles } from "./styles";

function CouponsUpdate({ history, match }) {
	const classes = useStyles();
	const [couponSelected, setCouponSelected] = React.useState(null);
	const [redirect] = React.useState(false);
	const roleName = "Cupons";
	const [message, setMessage] = React.useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [alertTitle, setAlertTitle] = React.useState("");
	const [couponCode, setCouponCode] = React.useState("");
	const [discountType, setDiscountType] = React.useState("");
	const [discountValue, setDiscountValue] = React.useState("");
	const [userId, setUserId] = React.useState(null);
	const [couponInitialDate, setCouponInitialDate] = React.useState(null);
	const [couponEndDate, setCouponEndDate] = React.useState(null);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [showError, setShowError] = React.useState(false);
    const [actionsTableOrder, setActionsTableOrder] = React.useState([]);

	const getCoupon = useCallback(async  () => {
		const response = await api.get(`/v6/coupon/${match.params.id}`);
			setCouponSelected(response.data);
	}, [match])

	const loadActionsTableOrder = useCallback(() => {
		const actionsOrder = [];
		actionsOrder.push({
			icon: VisibilityIcon,
			tooltip: "View",
			onClick: (event, rowData) => {
				history.push(
					`/pedidos/${rowData.id}`
				);
			},
		});

		setActionsTableOrder(actionsOrder);
	}, [history]);


	useEffect(() => {
		getCoupon();
		loadActionsTableOrder();
	}, [loadActionsTableOrder, getCoupon]);

	useEffect(() => {
		if (couponSelected) {
			setCouponCode(couponSelected.code);
			setDiscountType(couponSelected.discount_type);
			setDiscountValue(couponSelected.discount_value);
			setUserId(couponSelected.user_id);
			setCouponInitialDate(couponSelected.initial_date);
			setCouponEndDate(couponSelected.expiration_date);
		}
	}, [couponSelected]);

    const loadOrders = async (query) => {
		const { data } = await api.get(
			`v6/order/coupon/${match.params.id}?perPage=${
				query.pageSize
			}&page=${query.page + 1}`
		);

		const options = {
			currency: "BRL",
			minimumFractionDigits: 2,
			maximumFractionDigits: 3,
		};
		const formatNumber = new Intl.NumberFormat("pt-BR", options);
		let orders = data.data.map((order) => {
			return {
				...order,
				created_at: dateFormat(new Date(order.created_at), true),
				total: formatNumber.format(order.total),
				origin: parseInt(order.campaignId) ? (
					<Link
						color="inherit"
						to={`/campanhas/update/${order.campaignId}`}
					>
						{order.campaignName}
					</Link>
				) : (
					"Catálogo"
				),
				lead_name: (
					<Link
						color="inherit"
						to={`/clientes/update/${order.lead_id}`}
					>
						{order.lead_name}
					</Link>
				),
			};
		});

		return {
			data: orders,
			page: data.current_page ? data.current_page - 1 : 0,
			totalCount: data.total,
		};
	};



	async function submitForm() {
		try {
			setSaveLoading(true);
			const formData = {
				code: couponCode,
				discount_type: discountType,
				user_id: userId,
				discount_value: discountValue,
				couponUse_type: "UNLIMITED",
				initial_date: couponInitialDate,
				expiration_date: couponEndDate,
			};
			const response = await api.put(
				`/v6/coupon/${match.params.id}`,
				formData
			);
			if (response.status === 200) {
				setShowConfirmDialog(true);
				setAlertTitle("Cupom atualizado com sucesso!");
			}
			setSaveLoading(false);
		} catch (error) {
			setShowError(true);
			setTimeout(() => {
				setShowError(false);
			}, 3000);
			setAlertTitle(error.response.data.error);
			setSaveLoading(false);
		}
	}

	const renderAlertError = () => (
		<div style={{ position: "absolute", top: "6rem", right: "8rem" }}>
			<Alert severity="error">
				<span>Erro: </span>
				{alertTitle}
			</Alert>
		</div>
	);

	
	return (
		<div className={classes.root}>
			{redirect && <Redirect to={redirect} />}
			<CssBaseline />
			{/* TOPO E MENU DRAWER*/}
			<Base />
			{/* CONTEUDO */}
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Link color="inherit" to={`/coupons`}>
									{roleName}
								</Link>
								<Typography color="textPrimary">
									Criar
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Grid item xs={12}>
									<form className={classes.form} noValidate>
										<Grid item xs={12}>
											<Grid item xs={12}>
												<TextField
													variant="outlined"
													required
													fullWidth
													id="code"
													label="Código do Cupom"
													name="code"
													autoComplete="code"
													value={couponCode}
													onChange={(e) =>
														setCouponCode(
															e.target.value
														)
													}
												/>
											</Grid>
											<Grid
												item
												xs={12}
												style={{ marginTop: "1rem" }}
											>
												<InputLabel>
													Tipo de desconto
												</InputLabel>
												<RadioGroup
													aria-labelledby="demo-radio-buttons-group-label"
													defaultValue="female"
													name="radio-buttons-group"
													row
												>
													<FormControlLabel
														onChange={() => {
															setDiscountType(
																"PERCENTAGE"
															);
														}}
														value="PERCENTAGE"
														control={
															<Radio
																checked={
																	couponSelected?.discount_type ===
																	"PERCENTAGE"
																}
															/>
														}
														label="Porcentagem"
													/>
													<FormControlLabel
														onChange={() => {
															setDiscountType(
																"VALUE"
															);
														}}
														value="VALUE"
														control={
															<Radio
																checked={
																	couponSelected?.discount_type ===
																	"VALUE"
																}
															/>
														}
														label="Valor"
													/>
												</RadioGroup>
											</Grid>
											<Grid item xs={6}>
												<TextField
													variant="outlined"
													required
													fullWidth
													id="discount_value"
													label="Valor do desconto"
													name="discount_value"
													autoComplete="discount_value"
													value={discountValue}
													onChange={(e) =>
														setDiscountValue(
															e.target.value
														)
													}
												/>
											</Grid>
											<Grid item xs={6}>
												<DatePicker
													required
													value={couponInitialDate}
													onChange={
														setCouponInitialDate
													}
													label="Data da liberação do cupom"
													id="couponInititalDate"
													name="couponInitialDate"
												/>
											</Grid>
											<Grid item xs={12}>
												<DatePicker
													value={couponEndDate}
													onChange={setCouponEndDate}
													label="Data da expiração do cupom"
													id="expiration_date"
													name="expiration_date"
												/>
											</Grid>
											<Grid item xs={12}>
												<MaterialTableComponent
													options={{
														search: false,
														sorting: false,
														actionsColumnIndex: -1,
													}}
													columns={[
														{
															title: "Data do pedido",
															field: "created_at",
															align: "center",
														},
														{
															title: "Cliente",
															field: "lead_name",
															align: "center",
														},
														{
															title: "Origem",
															field: "origin",
															align: "center",
														},
														{
															title: "Nome do endereço",
															field: "address_name",
															align: "center",
														},
														{
															title: "Pedido",
															field: "id",
															align: "right",
															cellStyle: {
																textAlign:
																	"right",
															},
														},
														{
															title: "Itens",
															field: "quantity",
															align: "right",
															cellStyle: {
																textAlign:
																	"right",
															},
														},
														{
															title: "Total R$",
															field: "total",
															align: "right",
															cellStyle: {
																textAlign:
																	"right",
															},
														},
													]}
													// data={[
													// 	{
													// 		created_at:
													// 			"2023-02-25 às 19:30",
													// 		lead_name:
													// 			"Vanderlei 2",
													// 		origin: "Catálogo",
													// 		address_name:
													// 			"Casa",
													// 		id: 1,
													// 		quantity: 1,
													// 		total: "10,00",
													// 	},
													// 	{
													// 		created_at:
													// 			"2023-02-25 às 19:27",
													// 		lead_name:
													// 			"Vanderlei 1",
													// 		origin: "Campanha de Verão",
													// 		address_name:
													// 			"Casa",
													// 		id: 1,
													// 		quantity: 1,
													// 		total: "10,00",
													// 	},
													// ]}
													data={(query) => loadOrders(query)}
													actions={actionsTableOrder}
													title="Pedidos"
												/>
											</Grid>
											<Grid item xs={12}>
												<Button
													onClick={() => {
														history.push(
															`/coupons`
														);
													}}
													type="button"
													variant="contained"
													color="default"
													// className={classes.submit}
													style={{
														marginRight: 30,
														marginTop: 30,
													}}
												>
													Cancelar
												</Button>
												<Button
													disabled={saveLoading}
													type="button"
													variant="contained"
													color="primary"
													onClick={submitForm}
													style={{ marginTop: 30 }}
												>
													{saveLoading
														? "Salvando..."
														: "Salvar"}
												</Button>
											</Grid>
										</Grid>
									</form>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<AlertDialog
						message={message}
						onClose={() => setMessage(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
					/>
					<ConfirmDialog
						message={showConfirmDialog}
						onClose={() => setShowConfirmDialog(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
						onConfirm={() => {
							setShowConfirmDialog(false);
							history.push("/coupons");
						}}
					/>
					{showError && renderAlertError()}
				</Container>
			</main>
		</div>
	);
}

export default CouponsUpdate;
