import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { cleanMoneyMask } from "masks-br";
import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { formatarMoeda } from "../../compoments/Util";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import DatePicker from "./../../compoments/DatePicker";
import Loading from "./../../compoments/Loading";
import PhotosLine from "./../../compoments/Photos/Line";
import api from "./../../services/axios";
import jwtDecode from "./../../services/jwt";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  input: {
    display: "none",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: "100%",
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Create({ match, history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("createrotulosgestor")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const roleName = "Rótulos";

  const [redirect] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [lojas, setLojas] = React.useState([]);
  const [nome, setNome] = React.useState([]);
  const [tipoRotulo, setTipoRotulo] = React.useState({});
  const [tiposRotulos, setTiposRotulos] = React.useState([]);
  const [pais, setPais] = React.useState([]);
  const [paises, setPaises] = React.useState([]);
  const [regioes, setRegioes] = React.useState([]);
  const [regiao, setRegiao] = React.useState([]);
  const [vinicola, setVinicola] = React.useState("");
  const [tiposVinhos, setTiposVinhos] = React.useState([]);
  const [tipoVinho, setTipoVinho] = React.useState("");
  const [semSafra, setSemSafra] = React.useState(false);
  const [safra, setSafra] = React.useState("");
  const [alcool, setAlcool] = React.useState("");
  const [descricao, setDescricao] = React.useState("");
  const [uvas, setUvas] = React.useState([]);
  const [uvasSelected, setUvasSelected] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [experiencias, setExperiencias] = React.useState([]);
  const [experienciasSelected, setExperienciasSelected] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pratos, setPratos] = React.useState([]);
  const [pratosSelected, setPratosSelected] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [valores, setValores] = React.useState([]);
  const [valoresSelected, setValoresSelected] = React.useState([]);
  const [preco, setPreco] = React.useState("");
  const [precoPromocional, setPrecoPromocional] = React.useState(undefined);
  const [dataPostagem, setDataPostagem] = React.useState(new Date());
  const [estoque, setEstoque] = React.useState(Number);
  const [photos, setPhotos] = React.useState([]);

  const [photosSelected, setPhotosSelected] = React.useState([]);
  const [loadRegioes, setLoadRegioes] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [showLoja, setShowLoja] = React.useState(false);
  const [status, setStatus] = React.useState("1");
  const [loja, setLoja] = React.useState("");
  const [lojaValue, setLojaValue] = React.useState("");
  const [destaque, setDestaque] = React.useState("0");
  const [ordem, setOrdem] = React.useState("");
  const [link, setLink] = React.useState("");
  const [codRef, setCodRef] = React.useState("");

  const loadData = useCallback(async () => {
    const dataLojas = await api.get("/v3/users?role=business");
    setLojas(dataLojas.data.data);
    const dataPaises = await api.get("/v2/meta/paises?paisId=all");
    setPaises(dataPaises.data.data);
    const dataTiposVinhos = await api.get("/v2/meta/tipos-vinhos");
    setTiposVinhos(dataTiposVinhos.data.data);
    const dataTipos = await api.get("/v3/tipos-indicacoes");
    setTiposRotulos(dataTipos.data.data);
    setTipoRotulo(dataTipos.data.data[0]);
    const dataUvas = await api.get("/v3/uvas");
    let uvassData = dataUvas.data.data.map((i, k) => {
      return {
        title: i.nome,
        id: i.id,
      };
    });
    setUvas(uvassData);
    // const dataExperiencias = await api.get(
    //   "/v4/categories?tipo=experiencia&final=1"
    // );
    // let experienciasData = dataExperiencias.data.data.map((i, k) => {
    //   return {
    //     title: i.name,
    //     id: i.id,
    //   };
    // });
    // setExperiencias(experienciasData);
    // const dataPratos = await api.get("/v4/categories?tipo=prato&final=1");
    // let pratosData = dataPratos.data.data.map((i, k) => {
    //   return {
    //     title: i.name,
    //     id: i.id,
    //   };
    // });
    // setPratos(pratosData);
    // const dataValor = await api.get("/v4/categories?tipo=valor&final=1");
    // let valorData = dataValor.data.data.map((i, k) => {
    //   return {
    //     title: i.name,
    //     id: i.id,
    //   };
    // });
    // setValores(valorData);
    setLoading(false);
  }, [setLojas, setPaises, setTiposVinhos, setTiposRotulos]);

  const loadRegiao = async (paisId) => {
    setRegioes([]);
    setLoadRegioes(true);
    const dataRegioesLoad = await api.get(
      "/v2/meta/regioes-business?paisId=" + paisId
    );
    setRegioes(dataRegioesLoad.data.data);
    setLoadRegioes(false);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    const tokenData = jwtDecode(localStorage.getItem("token"));
    setShowLoja(!!tokenData.user.roles.find((r, k) => r === "administrator"));
  }, []);

  const dateFormat = (date) => {
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yy = date.getFullYear().toString();
    return yy + "-" + mm + "-" + dd;
  };

  const addPhoto = (event) => {
    const photoSelected = event.target.files[0];
    const typeArr = photoSelected.type.split("/");
    const tp = ["jpg", "jpeg", "png"];
    if (
      typeArr &&
      typeArr[0] &&
      typeArr[0] === "image" &&
      typeArr[1] &&
      tp.indexOf(typeArr[1].toLowerCase()) !== -1
    ) {
      const photosNew = [
        ...[
          {
            uri: URL.createObjectURL(photoSelected),
            id: ((Date.now() % 1000) / 1000).toString(),
            preview: true,
          },
        ],
        ...photos,
      ];
      setPhotosSelected([...photosSelected, ...[photoSelected]]);
      setPhotos(photosNew);
    } else {
      setMessage("Imagem inválida");
    }
  };

  const save = async () => {
    setSaveLoading(true);
    const data = new FormData();

    photosSelected.map((i, k) => {
      return data.append("photos[]", i, i.name);
    });

    let uvasIds = uvasSelected.map((i, k) => i.id);
    let experienciasIds = experienciasSelected.map((i, k) => i.id);
    let pratosIds = pratosSelected.map((i, k) => i.id);
    let valoresIds = valoresSelected.map((i, k) => i.id);

    if (!(tipoRotulo.nome === "Diversos")) {
      data.append("meta_pais_id", pais);
      data.append("meta_regiao_id", regiao);
      data.append("meta_regiao_id", regiao);
      data.append("vinicola", vinicola);
      data.append("meta_tipo_vinho_id", tipoVinho);
      data.append("safra", safra);
      data.append("sem_safra", semSafra === true ? 1 : 0);
      data.append("porcentagem_alcool", alcool);
      precoPromocional &&
        data.append("preco_promocional", cleanMoneyMask(precoPromocional));

      data.append("uva_ids", JSON.stringify(uvasIds));
      data.append("experiencias_ids", JSON.stringify(experienciasIds));
      data.append("pratos_ids", JSON.stringify(pratosIds));
      data.append("valores_ids", JSON.stringify(valoresIds));
      data.append("motor_venda_id", "");
      data.append("link", link);
      data.append("ordem", ordem);
      data.append("destaque", destaque);
      data.append("loja_id", "");
    }
    data.append("cod_ref", codRef);
    data.append("status_indicacao_id", status);
    data.append("tipo_indicacao_id", tipoRotulo.id);
    data.append("user_id", loja && loja.id);
    data.append("nome", nome);
    data.append("descricao", descricao);
    data.append("preco", cleanMoneyMask(preco));
    data.append("data_postagem", dataPostagem ? dateFormat(dataPostagem) : "");
    data.append("estoque", estoque);

    try {
      await api.post("/gestor/v1/indicacoes", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push("/rotulos");
      setSaveLoading(false);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
      setSaveLoading(false);
    }
  };

  const classes = useStyles();

  const removePhoto = async (id, preview, index) => {
    if (preview) {
      const photosSelectedArr = photosSelected;
      photosSelectedArr.splice(index, 1);
      setPhotosSelected(photosSelectedArr);
      const phoN = photos.filter((item) => {
        return item.id.toString() !== id.toString();
      });
      setPhotos(phoN);
    }
    setAlertTitle("");
  };

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={`/rotulos`}>
                  {roleName}
                </Link>
                <Typography color="textPrimary">Criar</Typography>
              </Breadcrumbs>
            </Grid>
            {loading ? (
              <Loading />
            ) : (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      {showLoja && (
                        <Grid item xs={6}>
                          <Autocomplete
                            required
                            value={loja}
                            onChange={(event, newValue) => {
                              setLoja(newValue);
                            }}
                            inputValue={lojaValue}
                            onInputChange={(event, newInputValue) => {
                              setLojaValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={lojas}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Loja *" />
                            )}
                            getOptionLabel={(option) => option.name}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Tipo do Rótulo
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={tiposRotulos[0]}
                            value={tipoRotulo}
                            onChange={(e) => setTipoRotulo(e.target.value)}
                          >
                            {tiposRotulos.map((data, k) => (
                              <MenuItem value={data} key={data.id}>
                                {data.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="nome"
                          label="Nome"
                          name="nome"
                          autoComplete="nome"
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                        />
                      </Grid>
                      {!(tipoRotulo.nome === "Diversos") && (
                        <>
                          <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">
                                País
                              </InputLabel>
                              <Select
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={pais}
                                onChange={(e) => {
                                  setPais(e.target.value);
                                  loadRegiao(e.target.value);
                                }}
                              >
                                {paises.map((data, k) => (
                                  <MenuItem value={data.id} key={data.id}>
                                    {data.descricao}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">
                                {!loadRegioes ? "Região" : "Carregando..."}
                              </InputLabel>
                              <Select
                                required
                                disabled={regioes.length < 1 ? true : false}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={regiao}
                                onChange={(e) => setRegiao(e.target.value)}
                              >
                                {regioes.map((data, k) => (
                                  <MenuItem value={data.id} key={data.id}>
                                    {data.descricao}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              id="vinicola"
                              label="Vinícola"
                              name="vinicola"
                              autoComplete="vinicola"
                              value={vinicola}
                              onChange={(e) => setVinicola(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">
                                Tipo de vinho
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tipoVinho}
                                onChange={(e) => setTipoVinho(e.target.value)}
                              >
                                {tiposVinhos.map((data, k) => (
                                  <MenuItem value={data.id} key={data.id}>
                                    {data.descricao}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={semSafra}
                                  onChange={(e) => {
                                    setSemSafra(
                                      e.target.checked === true ? true : false
                                    );
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="Não safrado"
                            />
                          </Grid>
                          {!semSafra && (
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="safra"
                                label="Safra"
                                name="safra"
                                autoComplete="safra"
                                value={safra}
                                onChange={(e) => setSafra(e.target.value)}
                                type="number"
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              id="alcool"
                              label="Álcool"
                              name="alcool"
                              autoComplete="alcool"
                              value={alcool}
                              onChange={(e) => setAlcool(e.target.value)}
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={uvas}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.title}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={
                                      !!selected ||
                                      !!uvasSelected.find(
                                        (i, k) => i.id === option.id
                                      )
                                    }
                                  />
                                  {option.title}
                                </React.Fragment>
                              )}
                              style={{ width: 500 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Uvas"
                                  placeholder="Buscar..."
                                />
                              )}
                              value={uvasSelected}
                              defaultValue={uvasSelected}
                              onChange={(event, value, reason) => {
                                if (value.length > 0) {
                                  let selected = value.pop();
                                  let exist = value
                                    .map((i, k) => i.id)
                                    .indexOf(selected.id);
                                  if (exist > -1) {
                                    value.splice(exist, 1);
                                  } else {
                                    value.push(selected);
                                  }
                                }
                                setUvasSelected(value);
                              }}
                            />
                          </Grid>
                          {isPermission(
                            "podeadicionarexperienciasemrotulos"
                          ) && (
                            <Grid item xs={12}>
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={experiencias}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={
                                        !!selected ||
                                        !!experienciasSelected.find(
                                          (i, k) => i.id === option.id
                                        )
                                      }
                                    />
                                    {option.title}
                                  </React.Fragment>
                                )}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Experiências"
                                    placeholder="Buscar..."
                                  />
                                )}
                                value={experienciasSelected}
                                defaultValue={experienciasSelected}
                                onChange={(event, value, reason) => {
                                  if (value.length > 0) {
                                    let selected = value.pop();
                                    let exist = value
                                      .map((i, k) => i.id)
                                      .indexOf(selected.id);
                                    if (exist > -1) {
                                      value.splice(exist, 1);
                                    } else {
                                      value.push(selected);
                                    }
                                  }
                                  setExperienciasSelected(value);
                                }}
                              />
                            </Grid>
                          )}
                          {isPermission("podeadicionarpratosemrotulos") && (
                            <Grid item xs={12}>
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={pratos}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={
                                        !!selected ||
                                        !!pratosSelected.find(
                                          (i, k) => i.id === option.id
                                        )
                                      }
                                    />
                                    {option.title}
                                  </React.Fragment>
                                )}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Pratos"
                                    placeholder="Buscar..."
                                  />
                                )}
                                value={pratosSelected}
                                defaultValue={pratosSelected}
                                onChange={(event, value, reason) => {
                                  if (value.length > 0) {
                                    let selected = value.pop();
                                    let exist = value
                                      .map((i, k) => i.id)
                                      .indexOf(selected.id);
                                    if (exist > -1) {
                                      value.splice(exist, 1);
                                    } else {
                                      value.push(selected);
                                    }
                                  }
                                  setPratosSelected(value);
                                }}
                              />
                            </Grid>
                          )}
                          {isPermission("podeadicionarvaloresemrotulos") && (
                            <Grid item xs={12}>
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={valores}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={
                                        !!selected ||
                                        !!valoresSelected.find(
                                          (i, k) => i.id === option.id
                                        )
                                      }
                                    />
                                    {option.title}
                                  </React.Fragment>
                                )}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Valores"
                                    placeholder="Buscar..."
                                  />
                                )}
                                value={valoresSelected}
                                defaultValue={valoresSelected}
                                onChange={(event, value, reason) => {
                                  if (value.length > 0) {
                                    let selected = value.pop();
                                    let exist = value
                                      .map((i, k) => i.id)
                                      .indexOf(selected.id);
                                    if (exist > -1) {
                                      value.splice(exist, 1);
                                    } else {
                                      value.push(selected);
                                    }
                                  }
                                  setValoresSelected(value);
                                }}
                              />
                            </Grid>
                          )}
                        </>
                      )}

                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="descricao"
                          label="Descrição"
                          name="descricao"
                          autoComplete="descricao"
                          value={descricao}
                          onChange={(e) => setDescricao(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="preco"
                          label="Preço"
                          name="preco"
                          autoComplete="preco"
                          value={preco}
                          onChange={(e) =>
                            e.target.value.length < 11 &&
                            setPreco(formatarMoeda(e.target.value))
                          }
                        />
                      </Grid>
                      {!(tipoRotulo.nome === "Diversos") && (
                        <>
                          {isPermission("admin") && (
                            <>
                              <Grid item xs={12}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  id="link"
                                  label="Link Comprar"
                                  name="link"
                                  autoComplete="link"
                                  value={link}
                                  onChange={(e) => setLink(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl className={classes.formControl2}>
                                  <InputLabel id="demo-simple-select-label">
                                    Destaque no App
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={destaque}
                                    onChange={(e) =>
                                      setDestaque(e.target.value)
                                    }
                                  >
                                    <MenuItem value="1">Sim</MenuItem>
                                    <MenuItem value="0">Não</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  variant="outlined"
                                  id="ordem"
                                  label="Ordem no App"
                                  name="ordem"
                                  autoComplete="ordem"
                                  value={ordem}
                                  onChange={(e) => setOrdem(e.target.value)}
                                  type="number"
                                />
                              </Grid>
                            </>
                          )}

                          {tipoRotulo.id === 1 && (
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="precoPromocional"
                                label="Preço promocional"
                                name="precoPromocional"
                                autoComplete="preco_promocional"
                                value={precoPromocional}
                                onChange={(e) =>
                                  e.target.value.length < 11 &&
                                  setPrecoPromocional(
                                    formatarMoeda(e.target.value)
                                  )
                                }
                              />
                            </Grid>
                          )}
                        </>
                      )}

                      <Grid item xs={4}>
                        <DatePicker
                          required
                          value={dataPostagem}
                          onChange={setDataPostagem}
                          label="Data da liberação do catálogo"
                          id="dataPostagem"
                          name="dataPostagem"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          id="estoque"
                          label="Possui Estoque"
                          name="estoque"
                          value={estoque}
                          onChange={(e) => setEstoque(e.target.value)}
                          type="number"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="cod_ref"
                          label="Código de Referência Interno"
                          name="codRef"
                          autoComplete="codRef"
                          value={codRef}
                          onChange={(e) => setCodRef(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <MenuItem value="0">Inativo</MenuItem>
                            <MenuItem value="1">Ativo</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          type="file"
                          key={"photoUpload"}
                          name="photoUpload"
                          onChange={addPhoto}
                          id="contained-button-file"
                          className={classes.input}
                        />
                        <label
                          htmlFor="contained-button-file"
                          style={{ display: "block" }}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            startIcon={<AddPhotoAlternateIcon />}
                          >
                            Adicionar imagem
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={12}>
                        <PhotosLine photos={photos} remove={removePhoto} />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            history.push(`/rotulos`);
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          // className={classes.submit}
                          style={{ marginRight: 30, marginTop: 30 }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          disabled={saveLoading}
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={save}
                          style={{ marginTop: 30 }}
                        >
                          {saveLoading ? "Salvando..." : "Salvar"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            )}
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
