import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect } from "react";
import { Redirect } from "react-router";

import api from "./../services/axios";

import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function Nav({
  titleHeader = "",
  handleDrawerMenu,
  logoutAction,
  badgeContent = null,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [redirect] = React.useState(null);
  const [notifications] = React.useState([]);
  const [notificationsLength] = React.useState(null);

  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = React.useState("");
  const [idNotification] = React.useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [openModal, setOpenModal] = React.useState(false);
  const [userData, setUserData] = React.useState(null);
  const [values, setValues] = React.useState({});
  const [modalStyle] = React.useState(getModalStyle);

  useEffect(() => {
    loadData();
    askNotificationPermission();
  }, []);

  useEffect(() => {}, [idNotification]);

  useEffect(() => {}, [notificationsLength]);

  useEffect(() => {}, [notifications]);

  function getModalStyle() {
    const rand = Math.round(Math.random() * 20) - 10;
    const top = 50 + rand;
    const left = 50 + rand;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function toPerfil() {
    // handleMenuClose()
    window.location = "/perfil";
  }

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  const handleOpenModal = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const askNotificationPermission = function askNotificationPermission() {
    function checkNotificationPromise() {
      try {
        Notification.requestPermission().then();
      } catch (e) {
        return false;
      }

      return true;
    }

    // function to actually ask the permissions
    function handlePermission(permission) {
      // set the button to shown or hidden, depending on what the user answers
      if (
        Notification.permission === "denied" ||
        Notification.permission === "default"
      ) {
        console.log("notificação bloqueada", Notification.permission);
      } else {
        console.log("notificação ativa", Notification.permission);
      }
    }

    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission(function (permission) {
          handlePermission(permission);
        });
      }
    }
  };

  const loadData = async (loadDataFlag) => {
    const user_storage = localStorage.getItem("user");
    if (user_storage) {
      const { id } = JSON.parse(user_storage);

      const user_data = await api.get("/v3/users/" + id);

      const user = user_data.data;

      if (user) {
        setUserData(user);
      }
    }
  };

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={toPerfil}>Meu Perfil</MenuItem>
      <MenuItem onClick={() => handleOpenModal()}>
        Alterar Foto do Perfil
      </MenuItem>
      <MenuItem onClick={logoutAction}>Sair</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const save = async () => {
    const data = new FormData();
    data.append("_method", "PUT");

    if (values.logotipo.image) {
      data.append("logotipo", values.logotipo.image);
    }

    try {
      const response = await api.post("/v4/profile/logotipo", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status) {
        setValues({});
        handleCloseModal();
        loadData();
      }

      setMessage({
        message: "Imagem do perfil atualizada com sucesso.",
        title: "Sucesso",
      });

      handleCloseModal();
    } catch (error) {
      console.log({ error });
      setMessage({
        message: "Erro ao atualizar imagem do perfil.",
        title: "Erro",
      });
    }
  };

  const addPhoto = (event) => {
    const photoSelected = event.target.files[0];
    const typeArr = photoSelected.type.split("/");
    const tp = ["jpg", "jpeg", "png"];
    if (
      typeArr &&
      typeArr[0] &&
      typeArr[0] === "image" &&
      typeArr[1] &&
      tp.indexOf(typeArr[1].toLowerCase()) !== -1
    ) {
      const photoNew = {
        uri: URL.createObjectURL(photoSelected),
        id: ((Date.now() % 1000) / 1000).toString(),
        preview: true,
        image: photoSelected,
      };
      setValues((oldValues) => ({ ...oldValues, logotipo: photoNew }));
    } else {
      alert("Imagem inválida, utilize o formato 1944 x 300");
    }
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawerMenu()}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6" noWrap>
          {titleHeader}
        </Typography>

        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <Avatar
              alt="Remy Sharp"
              src={
                userData?.business?.logotipo_url
                  ? userData.business?.logotipo_url.replace(
                      "https://api.intelivino.com.br",
                      "https://images.intelivino.com.br"
                    )
                  : ""
              }
              className={classes.large}
            />
          </IconButton>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2>Insira uma imagem para o seu perfil.</h2>
          <div className={classes.root}>
            <input
              accept="image/*"
              className={classes.input}
              id="logotipo"
              type="file"
              name="logotipo"
              onChange={addPhoto}
            />
            <label htmlFor="logotipo">
              <Button variant="contained" color="primary" component="span">
                Selecionar Imagem
              </Button>
            </label>
            <Button autoFocus color="inherit" onClick={save}>
              Salvar
            </Button>
          </div>
          {values.logotipo && (
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  width="300"
                  src={values.logotipo.uri}
                  className={classes.media}
                  onError={(e) => console.log(e)}
                  title="logotipo"
                />
              </CardActionArea>
            </Card>
          )}
        </div>
      </Modal>
    </>
  );
}
