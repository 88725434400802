import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useMemo, useCallback } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import ImageUpload from "./../../compoments/ImageUpload";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import Base from "./../Base";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
}));

export default function BannerUpdate({ match, history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("createbannersgestor")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const route = "banners";
  const pageTitle = "Banners";

  const [redirect] = React.useState(false);
  const [id, setId] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [description, setDescription] = React.useState("");
  const [link, setLink] = React.useState("");
  const [order, setOrder] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [thumbnail, setThumbnail] = React.useState(null);
  const [removeFlag, setRemoveFlag] = React.useState(true);
  const [sorteios, setSorteios] = React.useState([]);
  const [sorteioValue, setSorteioValue] = React.useState("");
  const [sorteio, setSorteio] = React.useState("");
  const [loadingSorteios, setLoadingSorteios] = React.useState(true);

  const loadData = useCallback(async () => {
    setId(match.params.id);
    const data = await api.get(`/v3/${route}/` + match.params.id);
    if (data.data.sorteio && data.data.sorteio.id) {
      setSorteio({ id: data.data.sorteio.id, name: data.data.sorteio.nome });
    }
    setDescription(data.data.description);
    setLink(data.data.link);
    setOrder(data.data.order);
    setStatus(data.data.active.toString());
    setThumbnail(`${data.data.base_url}${data.data.image_url}`);
    setLoading(false);
    setLoadingSorteios(true);
    const dataSorteios = await api.get("/gestor/v1/sorteios?perPage=100");
    setSorteios(
      dataSorteios.data.data.map((i) => {
        return { id: i.id, name: i.nome };
      })
    );
    setLoadingSorteios(false);
  }, [match]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const save = async () => {
    if (thumbnail) {
      const data = new FormData();
      if (thumbnail.name) {
        data.append("image[]", thumbnail, thumbnail.name);
      }

      data.append("sorteio_id", sorteio && sorteio.id ? sorteio.id : null);
      data.append("order", order);
      data.append("active", status);
      data.append("link", link);
      data.append("description", description);
      data.append("_method", "PUT");
      try {
        await api.post(`/gestor/v1/banners/${id}`, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        setAlertTitle("");
        history.push(`/${route}`);
      } catch (error) {
        setAlertTitle("Erro");
        setMessage(error.response.data.message || "Erro");
      }
    } else {
      setAlertTitle("Erro");
      setMessage("A imagem é obrigatória");
    }
  };

  const preview = useMemo(() => {
    return thumbnail && thumbnail.name
      ? URL.createObjectURL(thumbnail)
      : thumbnail;
  }, [thumbnail]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" to={`/${route}`}>
                    {pageTitle}
                  </Link>
                  <Typography color="textPrimary">Editar</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ImageUpload
                          onChange={(event) => {
                            setThumbnail(event.target.files[0]);
                            setRemoveFlag(true);
                          }}
                          previewChange={preview}
                          removeFlag={removeFlag}
                          cropFlag={status}
                          remove={(flag) => {
                            setThumbnail(null);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="description"
                          label="Descrição"
                          name="description"
                          autoComplete="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type={"number"}
                          variant="outlined"
                          required
                          fullWidth
                          id="order"
                          label="Ordem"
                          name="order"
                          autoComplete="order"
                          value={order}
                          onChange={(e) => setOrder(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <MenuItem value="0">Inativo</MenuItem>
                            <MenuItem value="1">Ativo</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          loading={loadingSorteios}
                          value={sorteio}
                          onChange={(event, newValue) => {
                            setSorteio(newValue);
                          }}
                          inputValue={sorteioValue}
                          onInputChange={(event, newInputValue) => {
                            setSorteioValue(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={sorteios}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Sorteio" />
                          )}
                          getOptionLabel={(option) => option.name || ""}
                        />
                      </Grid>
                      {(!sorteio || !sorteio.id) && (
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="link"
                            label="Link"
                            name="link"
                            autoComplete="link"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            history.push(`/${route}`);
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          style={{ marginRight: 30 }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={save}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          )}
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
