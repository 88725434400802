import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { DataGrid, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import * as React from "react";

export default function GridWines({ data, onClickDelete, onHandleChangeCell }) {
  const gridApiRef = useGridApiRef();

	const enterEditCell = React.useCallback(
		(params) => () => {
			gridApiRef.current.startCellEditMode({
				id: params.id,
				field: "customPrice",
			});
		},
		[gridApiRef]
	);
	const columns = [
		{
			field: "wine",
			headerName: "Rótulo",
			flex: 1,
			editable: false,
		},
		{
			field: "price",
			headerName: "Preço R$",
			type: "number",
			editable: false,
		},
		{
			field: "customPrice",
			headerName: "Preço personalizado R$",
			type: "number",
			editable: true,
			flex: 1,
		},
		{
			field: "actions",
			type: "actions",
			width: 80,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<Edit />}
					key={params.id}
					label="Editar"
					onClick={enterEditCell(params)}
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon />}
					key={params.id}
					label="Delete"
					onClick={onClickDelete(params.id)}
				/>,
			],
		},
	];

  return (
    <div>
      <DataGrid
        apiRef={gridApiRef}
        autoHeight
        rows={data}
        columns={columns}
        processRowUpdate={onHandleChangeCell}
        hideFooterSelectedRowCount
        initialState={{
          ...data.initialState,
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: "Registros por pagina",
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        experimentalFeatures={{ newEditingApi: true }}
        style={{ width: 800 }}
      />
    </div>
  );
}
