/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef } from "react";
import MaterialTableComponent from "./../../../compoments/MaterialTableComponent";
import Base from "./../../Base";
import { useStylesIndex } from "./styles";
import jwtDecode from "../../../services/jwt";
import newApi from "../../../services/axios/new-api";
import Loading from "../../../compoments/Loading";
import AlertDialog from "../../../compoments/AlertDialog";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Unavailable from "../unavailable";
import { featuresAvailable } from "../../../services/permission";

export default function Index({ history }) {
  const roleName = "Estoque";

  const classes = useStylesIndex();
  const [wineType, setWineType] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [grape, setGrape] = React.useState("");
  const [wineTypeId, setWineTypeId] = React.useState(null);
  const [typeOrder, setTypeOrder] = React.useState(null);
  const [countryId, setCountryId] = React.useState(null);
  const [grapeId, setGrapeId] = React.useState(null);
  const [loja, setLoja] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [historyWinetypes, setHistoryWinetypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [grapes, setGrapes] = React.useState([]);
  const [actionsTable] = React.useState(loadActionsTable());
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const tableRef = useRef();

  const [openForm, setOpenForm] = React.useState(false);
  const [editableRowData, setEditableRowData] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const itemPerPage = 25;
  const [stockAvailable, setStockAvailable] = React.useState(null);

  useEffect(() => {
    setIsLoading(true);
    setLoja(getUser());
    loadWinetypes();
    loadCountries();
    loadGrapes();
    handleLoadData();
    loadPermissionPage();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleLoadData();
  }, [wineTypeId, countryId, grapeId, typeOrder]);

  useEffect(() => {
    handleLoadData();
  }, [page]);

  function loadActionsTable() {
    let actionsDt = [];

    actionsDt.push({
      icon: "edit",
      tooltip: "Editar",
      onClick: (event, rowData) => {
        setEditableRowData(rowData);
        setOpenForm(true);
      },
    });

    return actionsDt;
  }

  const loadPermissionPage = async () => {
    const { stockAvailable } = await featuresAvailable(loja.id ?? getUser().id);
    setStockAvailable(stockAvailable);
  };

  const loadWinetypes = async () => {
    const { data } = await newApi.get(
      `/catalog/winetypes?idAccount=loja-inteli`
    );
    setHistoryWinetypes(data);
  };

  const loadCountries = async () => {
    const { data } = await newApi.get(
      `/catalog/countries?idAccount=loja-inteli`
    );
    setCountries(data);
  };

  const loadGrapes = async () => {
    const { data } = await newApi.get(`/catalog/grapes?idAccount=loja-inteli`);
    setGrapes(data);
  };

  const getUser = () => {
    const data = jwtDecode(localStorage.getItem("token"));
    return data.user;
  };

  const handleChangeWineType = (e, value, reason) => {
    const id = value.props["data-id"];
    setWineTypeId(id);
    setWineType(e.target.value);
  };

  const handleChangeCountry = (e, value, reason) => {
    const id = value.props["data-id"];
    setCountryId(id);
    setCountry(e.target.value);
  };

  const handleChangeGrape = (e, value, reason) => {
    const id = value.props["data-id"];
    setGrapeId(id);
    setGrape(e.target.value);
  };

  const handleChangeTypeOrder = (e, value, reason) => {
    setTypeOrder(e.target.value);
  };

  const formatToMoney = (value) => {
    const options = {
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    };
    const formatNumber = new Intl.NumberFormat("pt-BR", options);
    return formatNumber.format(value);
  };

  const handleLoadData = async () => {
    try {
      const idUser = loja.id ?? getUser().id;
      const { data } = await newApi.get(
        `/stock/inventory/${idUser}?limit=${itemPerPage}&offset=${page}`,
        {
          params: {
            wineTypeId,
            countryId,
            grapeId,
            typeOrder,
          },
        }
      );
      setTotalCount(data.total);
      const moviments = data.data.items.map((item) => ({
        ...item,
        priceUnit: formatToMoney(item.priceUnit),
        priceTotal: formatToMoney(item.priceTotal),
      }));

      setTableData(moviments);
    } catch (error) {
      return;
    }
  };

  const handleChange = (event) => {
    setEditableRowData({
      ...editableRowData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const idUser = loja.id ?? getUser().id;
    const price = editableRowData.priceUnit.replace(",", ".");
    const data = {
      quantity: editableRowData.quantity,
      priceAverage: price,
      minQuantity: editableRowData.stockMin,
    };

    const total = editableRowData.quantity * parseFloat(price).toFixed(2);
    try {
      await newApi.patch(
        `/stock/stockitem/${idUser}/${editableRowData.itemId}`,
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const items = tableData.map((item) => {
        if (item.itemId === editableRowData.itemId) {
          return {
            ...item,
            quantity: editableRowData.quantity,
            priceUnit: formatToMoney(price),
            priceTotal: formatToMoney(total),
            stockMin: editableRowData.stockMin,
          };
        }

        return item;
      });
      setTableData(items);
      setOpenForm(false);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response?.data?.message || "Erro");
    }
  };

  const formStockAdjustment = () => (
    <div>
      <Dialog open={openForm} onClose={() => setOpenForm(false)}>
        <DialogTitle>Ajustar Estoque</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="quantity"
              label="Quantidade"
              value={editableRowData.quantity}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              name="stockMin"
              label="Estoque Mínimo"
              value={editableRowData.stockMin}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              name="priceUnit"
              label="Preço Unitário"
              value={editableRowData.priceUnit}
              onChange={handleChange}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenForm(false)}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const renderFilters = () => (
    <>
      <Grid item xs={3}>
        <InputLabel>Filtrar por Tipo de Vinho:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={wineType}
          name="wineType"
          onChange={handleChangeWineType}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value="">Todos</MenuItem>
          {historyWinetypes.map((data, k) => (
            <MenuItem value={data.name} key={data.id} data-id={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <InputLabel>Filtrar por Uva:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={grape}
          name="grape"
          onChange={handleChangeGrape}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value="">Todas</MenuItem>
          {grapes.map((data, k) => (
            <MenuItem value={data.name} key={data.id} data-id={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <InputLabel>Filtrar por País:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={country}
          name="country"
          onChange={handleChangeCountry}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value="">Todos</MenuItem>
          {countries.map((data, k) => (
            <MenuItem value={data.name} key={data.id} data-id={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <InputLabel>Ordenação:</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={typeOrder}
          name="typeOrder"
          onChange={handleChangeTypeOrder}
          style={{ width: 200 }}
          displayEmpty
        >
          <MenuItem value={null}>Padrão</MenuItem>
          <MenuItem value="NAME">Alfabética</MenuItem>
          <MenuItem value="QUANTITY_ASC">Estoque Atual Crescente</MenuItem>
          <MenuItem value="QUANTITY_DESC">Estoque Atual Decrescente</MenuItem>
        </Select>
      </Grid>
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Base />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {!stockAvailable && stockAvailable !== null ? (
          Unavailable()
        ) : (
          <Container maxWidth="lg" className={classes.container}>
            {openForm && formStockAdjustment()}
            {isLoading || stockAvailable === null ? (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">{roleName}</Typography>
                  </Breadcrumbs>
                </Grid>

                {renderFilters()}

                <Grid item xs={12}>
                  <MaterialTableComponent
                    tableRef={tableRef}
                    columns={[
                      {
                        title: "Rótulo",
                        field: "itemName",
                        width: "30%",
                      },
                      { title: "Cod Ref", field: "codeRef" },
                      {
                        title: "Quant",
                        field: "quantity",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Estoque Mínimo",
                        field: "stockMin",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Valor Unit R$",
                        field: "priceUnit",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                      {
                        title: "Valor Total R$",
                        field: "priceTotal",
                        align: "right",
                        cellStyle: {
                          textAlign: "right",
                        },
                      },
                    ]}
                    data={tableData}
                    title="Ajuste de Estoque"
                    options={{
                      search: false,
                      sorting: false,
                      actionsColumnIndex: -1,
                      pageSize: itemPerPage,
                    }}
                    actions={actionsTable}
                    page={page > 0 ? page - 1 : 0}
                    onChangePage={(newPage) => {
                      setPage(newPage + 1);
                    }}
                    totalCount={totalCount}
                  />
                </Grid>
              </Grid>
            )}
            <AlertDialog
              message={message}
              onClose={() => setMessage(false)}
              title={alertTitle}
              buttonSuccessText={"OK"}
            />
          </Container>
        )}
      </main>
    </div>
  );
}
