/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { cleanMoneyMask } from "masks-br";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { formatarMoeda } from "../../compoments/Util";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import DatePicker from "./../../compoments/DatePicker";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import jwtDecode from "./../../services/jwt";
import Base from "./../Base";
import GridWines from "./grid-wines";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  input: {
    display: "none",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: "100%",
  },
}));

export default function CampanhaCreate({ match, history }) {
  const loadPermission = async () => {
    if (!isPermission("createcampanhasgestor")) {
      history.push("/401");
    }
  };

  useEffect(() => {
    loadPermission();
  }, []);

  const roleName = "Campanhas";

  const [redirect] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [loja, setLoja] = React.useState("");
  const [loadingLojas, setLoadingLojas] = React.useState(true);
  const [lojas, setLojas] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [showLoja, setShowLoja] = React.useState(false);
  const [thumbnail] = React.useState(null);
  const [name, setName] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [date_start, setDate_start] = React.useState(null);
  const [date_end, setDate_end] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [rotulosSelected, setRotulosSelected] = React.useState([]);
  const [rotulos, setRotulos] = React.useState([]);
  const [lojaValue, setLojaValue] = React.useState("");
  const [loadingRotulos, setLoadingRotulos] = React.useState(true);
  const [campainType, setCampainType] = React.useState();
  const [rotulosIndex, setRotulosIndex] = React.useState([]);
  const [presentation, setPresentation] = React.useState("");
  const [onConfirmationEnd, setOnConfirmationEnd] = React.useState("");
  const [coupon, setCoupon] = React.useState("");
  const [askForAddress, setAskForAddress] = React.useState(false);
  const [askForCatalog, setAskForCatalog] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState("");
  const [showOriginalPriceIndication, setShowOriginalPriceIndication] =
    React.useState(true);

  const [tableWineItems, setTableWineItems] = React.useState([]);
  const inputRefItem = useRef(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [selectedItem] = React.useState([]);
  const [searchTermItem, setSearchTermItem] = React.useState("");
  const [inputValueItem, setInputValueItem] = React.useState("");
  const handleBlurItem = () => {
    if (!selectedItem.length) {
      setInputValueItem(inputValueItem || searchTermItem);
    }
  };

  const handleFocusItem = () => {
    setInputValueItem(inputValueItem || searchTermItem);
  };

  const loadData = async () => {
    const tokenData = jwtDecode(localStorage.getItem("token"));
    setLoja(tokenData.user);
    let showLojaP = !!tokenData.user.roles.find(
      (r, k) => r === "administrator"
    );
    setShowLoja(showLojaP);
    if (showLojaP) {
      setLoadingLojas(true);
      const dataLojas = await api.get("/v3/users?role=business");
      setLojas(dataLojas.data.data);
      setLoadingLojas(false);
    } else {
      loadRotulos({ id: tokenData.user.id }, 0);
    }
    setLoading(false);
  };

  const searchRotulosInApi = async (
    search,
    discountP = 0,
    userId,
    pageApi = 1
  ) => {
    setLoadingRotulos(true);
    const dataRotulos = await api.get(
      `v5/indicacoes?search=${search}&perPage=20&userId=${userId}&page=${pageApi}`
    );
    let rotulosData = dataRotulos.data.data.map((i, k) => {
      let porcent = 0;
      if (discountP) {
        porcent = i.preco * (parseInt(discountP) / 100);
      }
      let infoUvas = "";

      if (i.uvas) {
        infoUvas = i.uvas
          ?.map((uva) => {
            return uva.nome;
          })
          .join(", ");
      }

      let price = Math.round((i.preco - porcent) * 100) / 100;
      return {
        title: i.nome + " R$ " + price,
        preco: price,
        id: i.id,
        nome: i.nome,
        estoque: i.estoque,
        status: i.status.id,
        columnToFilter: `${i.nome}, ${i.meta_pais_descricao}, ${i.safra}, ${i.vinicola}, ${i.meta_regiao_descricao}, ${i.meta_tipo_vinho_descricao}, ${infoUvas}, R$ ${price}`,
      };
    });

    if (pageApi === 1) {
      setRotulos(rotulosData);
    } else {
      setRotulos((prevList) => [...prevList, ...rotulosData]);
    }

    if (rotulosData.length === 0) {
      setHasMore(false);
    }
    setLoadingRotulos(false);
  };

  const debounceSearchRotulosInApi = useDebouncedCallback(
    (search, discountP = 0, userId) => {
      searchRotulosInApi(search, discountP, userId);
    },
    800
  );

  const debounceSearchRotulos = useDebouncedCallback((loja, discountP = 0) => {
    loadRotulos(loja, discountP);
  }, 800);

  const loadRotulos = async (
    loja,
    discountP,
    rotulosSelectedP = [],
    pageApi = 1
  ) => {
    if (rotulos && pageApi === 1) {
      setRotulos([]);
      setLoadingRotulos(!loadingRotulos);
    }
    if (loja && loja.id) {
      const dataRotulos = await api.get(
        `v5/indicacoes?perPage=100&userId=${loja.id}&page=${pageApi}`
      );
      let rotulosData = dataRotulos.data.data.map((i, k) => {
        let porcent = 0;
        if (discountP) {
          porcent = i.preco * (parseInt(discountP) / 100);
        }

        let infoUvas = "";

        if (i.uvas) {
          infoUvas = i.uvas
            ?.map((uva) => {
              return uva.nome;
            })
            .join("/");
        }
        let price = Math.round((i.preco - porcent) * 100) / 100;
        return {
          title: i.nome + " R$ " + price,
          preco: price,
          id: i.id,
          nome: i.nome,
          estoque: i.estoque,
          status: i.status.id,
          columnToFilter: `${i.nome}/${i.meta_pais_descricao}/${i.safra}/${i.vinicola}/${i.meta_regiao_descricao}/${i.meta_tipo_vinho_descricao}/${infoUvas}/R$ ${price}`,
        };
      });

      if (pageApi === 1) {
        setRotulos(rotulosData);
        setRotulosIndex(rotulosData);
      } else {
        setRotulos((prevList) => [...prevList, ...rotulosData]);
        setRotulosIndex((prevList) => [...prevList, ...rotulosData]);
      }

      if (rotulosData.length === 0) {
        setHasMore(false);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setShowOriginalPriceIndication(parseInt(campainType) !== 4);
  }, [campainType]);

  const save = async () => {
    setSaveLoading(true);
    const data = new FormData();

    if (thumbnail && thumbnail.name) {
      data.append("photos[]", thumbnail, thumbnail.name);
    }

    let indicacoes = tableWineItems.map((wine) => {
      let objWine = {};
      objWine.id = wine.id;
      if (wine.customPrice && wine.customPrice > 0) {
        objWine.price = wine.customPrice;
      }
      return objWine;
    });

    data.append("user_id", loja && loja.id ? loja.id : null);
    data.append("name", name);
    data.append("discount", cleanMoneyMask(discount));
    if (date_start) {
      data.append("date_start", moment(date_start).format("YYYY-MM-DD"));
    }

    if (date_end) {
      data.append("date_end", moment(date_end).format("YYYY-MM-DD"));
    }

    data.append("status", !!status === true ? 1 : 0);
    data.append("indicacoesIds", JSON.stringify(indicacoes));
    data.append("type", campainType);
    data.append("presentation", presentation);
    data.append("on_confirmation_end", onConfirmationEnd);
    data.append("coupon", coupon);
    data.append("ask_for_address", askForAddress);
    data.append("ask_for_catalog", askForCatalog);
    data.append("access_code", accessCode);
    data.append("show_original_price", showOriginalPriceIndication);

    try {
      await api.post("/gestor/v1/campaigns", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      setAlertTitle("");
      history.push("/campanhas");
      setSaveLoading(false);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
      setSaveLoading(false);
    }
  };

  const classes = useStyles();

  const handleListScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore) {
      const newPage = page + 1;
      setPage(newPage);

      if (searchTermItem || inputValueItem) {
        searchRotulosInApi(
          searchTermItem ?? inputValueItem,
          discount,
          loja.id,
          newPage
        );
      } else {
        loadRotulos(loja, discount, [], newPage);
      }
    }
  };

  const renderDiscount = () => {
    if (parseInt(campainType) === 4) return "";
    return (
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          id="discount"
          label="Desconto"
          name="discount"
          autoComplete="discount"
          value={discount}
          onChange={(e) => {
            const valor = formatarMoeda(e.target.value);
            setDiscount(valor);
            debounceSearchRotulos(loja, valor);
          }}
        />
      </Grid>
    );
  };

  const renderFormPropsCampaignPrice = () => {
    if (parseInt(campainType) !== 4) return "";
    return (
      <>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="accessCode"
            label="Código de acesso"
            name="accessCode"
            autoComplete="accessCode"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
          />
        </Grid>
      </>
    );
  };
  const renderFormPropsCampaignLead = () => {
    if (parseInt(campainType) !== 3) return "";
    return (
      <>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="coupon"
            label="Código de Confirmação"
            name="coupon"
            autoComplete="coupon"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            maxRows={4}
            id="presentation"
            label="Texto inicial "
            name="presentation"
            autoComplete="presentation"
            value={presentation}
            onChange={(e) => setPresentation(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            maxRows={4}
            id="on_confirmation_end"
            label="Texto de confirmação"
            name="on_confirmation_end"
            autoComplete="on_confirmation_end"
            value={onConfirmationEnd}
            onChange={(e) => setOnConfirmationEnd(e.target.value)}
          />
        </Grid>
        <>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!askForAddress}
                  onChange={(e) => {
                    setAskForAddress(e.target.checked === true ? 1 : 0);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Solicitar endereço ?"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!askForCatalog}
                  onChange={(e) => {
                    setAskForCatalog(e.target.checked === true ? 1 : 0);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Mostrar catálogo ?"
            />
          </Grid>
        </>
      </>
    );
  };

  const renderSearchWineForm = () => {
    if (parseInt(campainType) === 3 && !askForCatalog) return "";
    return (
      <>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth={true}
            loading={loadingRotulos}
            disableCloseOnSelect
            multiple
            options={rotulos.filter(
              (item) =>
                item.estoque > 0 &&
                item.status === 1 &&
                !rotulosSelected.includes(item.id)
            )}
            getOptionLabel={(item) => item.title}
            value={selectedItem}
            onChange={(event, value, reason) => {
              if (!isValidAddWineItem()) {
                window.alert(
                  "Você já selecionou a quantidade máxima de rótulos do seu plano. Se desejar mais, aumente seu plano !"
                );
                return;
              }
              const wine = value.pop();
              setRotulosSelected([...rotulosSelected, wine.id]);
              setTableWineItems([
                {
                  id: wine.id,
                  wine: wine.nome,
                  price: wine.preco,
                },
                ...tableWineItems,
              ]);
            }}
            inputValue={inputValueItem}
            onInputChange={(event, newInputValue) => {
              if (newInputValue) {
                setInputValueItem(newInputValue);
                setSearchTermItem(newInputValue);
                debounceSearchRotulosInApi(newInputValue, discount, loja.id);
                return;
              }
              setInputValueItem("");
              setSearchTermItem("");
              setRotulos(rotulosIndex);
            }}
            onBlur={handleBlurItem}
            onFocus={handleFocusItem}
            ListboxProps={{
              onScroll: handleListScroll,
              style: {
                maxHeight: "200px",
                overflowY: "auto",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Adicionar rótulos"
                placeholder="Buscar..."
                variant="outlined"
                inputRef={inputRefItem}
              />
            )}
            renderOption={(item) => item.title}
          />
        </Grid>

        <Grid>
          <GridWines
            data={tableWineItems}
            onClickDelete={onDeleteWine}
            onHandleChangeCell={onChangeValueColumn}
          />
        </Grid>
      </>
    );
  };

  const isValidAddWineItem = () => {
    return (parseInt(campainType) === 2 || askForCatalog) &&
      tableWineItems.length === parseInt(loja.plano.normal_indications)
      ? false
      : true;
  };

  const onDeleteWine = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setTableWineItems((prevRows) =>
          prevRows.filter((row) => row.id !== id)
        );
        setRotulosSelected((prevRows) =>
          prevRows.filter((selectedId) => {
            return selectedId !== id;
          })
        );
      });
    },
    []
  );

  const [rowChanged, setRowChanged] = React.useState(null);
  useEffect(() => {
    if (!rowChanged) return;
    const rowIndex = tableWineItems.findIndex(
      (item) => item.id === rowChanged.id
    );

    let newData = [...tableWineItems];
    newData[rowIndex] = {
      id: rowChanged.id,
      price: rowChanged.price,
      wine: rowChanged.wine,
      customPrice: rowChanged.customPrice > 0 ? rowChanged.customPrice : 0,
    };
    setTableWineItems(newData);
  }, [rowChanged]);

  const onChangeValueColumn = React.useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        setRowChanged(newRow);
        resolve(newRow);
      }),
    []
  );

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={`/campanhas`}>
                  {roleName}
                </Link>
                <Typography color="textPrimary">Criar</Typography>
              </Breadcrumbs>
            </Grid>
            {loading ? (
              <Loading />
            ) : (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid item xs={6}>
                    <form className={classes.form} noValidate>
                      <Grid container spacing={2}>
                        {showLoja && (
                          <Grid item xs={6}>
                            <Autocomplete
                              loading={loadingLojas}
                              value={loja}
                              onChange={(event, newValue) => {
                                setLoja(newValue);
                                loadRotulos(newValue, discount);
                              }}
                              inputValue={lojaValue}
                              onInputChange={(event, newInputValue) => {
                                setLojaValue(newInputValue);
                              }}
                              id="controllable-states-demo"
                              options={lojas}
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Loja" />
                              )}
                              getOptionLabel={(option) => option.name || ""}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Grid item xs={6}>
                            <InputLabel>Tipo de Campanha</InputLabel>
                            <Select
                              fullWidth
                              native
                              onChange={(e) => {
                                setCampainType(e.target.value);
                              }}
                              inputProps={{
                                name: "Descrição da Campanha",
                                id: "",
                              }}
                            >
                              <option value=""></option>
                              <option value={1}>Social Commerce</option>
                              <option value={2}>Intelivino App</option>
                              {parseInt(loja.plano.id) !== 7 ? (
                                <>
                                  <option value={3}>Captura de Lead</option>
                                  <option value={4}>Tabela de Preço</option>
                                </>
                              ) : (
                                ""
                              )}
                            </Select>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            label="Nome da campanha"
                            name="name"
                            autoComplete="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Grid>
                        {renderDiscount()}
                        {renderFormPropsCampaignLead()}
                        {renderFormPropsCampaignPrice()}
                        {parseInt(campainType) === 1 ? (
                          <>
                            <Grid item xs={4}>
                              <DatePicker
                                value={date_start}
                                onChange={setDate_start}
                                label="Data início"
                                id="date_start"
                                name="date_start"
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <DatePicker
                                value={date_end}
                                onChange={setDate_end}
                                label="Data fim"
                                id="date_end"
                                name="date_end"
                              />
                            </Grid>
                          </>
                        ) : (
                          ""
                        )}
                        {renderSearchWineForm()}
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!status}
                                onChange={(e) => {
                                  setStatus(
                                    e.target.checked === true ? true : false
                                  );
                                }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label="Ativo"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Button
                            onClick={() => {
                              history.push(`/campanhas`);
                            }}
                            type="button"
                            variant="contained"
                            color="default"
                            style={{
                              marginRight: 30,
                              marginTop: 30,
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={saveLoading}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={save}
                            style={{
                              marginTop: 30,
                            }}
                          >
                            {saveLoading ? "Salvando..." : "Salvar"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>

          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
