import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LinkIcon from "@material-ui/icons/Link";
import React, { useEffect } from "react";
import { Redirect } from "react-router";
import AlertDialog from "./../../compoments/AlertDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import api from "./../../services/axios";
import jwtDecode from "./../../services/jwt";
import Base from "./../Base";
import ModalUpdateCatalogo from "./ModalUpdateCatalogo/";
import { getBaseUrl } from "../../env";
import Loading from "../../compoments/Loading";
const { storeURL } = getBaseUrl();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
  textColor: {
    display: "flex",
    padding: "10px",
    flexDirection: "row",
    borderRadius: "4px",
    alignItems: "center",
    border: "1px solid #bcc2bf",
    justifyContent: "space-between",
  },
}));

export default function PerfilForm({ history }) {
  const { baseURL } = getBaseUrl();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [redirect] = React.useState(false);
  const [urlLoja, setUrlLoja] = React.useState("");
  const [typeConfigFreight, setTypeConfigFreight] = React.useState(0);
  const [typeConfigStock, setTypeConfigStock] = React.useState(0);
  const [updateStockOrder, setUpdateStockOrder] = React.useState(0);
  const [availableStockInOrder, setAvailableStockInOrder] = React.useState(0);
  const [showConfigFreight, setShowConfigFreight] = React.useState(false);
  const [showConfigStock, setShowConfigStock] = React.useState(false);
  const [showEditMarket, setShowEditMarket] = React.useState(false);
  const [marketId, setMarketId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [textLoading, setTextLoading] = React.useState("");

  const loadData = async () => {
    const tokenData = jwtDecode(localStorage.getItem("token"));
    const id = tokenData.user?.id
    const user_data = await api.get("/v3/users/" + id);
    loadPermissionFeatures(user_data.data.id, user_data.data.features)
    setShowEditMarket(true);
    setShowConfigFreight(true);
    

    setMarketId(parseInt(user_data.data.id));
    setTypeConfigFreight(parseInt(user_data.data.way_calculate_freight));
  };

  const loadPermissionFeatures = async (accountId, features) => {
    if (features) {
      for (const feature of features) {
        if (feature.slug_feature) {
          setShowConfigStock(feature.value);
          await loadDataAboutStock(accountId);
        }
      }
    }
  };

  const loadDataAboutStock = async (userId) => {
    try {
      const { data } = await api.request({
        baseURL,
        url: `/api-node/config/${userId}`,
      });
      const stockControlConfig = data.find(
        (config) => config.name === "STOCK_CONTROL"
      );

      if (stockControlConfig) {
        setTypeConfigStock(parseInt(stockControlConfig.value));
        const stockControlStoreConfig = data.find(
          (config) => config.name === "STOCK_CONTROL_STORE"
        );
        if (stockControlStoreConfig) {
          setAvailableStockInOrder(stockControlStoreConfig.value === "1");
        }

        const stockControlUpdateConfig = data.find(
          (config) => config.name === "UPDATE_STOCK_ORDER"
        );
        if (stockControlUpdateConfig) {
          setUpdateStockOrder(stockControlUpdateConfig.value === "1");
        }
      }
    } catch (error) {
      setAlertTitle("Erro");
      setMessage("Erro ao recuperar configuraçẽos do estoque");
    }
  };

  useEffect(() => {
    const data = jwtDecode(localStorage.getItem("token"));
    setName(data.user.name);
    setEmail(data.user.email);
    setRoles(data.user.roles);
    setUrlLoja(storeURL + "" + data.user.name_business_slug);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typesConfigFreight = [
    {
      id: 0,
      label: "Nunca calcula frete",
    },
    {
      id: 1,
      label: "Tabela própria",
    },
    {
      id: 2,
      label: "Tabela própria + cálculo manual do frete",
    },
    {
      id: 3,
      label: "Somente cálculo manual de frete",
    },
  ];

  const typesConfigstock = [
    {
      id: 1,
      label: "Ativar estoque",
    },
    {
      id: 0,
      label: "Desativar estoque",
    },
  ];

  const onChangeTypeConfigFreight = async (value) => {
    try {
      const config = {
        WAY_CALCULATE_FREIGHT: value,
      };
      await api.post("/v6/users/config-freight", config, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setTypeConfigFreight(value);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage("Erro ao atualizar configuração do frete");
    }
  };

  const onChangeTypeConfigStock = async (value) => {
    try {
      const config = {
        name: "STOCK_CONTROL",
        value: String(value),
        accountId: marketId,
      };
      setLoading(true);
      if (value) {
        setTextLoading(
          "Ativando o estoque e configurando os detalhes para você. Aguarde um momento..."
        );
        await api.request({
          baseURL: "https://services.intelivino.com.br",
          url: `/api-node/config/activatestock`,
          method: "POST",
          data: config,
        });
      } else {
        await api.request({
          baseURL: "https://services.intelivino.com.br",
          url: `/api-node/config`,
          method: "POST",
          data: config,
        });
      }
      await onChangeUpdateStockOrder(value);
      await onChangeAvailableStockInOrder(value);
      setLoading(false);
      setTextLoading("");
      setTypeConfigStock(value);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(
        "Erro ao ativar estoque, tente novamente. Caso o problema persista, entre em contato conosco"
      );
    }
  };

  const onChangeUpdateStockOrder = async (value) => {
    try {
      const config = {
        name: "UPDATE_STOCK_ORDER",
        value: value ? "1" : "0",
        accountId: marketId,
      };
      await api.request({
        baseURL,
        url: `/api-node/config`,
        method: "POST",
        data: config,
      });
      setUpdateStockOrder(value);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage("Erro ao atualizar configuração: Atualiza estoque no pedido");
    }
  };

  const onChangeAvailableStockInOrder = async (value) => {
    try {
      const config = {
        name: "STOCK_CONTROL_STORE",
        value: value ? "1" : "0",
        accountId: marketId,
      };
      await api.request({
        baseURL,
        url: `/api-node/config`,
        method: "POST",
        data: config,
      });
      setAvailableStockInOrder(value);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(
        "Erro ao atualizar configuração: Verifica estoque disponível no pedido"
      );
    }
  };

  const classes = useStyles();

  const renderGridConfigFreight = () => {
    if (!showConfigFreight) return "";
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Configurações do Frete</h3>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={typeConfigFreight}
            onChange={(e) => {
              onChangeTypeConfigFreight(e.target.value);
            }}
          >
            {typesConfigFreight.map((data, k) => (
              <MenuItem value={data.id} key={data.id}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {[1, 2].includes(typeConfigFreight) ? (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push(`/frete`);
              }}
            >
              Editar tabela frete
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  };

  const renderGridConfigStock = () => {
    if (!showConfigStock) return "";
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Configurações do Estoque</h3>
          <Grid item xs={3}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeConfigStock}
              onChange={(e) => {
                onChangeTypeConfigStock(e.target.value);
              }}
            >
              {typesConfigstock.map((data, k) => (
                <MenuItem value={data.id} key={data.id}>
                  {data.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {typeConfigStock ? (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={updateStockOrder}
                      onChange={(e) => {
                        onChangeUpdateStockOrder(e.target.checked);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Atualiza estoque no pedido"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={availableStockInOrder}
                      onChange={(e) => {
                        onChangeAvailableStockInOrder(e.target.checked);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Verifica estoque disponível no pedido"
                />
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      {/* TOPO */}
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <form className={classes.form} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        name="name"
                        autoComplete="name"
                        value={name}
                        inputProps={{
                          disabled: true,
                          style: {
                            backgroundColor: "#F2F2F2",
                            cursor: "not-allowed",
                          },
                        }}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        value={email}
                        inputProps={{
                          disabled: true,
                          style: {
                            backgroundColor: "#F2F2F2",
                            cursor: "not-allowed",
                          },
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="roles"
                        label="Grupos"
                        name="roles"
                        autoComplete="roles"
                        value={roles.join(",")}
                        inputProps={{
                          disabled: true,
                          style: {
                            backgroundColor: "#F2F2F2",
                            cursor: "not-allowed",
                          },
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {showEditMarket ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <h3>Meus dados</h3>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            history.push(`/updatemarket`);
                          }}
                        >
                          Alterar
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3>Infomações do Catálogo</h3>
                      <ModalUpdateCatalogo />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3>Loja</h3>
                      <Typography className={classes.root}>
                        <LinkIcon />
                        <Link href={urlLoja} variant="body2" target="_blank">
                          {urlLoja}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                  {renderGridConfigFreight()}
                  {loading ? (
                    <Loading text={textLoading} />
                  ) : (
                    renderGridConfigStock()
                  )}
                </form>
              </Paper>
            </Grid>
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
