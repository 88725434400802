import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import ruLocale from "date-fns/locale/pt-BR";

const localeMap = {
  ptBR: ruLocale,
};

export default function DatePicker(props) {
  
  const locale = "ptBR";

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <Grid container justify="flex-start">
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          format="dd/MM/yyyy"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          {...props}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}