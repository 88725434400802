import axios from "axios";
import { getBaseUrl } from "../../env";
const { baseURL } = getBaseUrl();

const newApi = axios.create({
  baseURL: `${baseURL}/api-node`,
});

newApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["X-Token"] = `Bearer ${token}`;
    config.headers[
      "X-Saas-Client"
    ] = `aXlQUDdDZ0hOZm43TUJxOUpWMGM4Umd5ZVlZYW1Neno=`;
  }
  return config;
});

export default newApi;
