import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useMemo } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { isPermission } from "../../services/permission";
import AlertDialog from "./../../compoments/AlertDialog";
import ImageUpload from "./../../compoments/ImageUpload";
import Loading from "./../../compoments/Loading";
import api from "./../../services/axios";
import Base from "./../Base";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  disabled: {
    padding: 30,
    backgroundColor: "red",
  },
}));

export default function UsuariosUpdate({ match, history }) {
  const loadPermission = useCallback(() => {
    if (!isPermission("updateuserbusinessgestor")) {
      history.push("/401");
    }
  }, [history]);

  useEffect(() => {
    loadPermission();
  }, [loadPermission]);

  const roleName = "Lojistas";

  const [redirect] = React.useState(false);
  const [id, setId] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [name, setName] = React.useState("");
  const [fone, setFone] = React.useState("");
  const [whatsapp, setWhatsapp] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [cnpj, setCnpj] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [plano, setPlano] = React.useState("10");
  const [planos, setPlanos] = React.useState([]);
  const [thumbnail, setThumbnail] = React.useState(null);
  const [removeFlag, setRemoveFlag] = React.useState(false);
  const [controlStock, setControlStock] = React.useState(false);
  const [controlCommunity, setControlCommunity] = React.useState(false);

  const loadData = useCallback(async () => {
    setId(match.params.id);
    const data = await api.get("/v3/users/" + match.params.id);
    setName(data.data.name);
    setFone(data.data.business.fone);
    setWhatsapp(data.data.business.whatsapp);
    setEmail(data.data.email);
    setCpf(data.data.business.cpf || "");
    setCnpj(data.data.business.cnpj || "");
    setStatus(data.data.business.status || "0");
    setPlano(data.data.plano.id);
    setThumbnail(data.data.photo);
    const dataPlanos = await api.get("/v3/planos");
    setPlanos(dataPlanos.data.data);
    setLoading(false);
    if (data.data.features) {
      for (const feature of data.data.features) {
        switch (feature.slug_feature) {
          case "controle_de_estoque":
            setControlStock(feature.value);
            break;
          case "comunidade":
            setControlCommunity(feature.value);
            break;
          default:
            break;
        }
      }
    }
  }, [setId, setStatus, match]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const save = async () => {
    const data = new FormData();
    data.append("status", status);
    data.append("plano_id", plano);
    data.append("controlStock", controlStock ? 1 : 0);
    data.append("controlCommunity", controlCommunity ? 1 : 0);
    data.append("_method", "PUT");
    try {
      await api.post("/v3/users/updatePlano/" + id, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setAlertTitle("");
      history.push("/usersbusiness");
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
    }
  };

  const preview = useMemo(() => {
    return thumbnail && thumbnail.name
      ? URL.createObjectURL(thumbnail)
      : thumbnail;
  }, [thumbnail]);

  const classes = useStyles();

  const renderGridConfigmodules = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Ferramentas dísponiveis</h3>
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={controlStock}
                    onChange={(e) => {
                      setControlStock(!controlStock);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Controle de Estoque"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={controlCommunity}
                    onChange={(e) => {
                      setControlCommunity(!controlCommunity);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Comunidade"
              />
            </Grid>
          </>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Base />
      {/* CONTEUDO */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" to={`/usersbusiness`}>
                    {roleName}
                  </Link>
                  <Typography color="textPrimary">Editar</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ImageUpload
                          // onCropComplete={(croppedArea, croppedAreaPixels) => {
                          //   setCroppedAreaPixels(croppedAreaPixels)
                          // }}
                          onChange={(event) => {
                            setThumbnail(event.target.files[0]);
                            setRemoveFlag(true);
                          }}
                          previewChange={preview}
                          removeFlag={removeFlag}
                          cropFlag={status}
                          remove={(flag) => {
                            setThumbnail(null);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          label="Nome Comercial"
                          name="name"
                          autoComplete="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="fone"
                          label="Telefone"
                          name="fone"
                          autoComplete="fone"
                          value={fone}
                          onChange={(e) => setFone(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="whatsapp"
                          label="Whatsapp"
                          name="whatsapp"
                          autoComplete="whatsapp"
                          value={whatsapp}
                          onChange={(e) => setWhatsapp(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled="true"
                          variant="outlined"
                          required
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          autoComplete="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>

                      {cpf.toString().trim().length > 0 && (
                        <Grid item xs={12}>
                          <TextField
                            disabled="true"
                            variant="outlined"
                            required
                            fullWidth
                            id="cpf"
                            label="Cpf"
                            name="cpf"
                            autoComplete="cpf"
                            value={cpf}
                            onChange={(e) => setCpf(e.target.value)}
                          />
                        </Grid>
                      )}
                      {cnpj.toString().trim().length > 0 && (
                        <Grid item xs={12}>
                          <TextField
                            disabled="true"
                            variant="outlined"
                            required
                            fullWidth
                            id="cnpj"
                            label="Cnpj"
                            name="cnpj"
                            autoComplete="cnpj"
                            value={cnpj}
                            onChange={(e) => setCnpj(e.target.value)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <MenuItem value="0">Inativo</MenuItem>
                            <MenuItem value="1">Ativo</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Plano
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={plano}
                            onChange={(e) => setPlano(e.target.value)}
                          >
                            {planos.map((data, k) => (
                              <MenuItem value={data.id}>{data.nome}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {renderGridConfigmodules()}
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            history.push(`/usersbusiness`);
                          }}
                          type="button"
                          variant="contained"
                          color="default"
                          // className={classes.submit}
                          style={{ marginRight: 30 }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={save}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          )}
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
