import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
	badge: {
		border: "1px solid #ccc",
		borderRadius: "10px",
		padding: "6px",
		margin: "6px",
	},

	critical: {
		backgroundColor: "#D32F2F",
		color: "#FFFFFF",
	},

	warning: {
		backgroundColor: "#FF5722",
		color: "#FFFFFF",
	},

	primary: {
		backgroundColor: "#4CAF50",
		color: "#FFFFFF",
	},
}));
