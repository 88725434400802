export const dateFormat = (date, time) => {
	const dd = String(date.getDate()).padStart(2, "0");
	const mm = String(date.getMonth() + 1).padStart(2, "0");
	const yy = date.getFullYear().toString().substr(-2);
	const hours = date.getHours();
	const minutes = date.getMinutes().toString().padStart(2, "0");

	let timeString = "";
	if (time) {
		timeString =
			" às " +
			hours.toString().padStart(2, "0") +
			"h" +
			minutes.toString().padStart(2, "0");
	}
	return dd + "/" + mm + "/" + yy + " " + timeString;
};