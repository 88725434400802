export const getBaseUrl = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const storeURL = process.env.REACT_APP_STORE_URL;
  const imagesBucketURL = process.env.REACT_APP_IMAGE_BUCKET_URL;

  console.log({ baseURL, storeURL, imagesBucketURL });

  return {
    baseURL,
    storeURL,
    imagesBucketURL,
  };
};
