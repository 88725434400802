import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Redirect } from "react-router";
import AlertDialog from "./../../compoments/AlertDialog";
import { getBaseUrl } from "../../env";
const { imagesBucketURL } = getBaseUrl();
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${imagesBucketURL}/images/assets/banner-gestor.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login({ history }) {
  const classes = useStyles();
  const [redirect] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [alertTitle] = React.useState("");

  const logout = async () => {
    setTimeout(() => {
      localStorage.setItem("token", "");
      window.location = `/login`;
    }, 2000);
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      {redirect && <Redirect to={redirect} />}
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={12}
        md={12}
        className={classes.image}
        style={{ opacity: "0.8" }}
      ></Grid>
      <Typography
        variant="h2"
        gutterBottom
        component="div"
        style={{
          position: "absolute",
          backgroundColor: "#7d1f1a",
          color: "#FFF",
          margin: 200,
          padding: 15,
        }}
      >
        Sem permissão
      </Typography>
      <AlertDialog
        message={message}
        onClose={() => setMessage(false)}
        title={alertTitle}
        buttonSuccessText={"OK"}
      />
    </Grid>
  );
}
