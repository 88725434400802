import api from '../axios';
import jwtDecode from './../jwt';

export const isPermission = (permissionKey) => {
  const permissions = jwtDecode(localStorage.getItem('token')).user.permissions;
  return permissions.includes(permissionKey);
}

export const featuresAvailable = async (accountId) => {
  const { data } = await api.get("/v3/users/" + accountId);
  let stockAvailable = false;
  if (data.features) {
    for (const feature of data.features) {
      if (feature.slug_feature) {
        stockAvailable = feature.value;
      }
    }
  }

  return { stockAvailable };
};