/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AlertDialog from "../../compoments/AlertDialog";
import Loading from "../../compoments/Loading";
import MaterialTableComponent from "../../compoments/MaterialTableComponent";
import api from "../../services/axios";
import Base from "../Base";
import jwtDecode from "../../services/jwt/index";
import { roundNumber } from "../../utils/currency";
import { getBaseUrl } from "../../env";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Index({ history, match }) {
  const { baseURL } = getBaseUrl();
  const classes = useStyles();
  const tableRef = useRef();
  const [loading, setLoading] = React.useState(false);
  const [orderItems, setOrderItems] = React.useState([]);
  const [freight, setFreight] = React.useState(0);
  const [couponDiscount, setCouponDiscount] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [subTotal, setSubTotal] = React.useState(0);
  const [disabledFreight, setDisabledFreight] = React.useState(true);
  const [message, setMessage] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [mobilePhone, setMobilePhone] = React.useState("");
  const [addressStreet, setAddressStreet] = React.useState("");
  const [addressNumber, setAddressNumber] = React.useState("");
  const [addressDistrict, setAddressDistrict] = React.useState("");
  const [addressCity, setAddressCity] = React.useState("");
  const [addressState, setAddressState] = React.useState("");
  const [addressComplement, setAddressComplement] = React.useState("");
  const [addressZipCode, setAddressZipCode] = React.useState("");
  const [accountId, setAccountId] = useState(null);
  const options = {
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  };
  const formatNumber = new Intl.NumberFormat("pt-BR", options);

  useEffect(() => {
    const tokenData = jwtDecode(localStorage.getItem("token"));
    setAccountId(tokenData.user.id);
  }, []);

  const loadDetails = useCallback(async () => {
    if (!accountId) return;
    const { data } = await api.request({
      baseURL,
      url: `/api-node/account/${accountId}/orders/${match.params.orderId}`,
    });
    let orderItems = data.items.map((item) => {
      return {
        ...item,
        price: formatNumber.format(item.price),
        total: formatNumber.format(item.price * item.quantity),
      };
    });

    console.log({ data });

    setOrderItems(orderItems);
    setFreight(data.freight);

    if (data.discount) {
      setCouponDiscount(roundNumber(data.discount));
    }

    setSubTotal(data.subTotal);
    setTotal(roundNumber(data.total));
    setDisabledFreight(
      data.status !== "PENDING" && data.status !== "AWAIT_FREIGHT_CALC"
    );
    setName(data.customer.name);
    setMail(data.customer.email);
    setMobilePhone(data.customer.mobilePhone);
    setAddressStreet(data.address.street);
    setAddressNumber(data.address.street_number);
    setAddressDistrict(data.address.district);
    setAddressCity(data.address.city);
    setAddressState(data.address.state);
    setAddressComplement(data.address.address_complement);
    setAddressZipCode(data.address.zip_code);

    setLoading(false);
  }, [accountId]);

  const getFormatedTitle = useCallback(() => {
    return couponDiscount > 0 || freight > 0 ? (
      <div style={{ marginTop: 10 }}>
        <span style={{ fontSize: 20 }}>
          Preço Total: R$ {formatNumber.format(subTotal)}
        </span>
        {freight ? (
          <>
            <span style={{ marginLeft: 10, fontSize: 20 }}>+</span>
            <span style={{ marginLeft: 10, fontSize: 20 }}>
              Frete: R$ {formatNumber.format(freight)}
            </span>
          </>
        ) : (
          ""
        )}
        {Number(couponDiscount) > 0 ? (
          <>
            <span style={{ marginLeft: 10, fontSize: 20 }}>-</span>
            <span style={{ marginLeft: 10, fontSize: 20 }}>
              Cupom de Desconto: R$ {formatNumber.format(couponDiscount)}
            </span>
          </>
        ) : (
          ""
        )}
        <hr></hr>
        <span style={{ marginLeft: 80, fontWeight: "bold", fontSize: 20 }}>
          Valor Final: R$ {formatNumber.format(total)}
        </span>
      </div>
    ) : (
      <span style={{ marginTop: 10 }}>
        <span style={{ fontWeight: "bold", fontSize: 20 }}>
          Valor Final: R$ {formatNumber.format(total)}
        </span>
      </span>
    );
  }, [couponDiscount, freight, subTotal, total]);

  const save = async () => {
    setSaveLoading(true);

    try {
      await api.request({
        baseURL,
        url: `/api-node/account/${accountId}/orders/${match.params.orderId}/freight`,
        method: "PATCH",
        data: {
          freight: String(freight),
        },
      });
      setAlertTitle("");
      history.push("/pedidos");
      setSaveLoading(false);
    } catch (error) {
      setAlertTitle("Erro");
      setMessage(error.response.data.message || "Erro");
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    loadDetails();
  }, [accountId]);

  const onChangeFreight = useCallback(
    (value) => {
      const _freight = parseFloat(value) || 0;
      const _total = parseFloat(subTotal) + parseFloat(_freight);
      setTotal(_total);
      if (couponDiscount > 0) {
        setTotal(_total - couponDiscount);
      }
      setFreight(_freight);
    },
    [couponDiscount, subTotal, total, freight]
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Base />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Detalhes</Typography>
              </Breadcrumbs>
            </Grid>
            {loading ? (
              <Loading />
            ) : (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid item xs={10}>
                    <form className={classes.form} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            label="Nome"
                            name="name"
                            autoComplete="name"
                            value={name}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            required
                            fullWidth
                            id="mail"
                            label="E-mail"
                            name="mail"
                            autoComplete="mail"
                            value={mail}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            required
                            fullWidth
                            id="mobile_phone"
                            label="Celular"
                            name="mobile_phone"
                            autoComplete="mobile_phone"
                            value={mobilePhone}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            disabled
                            variant="outlined"
                            fullWidth
                            id="address_street"
                            label="Endereço"
                            name="address_street"
                            autoComplete="address_street"
                            value={addressStreet}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            disabled
                            variant="outlined"
                            fullWidth
                            id="address_number"
                            label="Número"
                            name="address_number"
                            autoComplete="address_number"
                            value={addressNumber}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            fullWidth
                            id="address_district"
                            label="Bairro"
                            name="address_district"
                            autoComplete="address_district"
                            value={addressDistrict}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            fullWidth
                            id="address_city"
                            label="Cidade"
                            name="address_city"
                            autoComplete="address_city"
                            value={addressCity}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            fullWidth
                            id="address_zip_code"
                            label="CEP"
                            name="address_zip_code"
                            autoComplete="address_zip_code"
                            value={addressZipCode}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            fullWidth
                            id="address_state"
                            label="Estado"
                            name="address_state"
                            autoComplete="address_state"
                            value={addressState}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            variant="outlined"
                            fullWidth
                            id="address_complement"
                            label="Complemento"
                            name="address_complement"
                            autoComplete="address_complement"
                            value={addressComplement}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="freight"
                            label="Frete"
                            name="freight"
                            autoComplete="freight"
                            value={freight}
                            disabled={disabledFreight}
                            onChange={(e) => onChangeFreight(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MaterialTableComponent
                            tableRef={tableRef}
                            columns={[
                              {
                                title: "Rótulo",
                                field: "name",
                              },
                              {
                                title: "Quantidade",
                                field: "quantity",
                                align: "right",
                                cellStyle: {
                                  textAlign: "right",
                                },
                              },
                              {
                                title: "Preço R$",
                                field: "price",
                                align: "right",
                                cellStyle: {
                                  textAlign: "right",
                                },
                              },
                              {
                                title: "Total R$",
                                field: "total",
                                align: "right",
                                cellStyle: {
                                  textAlign: "right",
                                },
                              },
                            ]}
                            data={orderItems}
                            title={getFormatedTitle()}
                            options={{
                              actionsColumnIndex: -1,
                              search: false,
                              sorting: false,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Paper>
              </Grid>
            )}

            <Grid item xs={10}>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                type="button"
                variant="contained"
                color="default"
                style={{
                  marginRight: 30,
                  marginTop: 30,
                }}
              >
                Voltar
              </Button>
              <Button
                disabled={saveLoading}
                type="button"
                variant="contained"
                color="primary"
                onClick={save}
                style={{
                  marginTop: 30,
                }}
              >
                {saveLoading ? "Salvando..." : "Salvar"}
              </Button>
            </Grid>
          </Grid>
          <AlertDialog
            message={message}
            onClose={() => setMessage(false)}
            title={alertTitle}
            buttonSuccessText={"OK"}
          />
        </Container>
      </main>
    </div>
  );
}
