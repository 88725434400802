/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AlertDialog from "./../../compoments/AlertDialog";
import Loading from "./../../compoments/Loading";
import Base from "./../Base";
import api from "./../../services/axios";
import { estados } from "../../services/address/estados";
import { paises } from "../../services/address/paises";
import { searchCep } from "../../services/address/viacep";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	input: {
		display: "none",
	},
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	appBarSpacer: theme.mixins.toolbar,
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	disabled: {
		padding: 30,
		backgroundColor: "red",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: "100%",
	},
	checkboxContainer: {
		display: "flex",
		flexWrap: "wrap",
	},
	checkboxWrapper: {
		width: "50%",
		boxSizing: "border-box",
		padding: 5,
	},
}));

export default function Update({ match, history }) {
	const roleName = "Atualizar meus dados";
	const [message, setMessage] = React.useState(false);
	const [alertTitle, setAlertTitle] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [name, setName] = React.useState("");
	const [mail, setMail] = React.useState("");
	const [cpf, setCpf] = React.useState("");
	const [cnpj, setCnpj] = React.useState("");
	const [id, setId] = React.useState("");
	const [corporateName, setCorporateName] = React.useState("");
	const [typeClient, setTypeClient] = React.useState(0);
	const [addressStreet, setAddressStreet] = React.useState("");
	const [addressNumber, setAddressNumber] = React.useState("");
	const [addressDistrict, setAddressDistrict] = React.useState("");
	const [addressCountry, setAddressCountry] = React.useState("");
	const [addressCity, setAddressCity] = React.useState("");
	const [addressState, setAddressState] = React.useState("");
	const [addressComplement, setAddressComplement] = React.useState("");
	const [addressZipCode, setAddressZipCode] = React.useState("");
	const [phone, setPhone] = React.useState("");
	const [contactPhone, setContactPhone] = React.useState("");
	const [mainContactName, setMainContactName] = React.useState("");
	const [whatsapp, setWhatsapp] = React.useState("");
	const [site, setSite] = React.useState("");
	const [passwordActual, setPasswordActual] = React.useState("");
	const [passwordNew, setPasswordNew] = React.useState("");

	const [categorias, setCategorias] = React.useState({
		champanheria: false,
		consultorDeVendas: false,
		ecommerce: false,
		importadora: false,
		lojaFisica: false,
		orgEvento: false,
		vinicola: false,
		winebar: false,
	});

	const handleCheckboxChange = (categoria) => {
		setCategorias((prevState) => ({
			...prevState,
			[categoria]: !prevState[categoria],
		}));
	};

	const populateAddress = async (cep) => {
		const validacep = /^\d{8}$/;

		if (validacep.test(cep) && cep.length === 8) {
			const data = await searchCep(cep);
            setAddressNumber("")
            setAddressCountry(1)
			if (data.logradouro) {
				setAddressStreet(data.logradouro);
			}
			if (data.bairro) {
				setAddressDistrict(data.bairro);
			}
			if (data.localidade) {
				setAddressCity(data.localidade);
			}
			if (data.uf) {
				setAddressState(data.uf);
			}
		}
	};

	const typePerson = [
		{
			id: 0,
			label: "",
		},
		{
			id: "cpf",
			label: "Física",
		},
		{
			id: "cnpj",
			label: "Juridica",
		},
	];

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		const data = await api.get("/gestor/v1/perfil");
		setLoading(true);
		setId(data.data.id);
		setName(data.data.name);
		setMail(data.data.email);
		setTypeClient(data.data.business.person_type);
		setCpf(data.data.business.cpf);
		setCnpj(data.data.business.cnpj);
		setCorporateName(data.data.business.razao_social);
		setAddressStreet(data.data.address_street);
		setAddressNumber(data.data.address_number);
		setAddressDistrict(data.data.address_neighborhood);
		setAddressCountry(data.data.address_country);
		setAddressCity(data.data.address_city);
		setAddressState(data.data.address_state);
		setAddressComplement(data.data.address_complement);
		setAddressZipCode(data.data.address_cep);
		setPhone(data.data.business.fone);
		setContactPhone(data.data.business.main_contact_fone);
		setMainContactName(data.data.business.main_contact_name);
		setWhatsapp(data.data.business.whatsapp);
		setSite(data.data.business.website);

		const categorias = {
			champanheria: false,
			consultorDeVendas: false,
			ecommerce: false,
			importadora: false,
			lojaFisica: false,
			orgEvento: false,
			vinicola: false,
			winebar: false,
		};

		if (data.data.business.activities) {
			for (const categoria of data.data.business.activities) {
				switch (parseInt(categoria.id)) {
					case 1:
						categorias.lojaFisica = true;
						break;
					case 2:
						categorias.winebar = true;
						break;
					case 3:
						categorias.ecommerce = true;
						break;
					case 4:
						categorias.champanheria = true;
						break;
					case 5:
						categorias.consultorDeVendas = true;
						break;
					case 6:
						categorias.orgEvento = true;
						break;
					case 7:
						categorias.vinicola = true;
						break;
					case 8:
						categorias.importadora = true;
						break;

					default:
						break;
				}
			}
		}
		setCategorias(categorias);
		setLoading(false);
	};

	const save = async () => {
		setSaveLoading(true);
		const data = new FormData();
		data.append("address_cep", addressZipCode);
		data.append("address_city", addressCity);
		data.append("address_complement", addressCity);
		data.append("address_country", addressCountry);
		data.append("address_neighborhood", addressDistrict);
		data.append("address_number", addressNumber);
		data.append("address_state", addressState);
		data.append("address_street", addressStreet);
		data.append("cnpj", cnpj);
		data.append("cpf", cpf);
		data.append("email", mail);
		data.append("fone", phone);
		data.append("main_contact_fone", contactPhone);
		data.append("main_contact_name", mainContactName);
		data.append("name", name);
		data.append("person_type", typeClient);
		data.append("razao_social", corporateName);
		data.append("website", site);
		data.append("whatsapp", whatsapp);

		if (passwordActual.trim() && passwordNew) {
			data.append("old_password", passwordActual);
			data.append("password", passwordNew);
		}

		const activities = [];
		if (categorias.lojaFisica) {
			activities.push(1);
		}
		if (categorias.winebar) {
			activities.push(2);
		}
		if (categorias.ecommerce) {
			activities.push(3);
		}
		if (categorias.champanheria) {
			activities.push(4);
		}
		if (categorias.consultorDeVendas) {
			activities.push(5);
		}
		if (categorias.orgEvento) {
			activities.push(6);
		}
		if (categorias.vinicola) {
			activities.push(7);
		}
		if (categorias.importadora) {
			activities.push(8);
		}

		data.append("activities", JSON.stringify(activities));
		data.append("_method", "put");

		try {
			await api.post(`/v3/users/${id}`, data, {
				headers: {
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			});

			setAlertTitle("");
			history.push("/perfil");
			setSaveLoading(false);
		} catch (error) {
			setAlertTitle("Erro");
			setMessage(error.response.data.message || "Erro");
			setSaveLoading(false);
		}
	};

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Base />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Breadcrumbs aria-label="breadcrumb">
								<Link color="inherit" to={`/clientes`}>
									{roleName}
								</Link>
								<Typography color="textPrimary">
									Atualizar
								</Typography>
							</Breadcrumbs>
						</Grid>
						{loading ? (
							<Loading />
						) : (
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<Grid item xs={10}>
										<form
											className={classes.form}
											noValidate
										>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														required
														fullWidth
														id="name"
														label="Nome Comercial"
														name="name"
														autoComplete="name"
														value={name}
														onChange={(e) =>
															setName(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														required
														fullWidth
														id="mail"
														label="E-mail"
														name="mail"
														autoComplete="mail"
														value={mail}
														onChange={(e) =>
															setMail(
																e.target.value
															)
														}
													/>
												</Grid>
												<>
													<Grid item xs={8}>
														<FormControl
															className={
																classes.formControl
															}
														>
															<InputLabel id="demo-simple-select-label">
																Tipo Pessoa
															</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																defaultValue={
																	typePerson[0]
																}
																value={
																	typeClient
																}
																onChange={(
																	e
																) => {
																	setTypeClient(
																		e.target
																			.value
																	);
																}}
															>
																{typePerson.map(
																	(data) => (
																		<MenuItem
																			value={
																				data.id
																			}
																			key={
																				data.id
																			}
																		>
																			{
																				data.label
																			}
																		</MenuItem>
																	)
																)}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={6}>
														{typeClient ===
														"cpf" ? (
															<TextField
																variant="outlined"
																fullWidth
																id="cpf"
																label="CPF"
																name="cpf"
																autoComplete="cpf"
																value={cpf}
																onChange={(e) =>
																	setCpf(
																		e.target
																			.value
																	)
																}
															/>
														) : (
															<TextField
																variant="outlined"
																fullWidth
																id="cnpj"
																label="CNPJ"
																name="cnpj"
																autoComplete="cnpj"
																value={cnpj}
																onChange={(e) =>
																	setCnpj(
																		e.target
																			.value
																	)
																}
															/>
														)}
													</Grid>
													{typeClient === "cnpj" ? (
														<Grid item xs={6}>
															<TextField
																variant="outlined"
																fullWidth
																id="corporate_name"
																label="Razão Social"
																name="corporate_name"
																autoComplete="corporate_name"
																value={
																	corporateName
																}
																onChange={(e) =>
																	setCorporateName(
																		e.target
																			.value
																	)
																}
															/>
														</Grid>
													) : (
														""
													)}
												</>
												<>
													<Grid item xs={12}>
														<TextField
															variant="outlined"
															fullWidth
															id="address_zip_code"
															label="CEP"
															name="address_zip_code"
															autoComplete="address_zip_code"
															value={
																addressZipCode
															}
                                                            onChange={(e) => {
                                                                populateAddress(
                                                                    e.target.value
                                                                );
                                                                setAddressZipCode(
                                                                    e.target.value
                                                                );
                                                            }}
														/>
													</Grid>
													<Grid item xs={8}>
														<TextField
															variant="outlined"
															fullWidth
															id="address_street"
															label="Endereço"
															name="address_street"
															autoComplete="address_street"
															value={
																addressStreet
															}
															onChange={(e) =>
																setAddressStreet(
																	e.target
																		.value
																)
															}
														/>
													</Grid>
													<Grid item xs={4}>
														<TextField
															variant="outlined"
															fullWidth
															id="address_number"
															label="Número"
															name="address_number"
															autoComplete="address_number"
															value={
																addressNumber
															}
															onChange={(e) =>
																setAddressNumber(
																	e.target
																		.value
																)
															}
														/>
													</Grid>
												</>
												<Grid item xs={9}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_city"
														label="Cidade"
														name="address_city"
														autoComplete="address_city"
														value={addressCity}
														onChange={(e) =>
															setAddressCity(
																e.target.value
															)
														}
													/>
												</Grid>
												<FormControl
													variant="outlined"
													className={
														classes.formControl
													}
													style={{ width: "14.8rem" }}
												>
													<InputLabel id="demo-simple-select-outlined-label">
														Estado
													</InputLabel>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={addressState}
														onChange={(e) =>
															setAddressState(
																e.target.value
															)
														}
														label="Estado"
													>
														{estados.map(
															(data, k) => {
																return (
																	<MenuItem
																		value={
																			data.id
																		}
																		key={
																			data.id
																		}
																	>
																		{
																			data.label
																		}
																	</MenuItem>
																);
															}
														)}
													</Select>
												</FormControl>
												<Grid item xs={8}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_district"
														label="Bairro"
														name="address_district"
														autoComplete="address_district"
														value={addressDistrict}
														onChange={(e) =>
															setAddressDistrict(
																e.target.value
															)
														}
													/>
												</Grid>
												<FormControl
													variant="outlined"
													className={
														classes.formControl
													}
													style={{ width: "20rem" }}
												>
													<InputLabel id="demo-simple-select-outlined-label">
														País
													</InputLabel>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={addressCountry}
														onChange={(e) =>
															setAddressCountry(
																e.target.value
															)
														}
														label="País"
													>
														{paises.map(
															(data, k) => {
																return (
																	<MenuItem
																		value={
																			data.id
																		}
																		key={
																			data.id
																		}
																	>
																		{
																			data.country_name
																		}
																	</MenuItem>
																);
															}
														)}
													</Select>
												</FormControl>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="address_complement"
														label="Complemento"
														name="address_complement"
														autoComplete="address_complement"
														value={
															addressComplement
														}
														onChange={(e) =>
															setAddressComplement(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="phone"
														label="Telefone"
														name="phone"
														autoComplete="phone"
														value={phone}
														onChange={(e) =>
															setPhone(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="contact_phone"
														label="Telefone do contato"
														name="contact_phone"
														autoComplete="contact_phone"
														value={contactPhone}
														onChange={(e) =>
															setContactPhone(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="main_contact_name"
														label="Contato Principal"
														name="main_contact_name"
														autoComplete="main_contact_name"
														value={mainContactName}
														onChange={(e) =>
															setMainContactName(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="whatsapp"
														label="Whatsapp"
														name="whatsapp"
														autoComplete="whatsapp"
														value={whatsapp}
														onChange={(e) =>
															setWhatsapp(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														id="site"
														label="Site"
														name="site"
														autoComplete="site"
														value={site}
														onChange={(e) =>
															setSite(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="password_actual"
														label="Senha atual"
														name="password_actual"
														autoComplete="password_actual"
														value={passwordActual}
														onChange={(e) =>
															setPasswordActual(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														fullWidth
														id="password_new"
														label="Nova senha"
														name="password_new"
														autoComplete="password_new"
														value={passwordNew}
														onChange={(e) =>
															setPasswordNew(
																e.target.value
															)
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<h3>Atividades</h3>
													<div
														className={
															classes.checkboxContainer
														}
													>
														<div
															className={
																classes.checkboxWrapper
															}
														>
															<label>
																<input
																	style={{
																		marginRight: 7,
																	}}
																	type="checkbox"
																	checked={
																		categorias.champanheria
																	}
																	onChange={() =>
																		handleCheckboxChange(
																			"champanheria"
																		)
																	}
																/>
																Champanheria
															</label>
														</div>
														<div
															className={
																classes.checkboxWrapper
															}
														>
															<label>
																<input
																	style={{
																		marginRight: 7,
																	}}
																	type="checkbox"
																	checked={
																		categorias.consultorDeVendas
																	}
																	onChange={() =>
																		handleCheckboxChange(
																			"consultorDeVendas"
																		)
																	}
																/>
																Consultor de
																Vendas
															</label>
														</div>
														<div
															className={
																classes.checkboxWrapper
															}
														>
															<label>
																<input
																	style={{
																		marginRight: 7,
																	}}
																	type="checkbox"
																	checked={
																		categorias.ecommerce
																	}
																	onChange={() =>
																		handleCheckboxChange(
																			"ecommerce"
																		)
																	}
																/>
																E-commerce
															</label>
														</div>
														<div
															className={
																classes.checkboxWrapper
															}
														>
															<label>
																<input
																	type="checkbox"
																	checked={
																		categorias.importadora
																	}
																	onChange={() =>
																		handleCheckboxChange(
																			"importadora"
																		)
																	}
																/>
																Importadora
															</label>
														</div>
														<div
															className={
																classes.checkboxWrapper
															}
														>
															<label>
																<input
																	type="checkbox"
																	checked={
																		categorias.lojaFisica
																	}
																	onChange={() =>
																		handleCheckboxChange(
																			"lojaFisica"
																		)
																	}
																/>
																Loja Física
															</label>
														</div>
														<div
															className={
																classes.checkboxWrapper
															}
														>
															<label>
																<input
																	type="checkbox"
																	checked={
																		categorias.orgEvento
																	}
																	onChange={() =>
																		handleCheckboxChange(
																			"orgEvento"
																		)
																	}
																/>
																Organizador de
																eventos
															</label>
														</div>
														<div
															className={
																classes.checkboxWrapper
															}
														>
															<label>
																<input
																	type="checkbox"
																	checked={
																		categorias.vinicola
																	}
																	onChange={() =>
																		handleCheckboxChange(
																			"vinicola"
																		)
																	}
																/>
																Vinícola
															</label>
														</div>
														<div
															className={
																classes.checkboxWrapper
															}
														>
															<label>
																<input
																	type="checkbox"
																	checked={
																		categorias.winebar
																	}
																	onChange={() =>
																		handleCheckboxChange(
																			"winebar"
																		)
																	}
																/>
																Wine bar
															</label>
														</div>
													</div>
												</Grid>
												<Grid item xs={10}>
													<Button
														onClick={() => {
															history.goBack();
														}}
														type="button"
														variant="contained"
														color="default"
														style={{
															marginRight: 30,
															marginTop: 30,
														}}
													>
														Cancelar
													</Button>
													<Button
														disabled={saveLoading}
														type="button"
														variant="contained"
														color="primary"
														onClick={save}
														style={{
															marginTop: 30,
														}}
													>
														{saveLoading
															? "Salvando..."
															: "Salvar"}
													</Button>
												</Grid>
											</Grid>
										</form>
									</Grid>
								</Paper>
							</Grid>
						)}
					</Grid>
					<AlertDialog
						message={message}
						onClose={() => setMessage(false)}
						title={alertTitle}
						buttonSuccessText={"OK"}
					/>
				</Container>
			</main>
		</div>
	);
}
