import jwt_decode from "jwt-decode";

const jwtDecode = (token) => {
	if (token && token.trim().length > 0) {
		const tokenDecoded = jwt_decode(token);
		return tokenDecoded;
	}
	localStorage.setItem("token", "");
	window.location = `/login`;
	return null;
};

export default jwtDecode;
